.welcome-page__filter-by-distance {
    &__caption {
        height: 100%;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $primaryGreyColour;

        & * {
            color: $secondaryColour;
            color: rgb(83, 83, 83);
            margin-bottom: 0;
            font-size: 1.1em;
            font-weight: 700;
        }
    }

    &__div {
        border: 1px solid $primaryGreyColour;
        margin: 35px 130px;
        height: 3.2em;
        border-radius: 50px;
        overflow: hidden;
        display: flex;

        &.alt {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0;
            padding-right: 30px; 
            height: 50px;
            border-radius: 50px;
            // border: none;
            background-color: lighten($secondaryColour, 65%);


            & .caption {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0 20px;
                min-width: 200px;
                background-color: $secondaryColour;
                border-radius: 50px;
            }

            & h5 {
                color: $lightFont;
                font-size: 1.1em;
                margin: 0;
            }
        }

        & input {
            width: 100%;
        }

        & input{
            width: 100%;
            background: none;
            pointer-events: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        & input::-moz-range-thumb{
            height: 20px;
            width: 20px;
            border: none;
            border-radius: 50%;
            background: $secondaryColour;
            pointer-events: auto;
            -moz-appearance: none;
            box-shadow: 0 0 6px rgba(0,0,0,0.05);
            z-index: 2;
        }

        & input::-webkit-slider-thumb{
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: $secondaryColour;
            pointer-events: auto;
            -webkit-appearance: none;
            box-shadow: 0 0 6px rgba(0,0,0,0.05);
            z-index: 2;
        }

    }
    &__info span{
        font-weight: 700;
        color: $secondaryColour;
    }

    &__input-div{
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 0 20px;
    }

    &__input-range-bar {
        width: 100%;
        height: 5px;
        display: flex;
        position: relative;
        align-items: center;
        background: #ddd;
        border-radius: 5px;

        &.alt {
            background-color: white;
        }
    }

    &__thumb {
        z-index: 2;
    }

    &__trail-line {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $secondaryColour;
        border-radius: 5px;
        z-index: 1;
    }

    &__filter-by-distance-caption {
        
    }
}

@include largemobileScreen {
    .welcome-page__filter-by-distance {
        &__div {
            margin: 20px 0;
            padding: 1em;
    
            & input{
                width: 100%;
                background: none;
                pointer-events: none;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
    
            & input::-moz-range-thumb{
                height: 20px;
                width: 20px;
                border: none;
                border-radius: 50%;
                background: $secondaryColour;
                pointer-events: auto;
                -moz-appearance: none;
                box-shadow: 0 0 6px rgba(0,0,0,0.05);
                z-index: 2;
            }
    
            & input::-webkit-slider-thumb{
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: $secondaryColour;
                pointer-events: auto;
                -webkit-appearance: none;
                box-shadow: 0 0 6px rgba(0,0,0,0.05);
                z-index: 2;
            }
    
        }
        &__info span{
            font-weight: 700;
            color: $secondaryColour;
        }
    
        &__input-div{
            width: 100%;
            height: 18px;
            // background-color: lightblue;
            display: flex;
            align-items: center;
        }
    
        &__input-range-bar {
            width: 100%;
            height: 5px;
            display: flex;
            position: relative;
            align-items: center;
            background: #ddd;
            border-radius: 5px;
        }
    
        &__thumb {
            z-index: 2;
        }
    
        &__trail-line {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: $secondaryColour;
            border-radius: 5px;
            z-index: 1;
        }
    }
}

@include mobileScreen {
    // .welcome-page__filter-by-distance {
    //     &__div {
    //         margin: 20px 0;
    //         padding: 1em;
    
    //         & input{
    //             width: 100%;
    //             background: none;
    //             pointer-events: none;
    //             -webkit-appearance: none;
    //             -moz-appearance: none;
    //         }
    
    //         & input::-moz-range-thumb{
    //             height: 20px;
    //             width: 20px;
    //             border: none;
    //             border-radius: 50%;
    //             background: $secondaryColour;
    //             pointer-events: auto;
    //             -moz-appearance: none;
    //             box-shadow: 0 0 6px rgba(0,0,0,0.05);
    //             z-index: 2;
    //         }
    
    //         & input::-webkit-slider-thumb{
    //             height: 20px;
    //             width: 20px;
    //             border-radius: 50%;
    //             background: $secondaryColour;
    //             pointer-events: auto;
    //             -webkit-appearance: none;
    //             box-shadow: 0 0 6px rgba(0,0,0,0.05);
    //             z-index: 2;
    //         }
    
    //     }
    //     &__info span{
    //         font-weight: 700;
    //         color: $secondaryColour;
    //     }
    
    //     &__input-div{
    //         width: 100%;
    //         height: 18px;
    //         // background-color: lightblue;
    //         display: flex;
    //         align-items: center;
    //     }
    
    //     &__input-range-bar {
    //         width: 100%;
    //         height: 5px;
    //         display: flex;
    //         position: relative;
    //         align-items: center;
    //         background: #ddd;
    //         border-radius: 5px;
    //     }
    
    //     &__thumb {
    //         z-index: 2;
    //     }
    
    //     &__trail-line {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         bottom: 0;
    //         background-color: $secondaryColour;
    //         border-radius: 5px;
    //         z-index: 1;
    //     }
    // }

   .welcome-page__filter-by-distance{
    &__caption {
        height: 100%;
        width: auto;
        justify-content: flex-start;
        border-right: none;

        & * {
            font-size: 0.95em;
            font-weight: 600;
        }
    }

    &__input-div{
        padding: 0;
    }


    &__div {
        // border: 1px solid $primaryGreyColour;
        margin: 35px 10px;
        height: auto;
        border-radius: 8px;
        flex-direction: column;
        padding: 15px 10px;
        gap: 15px;

        &.alt {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0;
            padding-right: 30px; 
            height: 50px;
            border-radius: 50px;
            // border: none;
            background-color: lighten($secondaryColour, 65%);


            & .caption {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0 20px;
                min-width: 200px;
                background-color: $secondaryColour;
                border-radius: 50px;
            }

            & h5 {
                color: $lightFont;
                font-size: 1.1em;
                margin: 0;
            }
        }

        & input {
            width: 100%;
        }

        & input{
            width: 100%;
            background: none;
            pointer-events: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        & input::-moz-range-thumb{
            height: 20px;
            width: 20px;
            border: none;
            border-radius: 50%;
            background: $secondaryColour;
            pointer-events: auto;
            -moz-appearance: none;
            box-shadow: 0 0 6px rgba(0,0,0,0.05);
            z-index: 2;
        }

        & input::-webkit-slider-thumb{
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: $secondaryColour;
            pointer-events: auto;
            -webkit-appearance: none;
            box-shadow: 0 0 6px rgba(0,0,0,0.05);
            z-index: 2;
        }

    }
}
}