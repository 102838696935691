.about {
    &__image-div {
        width: 50%;
        height: 300px;
        float: left;

        & img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__heading {
        color: $secondaryColour;
    }
    
    &__button {
        margin: 2.5em 0;
        border: none;
        padding: 0.5em 2em;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        background-color: $secondaryColour;
        text-transform: capitalize;
    }
}

@include mobileScreen {
    .about {
        &__image-div {
            width: 100%;
            height: 250px;
            float: unset;
        }
    }
}