.navbar-custom {
    background-color: $secondaryColour;
    position: relative;

    &__app-logo {
        width: 50px; 
        margin-right: 10px;
    }

    &__mobile-search-div {
        display: none;
    }

    &__top-div{
        background-color: #ffffff20;
        @include paddingVertical(0.5em);

        &__inner-div {
            margin: 0 auto;
            width: $largeDeviceWidth;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        & ul {
            margin: 0;
        }

        & li {
            margin-left: 5px;
            cursor: pointer;

        }

        & a {
            font-size: $smallFont;
            font-weight: 600;
            color: $lightFont;
        }
    }

    &__item-type {
        &__title {
            color: $lightFont;
            font-size: 0.83em;
        }
        &__drop-down {
            width: 150px;
            border-radius: 4px;
        }
    }

    &__bottom-div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: $largeDeviceWidth;
        margin: 0 auto;
        @include paddingVertical(0.5em);
    }

    &__brand-div {
        display: none;
        flex-direction: column;

        & p {
            color: white;
            margin: 0
        }
    }

    &__top-brand-div {
        display: flex;
        flex-direction: column;

        & p {
            color: white;
            margin: 0
        }
    }

    &__brand {
        display: flex;
        align-items: center;

        &-text {
            font-family: 'Mogra', cursive;
            letter-spacing: 0.5px;
            color: $lightFont;
            font-size: 1.8em;
            margin: 0;
            cursor: pointer;
            padding-top: 5px;

            &--small {
                display: none;
            }
        }
    }

    & bottom-brand-logo {
        display: none;
    }

    &__pop-over{
        position: absolute;
        top: 25px;
        border: 1px solid $secondaryColour;
        border-radius: 5px;
        background-color: white;
    }

    &__user-account-div {
        position: relative;

        &__pop-over {
            position: absolute;
            top: 60px;
            left: 9px;
            border: 1px solid $secondaryColour;
            border-radius: 5px;
            background-color: white;
            width: 150px !important;
            min-height: 50px;
            padding: 0;
            overflow: hidden;
            z-index: 5;

            & li {
                margin: 0; 
                height: 2em;
                border-bottom: 1px solid #00000030;
                transition: background-color 0.3s;

                &:hover {
                    background-color: lighten($primaryColour, 20%);
                }

                &:last-child {
                    border-bottom: unset;
                }
            }

            &-button {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                padding: 0 12.8px !important;
                background-color: transparent;
                @include paddingHorizontal(0.8em);

                & i {
                    color: $secondaryColour;
                    font-size: 0.9em;
                    margin-right: 12px;
                }

                & h6 {
                    color: $secondaryColour;
                    font-size: 0.9em;
                    margin: 0;
                }
            }
        }

        & .pop-over-hidden {
            display: none;
        }
    }

    &__icon-button {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 1em;
        align-items: center;
        outline: none;
        border: none;
        background-color: #00000000;
        padding: 10px;
        border-radius: 4px;

        transition: background-color 0.4s;

        &__text-div *{
            margin: 0;
            text-align: left;
            color: $lightFont;
        }

        &__text-div p{
            font-size: 0.85em;
            line-height: 1;
            margin-bottom: 3px;
            color: #ffffff95;
        }

        &:hover {
            background-color: #00000010;
        }
    }

    &__data-count {
        min-width: 15px;
        height: 15px;
        padding: 2px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: $dangerRed;
        position: absolute;
        top: 10px;
        left: 30px;
        font-size: 0.65em;
        font-weight: 700;
        color: $lightFont;
    }

    &__mobile-data-count {
        min-width: 18px;
        height: 18px;
        padding: 2px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: $dangerRed;
        font-size: 0.7em;
        font-weight: 700;
        color: $lightFont;
    }

    &__text-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        outline: none;
        border: none;
        background-color: transparent;
        border-left: 2px solid white;
        padding-left: 25px;

        & h6 {
            color: $lightFont;
            font-size: 0.85em;
            margin: 0;
        }
    }

    &__icon {
        color: $lightFont;
        font-size: 0.8em;
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        border-radius: 50%;
    }

    & .drawer-button, & .drawer-button__div, & .drawer-button__div__hide {
        display: none;
    }

    &__sign-in {
        padding: 0 15px;
        border-left: none;
    }

    &__register {
        padding-left:15px;
    }

    &__search-div {
        position: absolute !important;
        z-index: 2;
        left: calc(50% - 175px);
    }
}

.bottom-nav {
    width: 100%;
    height: 5.3em;
    background-color: $secondaryColour;
    position: fixed;
    top: -5.5em;
    z-index: 10;
    display: flex;
    justify-content: center;
    transition: top 0.3s;
    box-shadow: 1px 1px 8px #00000090;

    &__content {
        width: $largeDeviceWidth;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $lightFont;
    }

    &__content-search-div {
        display: flex;
        width: 350px;
        height: 2.2em;
        position: relative;

        & input {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            border: none;
            padding: 0 1em;
            padding-right: 80px;
            background-color: #fff;

            &:focus {
                outline: none;
                border: 1px solid rgb(176, 206, 251);
            }
        }
    }

    &__home-icon-link {
        display: flex;
        gap: 3px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $lightFont;
        background-color: darken($secondaryColour, 1%);
        border-radius: 4px;
        width: 55px;
        height: 55px;

        & i {
            color: $lightFont;
            font-size: 1.1em;
        }

        & h6 {
            margin: 0;
            font-size: 0.9em;
        }
    }

    &__content-search-button {
        height: 1.8em;
        width: 1.8em;
        border-radius: 50%;
        border: none;
        outline: none;
        position: absolute;
        top: 0.2em;
        right: 0.2em;
        background-color: $secondaryColour;
        display: flex;
        justify-content: center;
        align-items: center;

        & i {
            font-size: 0.8em;
            color: #FFF;
        }
    }

    &--scrolled {
        top: 0;
    }
}

// LARGE MOBILE SCREEN STYLES

@include largemobileScreen {
    .navbar-custom {
        
        &.toggled {
            position: fixed;
            z-index: 10;
            top:0;
            right: 0;
            left: 0;
        }

        &__top-div{
            display: none;
        }

        &__bottom-div {
            width: 100%;
            margin: 0;
            padding: 0 1em;
            position: relative;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.8em;
        }

        &__brand-div {
            display: flex;
            flex-direction: column;
            gap: 1px;
    
            & p {
                display: block;
                color: white;
                font-size: 0.85em;
                margin: 0;
                letter-spacing: 0.2px;
                align-self: center;
            }
        }
    
        &__brand {
            margin: 0;
        }
    
        & .drawer-button {
            display: flex;
            width: 30px;
            border: none;
            outline: none;
            background-color: transparent;
            height: 22px;
            flex-direction: column;
            justify-content: space-between;

            &__line {
                background-color: white;
                width: 100%;
                height: 3px;
            }

            &__line:last-child {
                width: 60%;
            }

            &__div {
                display: block;
                position: absolute;
                top: 80px;
                width: 100vw;
                right: 0;
                height: calc(100vh - 80px);
                overflow-y: scroll;
                background-color: $backgroundColour;
                z-index: 6;
                padding: 0.8em;
                transition: right 0.2s;

                &__hide {
                    display: block;
                    position: absolute;
                    width: 100vw;
                    top: 80px;
                    right: 105vw;
                    height: calc(100vh - 80px);
                    overflow-y: scroll;
                    background-color: $backgroundColour;
                    z-index: 6;
                    padding: 0.8em;
                    transition: right 0.3s;
                    right: 105vw;
                }

            }

            &__input-button {
                position: absolute;
                z-index: 10;
                top: 0;
                right:0;
                height: 100%;
                padding: 0 0.5em;
                background-color: $secondaryColour;
                color: $lightFont;
                border-radius: 5px;
                border: none;
            }

            &__menu-div{
                & ul {
                  padding: 0;
                }
            }

            &__menu-item {
                height: 2.8em;
                width: 100%;
                border-bottom: 1px solid $primaryGreyColour;

                & button {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    padding: 0 0.5em !important;
                }

                &__button-div {
                    display: flex;
                    align-items: center;

                    & i {
                        font-size: 0.9em;
                        margin-right: 10px;
                    }
                }

                & h6 {
                    font-weight: 700;
                    margin: 0;
                    color: rgb(111, 111, 111);
                }

                & i {
                    color: rgb(111, 111, 111);
                }

                &__inner-button-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 12%;
                    height: 100%;

                    &--modified {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 12%;
                        height: 100%;
                    }
                }
            }

            &__menu-div h5{
                color: $secondaryColour;
                font-weight: 700;
            }

            &__input-div {
                width: 100%;
                height: 2.5em;
                margin-bottom: 20px;
                position: relative;
            }

            &__input {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $secondaryColour;
                @include paddingHorizontal(1em);
            }

            &__sign-in-div {
                display: flex;
                justify-content: space-between;
                height: 2em;
                width: 100%;
                margin-bottom: 30px;
            }

            &__sign-in-button {
                margin-right: 20px;
                height: 100%;
                border-radius: 5px;
                background-color: $secondaryColour;
                color: white;
                border: none;
                flex: 1;
                font-weight: 700;
                letter-spacing: 0.8px;
            }

            &__register-button {
                flex: 1;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $secondaryColour;
                background-color: transparent;
                color: $secondaryColour;
                font-weight: 700;
                letter-spacing: 0.8px;
            }

            &__sign-out-div {
                height: 2.5em;
                width: 100%;
                margin-top: 30px;
            }

            &__sign-out-button {
                height: 100%;
                color: white;
                border: none;
                background-color: $secondaryColour;
                width: 100%;
                border-radius: 5px;
                font-weight: 700;
                letter-spacing: 0.8px;
            }
        }

        &__search-div {
            display: none;
        }
    }   

    .bottom-nav {
        display: none;
    }

}

// MOBILE SCREEN STYLES

@include mobileScreen {
    .navbar-custom {
        
        &.toggled {
            position: fixed;
            z-index: 10;
            top:0;
            right: 0;
            left: 0;
        }

        &__mobile-search-div {
            display: block;
            margin-right: 15px;
            flex: 1;
            position: relative;
            height: 2.1em;
        }

        &__mobile-search-input {
            border-radius: 50px;
            width: 100%;
            height: 100%;
            outline: none;
            border: 1px solid $primaryGreyColour;
            padding: 0 15px;
        }

        &__mobile-search-button {
            height: 1.8em;
            width: 1.8em;
            border-radius: 50%;
            border: none;
            outline: none;
            background-color: $secondaryColour;
            position: absolute;
            top: 0.15em;
            right: 0.2em;
            display: flex;
            align-items: center;
            justify-content: center;

            & i {
                font-size: 0.7em;
                color: $lightFont;
            }
        }

        &__top-div{
            display: none;

            &__inner-div {
                width: 100%;
                padding: 0 0.8em;
            }

            &.alt {
                display: block;
                width: 100%;

                & p {
                    display: none;
                }
            }
        }

        &__bottom-div {
            width: 100%;
            margin: 0;
            padding: 0 1em;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.8em;
        }

        &__brand-div {
            display: flex;
            flex-direction: column;
            gap: 1px;
    
            & p {
                display: block;
                color: white;
                font-size: 0.85em;
                margin: 0;
                letter-spacing: 0.2px;
                align-self: center;
            }
        }
    
        &__brand {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0;
    
            &-text {
                
    
                &--small, &.alt {
                    display: block;
                    font-family: Arial, Helvetica, sans-serif;
                    letter-spacing: 0;
                    color: $lightFont;
                    font-size: 0.6em;
                    margin: 0;
                    margin-top: 3px;
                    cursor: pointer;
                    padding-top: 0;
                }
            }
        }
    
        & .drawer-button {
            display: flex;
            width: 2.1em;
            border-radius: 50%;
            border: none;
            outline: none;
            background-color: #fff;
            height: 2.1em;
            flex-direction: column;
            justify-content: space-between;
            padding: 0.5em;

            &__line {
                background-color: $secondaryColour;
                width: 100%;
                height: 3px;
            }

            &__line:last-child {
                width: 60%;
            }

            &__div {
                display: block;
                position: absolute;
                top: 80px;
                width: 100vw;
                right: 0;
                height: calc(100vh - 80px);
                overflow-y: scroll;
                background-color: $backgroundColour;
                z-index: 6;
                padding: 0.8em;
                transition: right 0.2s;

                &__hide {
                    display: block;
                    position: absolute;
                    width: 100vw;
                    top: 80px;
                    right: 105vw;
                    height: calc(100vh - 80px);
                    overflow-y: scroll;
                    background-color: $backgroundColour;
                    z-index: 6;
                    padding: 0.8em;
                    transition: right 0.3s;
                    right: 105vw;
                }

            }

            &__input-button {
                position: absolute;
                z-index: 10;
                top: 0;
                right:0;
                height: 100%;
                padding: 0 0.5em;
                background-color: $secondaryColour;
                color: $lightFont;
                border-radius: 5px;
                border: none;
            }

            &__menu-div{
                & ul {
                  padding: 0;
                }
            }

            &__menu-item {
                height: 2.8em;
                width: 100%;
                border-bottom: 1px solid $primaryGreyColour;

                & button {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    padding: 0 0.5em !important;
                }

                &__button-div {
                    display: flex;
                    align-items: center;

                    & i {
                        font-size: 0.9em;
                        margin-right: 10px;
                    }
                }

                & h6 {
                    font-weight: 700;
                    margin: 0;
                    color: rgb(111, 111, 111);
                }

                & i {
                    color: rgb(111, 111, 111);
                }

                &__inner-button-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 12%;
                    height: 100%;

                    &--modified {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 12%;
                        height: 100%;
                    }
                }
            }

            &__menu-div h5{
                color: $secondaryColour;
                font-weight: 700;
            }

            &__input-div {
                width: 100%;
                height: 2.5em;
                margin-bottom: 20px;
                position: relative;
            }

            &__input {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $secondaryColour;
                @include paddingHorizontal(1em);
            }

            &__sign-in-div {
                display: flex;
                justify-content: space-between;
                height: 2em;
                width: 100%;
                margin-bottom: 30px;
            }

            &__sign-in-button {
                margin-right: 20px;
                height: 100%;
                border-radius: 5px;
                background-color: $secondaryColour;
                color: white;
                border: none;
                flex: 1;
                font-weight: 700;
                letter-spacing: 0.8px;
            }

            &__register-button {
                flex: 1;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $secondaryColour;
                background-color: transparent;
                color: $secondaryColour;
                font-weight: 700;
                letter-spacing: 0.8px;
            }

            &__sign-out-div {
                height: 2.5em;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 30px;
            }

            &__sign-out-button {
                height: 100%;
                color: white;
                border: none;
                background-color: $secondaryColour;
                width: 100%;
                border-radius: 5px;
                font-weight: 700;
                letter-spacing: 0.8px;
            }

            &__settings-div {
                height: 2.5em;
                width: 100%;
                margin-top: 30px;
            }

            &__settings-button {
                // margin-bottom: 10px;
                height: 100%;
                color: dimgrey;
                border: none;
                background-color: lightgrey;
                width: 100%;
                border-radius: 5px;
                font-weight: 700;
                letter-spacing: 0.8px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                & h6 {
                    font-weight: 700;
                }

                & * {
                    margin: 0;
                }
            }
        }

        &__search-div {
            display: none;
        }
    }   
    .bottom-nav {
        display: none;
    }
}
