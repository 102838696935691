.sell-now {
    background-color: $primaryYellowColour; 
    border-radius: 50px;
    padding: 0.5em 1.5em; 
    cursor: pointer;
    transition: all 0.3s;
    display: inline-block;
    border: 1px solid $primaryYellowColour;
    color: $darkFont;
    font-weight: 700;

    & h6 {
        margin-bottom: 0;
        color: $darkFont;
    }

    &:hover {
        background-color: darken($primaryYellowColour, 2%); 
        border: 1px solid darken($primaryYellowColour, 2%);
        color: $darkFont;
    }

    &:active {
        border: 1px solid $primaryYellowColour !important;
        background-color: $primaryYellowColour !important;
    }
}

.sell-now-button-box__mobile {
    display: none;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@include mobileScreen{
    .navbar-custom{
        & .sell-now {
            display: none;
        }    
    }

    .sell-now {
        padding: 0.3em 2em; 
    }

    .sell-now-button-box__mobile {
        display: flex;
    }
}