.vendor-profile {
    border: 1px solid $primaryGreyColour;
    border-radius: 5px;
    background-color: $primaryMidGreyColour;
    width: 100%;
    height: auto;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    &__image-div {
        border: 1px solid $primaryGreyColour;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        background-color: #fff;
        position: relative;
        margin-bottom: 8px;

        & img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__online-indicator {
        position: absolute;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: $onlineColour;
        left: 8px;
        top: 8px;
        border: 1px solid $primaryGreyColour;
    }

    &__user-name {
        text-align: center;
        font-weight: 700;
    }

    &__user-last-seen {
        text-align: center;
        margin: 0;
        color: darkgrey;
    }
}