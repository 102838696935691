.buttons-box {
    width: 100%;
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &__edit-item-button {
        height: 2.5em;
        width: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        background-color: $secondaryColour;
        color: white;
        gap: 10px;
        border: none;
        outline: none;

        & * {
            margin: 0;
            color: $lightFont;
        }
    }

    &__delete {
        background-color: $dangerRed !important;

        &:hover {
            background-color: lighten($dangerRed, 3%) !important;
        }
    }

    &__sold {
        background-color: $primaryMidGreyColour !important;
        border: 1px solid $primaryGreyColour !important;

        & h6, & i {
          color: rgb(148, 148, 148) !important;
        }

        &:hover {
            background-color: darken($primaryGreyColour, 2%) !important;
        }
    }

    &__modal {
        & .modal-body {
            border: none;
            outline: none;
            min-height: 100px !important;
        }
    }

    &__share-modal {
        &__social-inner-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            & h6 {
                font-size: 0.7em;
            }
        }

        &__whatsapp {
            background-color: #21cc62;

            & i {
                font-size: 2.3em !important;
            }
        }

        &__facebook {
            background-color: #1874ee;
        }

        &__twitter {
            background-color: #1c9cea;

            & i {
                font-size: 2.3em !important;
            }
        }

        &__instagram {
            background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);;

            & i {
                font-size: 2.3em !important;
            }
        }

        &__copy-link-div {
            display: flex;
            justify-content: center;

            &:last-child {
            margin-bottom: 40px;
            }
        }

        &__social-link-div {
            display: flex;
            justify-content: center;
            gap: 30px;
            padding: 3.5em 0 2em;

            & button {
                height: 60px;
                width: 60px;
                border-radius: 5px;
                border: none;
            }

            & i {
                color: white;
                font-weight: 500;
                font-size: 2em;
            }
        }

        &__copy-link-input {
            margin-top: 8px;
            width:65%;
            border-radius: 5px;
            padding: 0.5em 2em;
            border: 1px solid $primaryGreyColour;
            font-weight: 600;
            color: rgb(195, 195, 195);

            &:focus {
                border: 1px solid $primaryGreyColour;
                outline: none;
            }
        }

        &__copy-link-button {
            border-radius: 5px;
            background-color: $secondaryColour;
            color: $lightFont;
            border: none;
            padding: 0.5em 2em;
            font-weight: 600;
        }
    }

    &__sold-button {
        background-color: $secondaryColour;
        color: $lightFont;
        padding: 8px 10px;
        width: 60%;
        border-radius: 5px;
        border: none;
        font-weight: 600;
        margin-bottom: 20px;

        &.sign-in {
            margin-bottom: 0;
            margin-top: 10px;
            width: 100%;
        }

        &--disabled {
            color: $lightFont;
            width: 100%;
            padding: 8px 10px;
            border-radius: 5px;
            border: none;
            font-weight: 600;
            background-color: lighten($secondaryColour, 50%);
            cursor: not-allowed;
            margin-bottom: 20px;
        }
    
    }

    &__register-button {
        background-color: $secondaryColour;
        color: $lightFont;
        padding: 8px 10px;
        width: 60%;
        border-radius: 5px;
        border: none;
        font-weight: 600;
        margin-bottom: 0;

        &.sign-in {
            margin-bottom: 0;
            margin-top: 10px;
            width: 100%;
        }

        &--disabled {
            color: $lightFont;
            width: 100%;
            padding: 8px 10px;
            border-radius: 5px;
            border: none;
            font-weight: 600;
            background-color: lighten($secondaryColour, 50%);
            cursor: not-allowed;
            margin-bottom: 0;
        }
    
    }

    &__verify-button {
        width: 100%;
        background-color: $secondaryColour;
        color: $lightFont;
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        font-weight: 600;
    }

    &__modal-title {
        margin: 0;
        // text-decoration: underline;
    }

    &__discard-button {
        background-color: $dangerRed;
        color: $lightFont;
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        font-weight: 600;
    }

    &__modal-body-custom {
        overflow: visible;
    }

    &__inner-modal-div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2em 2em;
        min-height: 100px;

        &__close-button {
            position: absolute;
            z-index: 3;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            border: none;
            outline: none;
            border-radius: 3px;
            background-color: transparent;
            transition: background-color 0.3s;

            &:hover {
                background-color: lightgrey;
            }

            & div:first-child {
                position: absolute;
                width: 100%;
                top: calc(50% - 2px);
                background-color: lightgrey;
                background-color: dimgrey;
                height: 4px;
                border-radius: 3px;
                transform:rotate(45deg);
            }

            & div:last-child {
                position: absolute;
                width: 100%;
                top: calc(50% - 2px);
                background-color: lightgrey;
                background-color: dimgrey;
                height: 4px;
                border-radius: 3px;
                transform:rotate(-45deg);
            }
        }

        &.register {
            padding: 0.5em 2em;
        }

        &--alt {
            gap: 10px;
        }

        & h4 {
            text-align: center;
            color: rgb(99, 99, 99);
        }
    }

    &__phone-input-div {
        position: relative;

        & h6 {
            position: absolute;
            left: 10px;
            top: 5px;
            border-right: 1px solid grey;
            padding: 4px; 
            padding-right: 7px;
            padding-left: 0;
            font-size: 1.1em;
            font-weight: 700;
        }
    }

    &__phone-input {
        flex: 1;
        height: 100%;
        border-radius: 4px;
        padding: 10px;
        border: 1px solid grey;
    }

    &__input-div--otp {
        width: 100% !important;
        margin-top: 2em;
    }

    &__otp {
        width: 100% !important;
    }

    &__input-outer-div {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
    }

    &__input-div {
        display: flex;
        flex-direction: column;
        width: 100%;

        & label {
            font-weight: 600;
            margin-bottom: 2px;

            & span {
                color: lightgrey;
            }
        }

        & input {
            height: 2.3em;
            width: 100%;
            border-radius: 4px;
            padding: 10px;
            border: 1px solid grey;

            &:focus {
                outline: 1px solid $secondaryColour;
            }
        }
    }

    &__inner-input-div {
        position: relative;

        & input {
            padding-left: 45px;
        }

        & h6 {
            position: absolute;
            top: 0.5em;
            left: 10px;
            z-index: 2;
            font-weight: 700;
            color: grey;
            border-right: 1px solid grey;
            padding-right: 5px;
        }
    }

    &__country-code-text {
        display: block;

        &--mobile {
            display: none;
        }
    }

    &__phone-input-div {
        display: flex;
        flex-direction: column;
        width: 100%;

        & label {
            font-weight: 600;
            margin-bottom: 2px;

            & span {
                color: lightgrey;
            }
        }

        & input {
     
            &:focus {
                outline: 1px solid $secondaryColour;
            }
        }
    }

    &__inner-phone-input-div {
        display: flex;
        justify-content: space-between;
        height: 2.3em;
        gap: 7px;
        position: relative;
    }

    &__country-code-list {
        position: absolute;
        top: 2.5em;
        left: 0;
        z-index: 10;
        border-radius: 4px;
        background-color: #FFFFFF;
        max-height: 130px;
        min-width: 100%;
        overflow-y: scroll;
        box-shadow: 3px 3px 5px dimgrey;
        margin: 0;
        padding: 0;
    }

    &__country-code-list-item {
        height: 2em;
        border: 1px solid rgb(239, 239, 239);

        & button {
            display: flex;
            align-items: center;
            padding: 0 10px;
            gap: 8px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            transition: background-color 0.3s;

            &:hover {
                background-color: rgba(211, 211, 211, 0.603);
            }
        }

        &:last-child {
            border: 1px solid transparent;
        } 

        & p {
            margin: 0;
            font-size: 0.9em;
        }

    }

    &__country-code-div {
        display: flex;
        padding: 8px;
        gap: 5px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid grey;

        & p {
            font-size: 0.85em;
            margin: 0;
        }
    }

    &__verify-phone-number-button {
        border: none;
        outline: none;
        padding: 0 10px;
        border-radius: 4px;
        background-color: $secondaryColour;
        color: $lightFont;

        &--mobile {
            display: none;
        }
    }

    &__phone-input-carret-button {
        padding: 3px;
        border-radius: 100%;
        border: none;
        outline: none;
        background-color: lightgrey;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(90deg);
    }

    &__verification-error-message {
        font-size: 0.85em;
        color: $dangerRed;
        font-weight: 500;
        margin: 0;
        margin-top: 3px;
    }

    &__verification-success-message {
        font-size: 0.85em;
        color: $successGreen;
        font-weight: 500;
        margin: 0;
        margin-top: 3px;
    }

    &__check-box-div {
        width: 100%;
        gap: 10px;
        display: flex;
    }

    &__switch-to-login-div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        &.signin {
         margin: 0;
        }

        & h6 {
            margin: 0;
        }
    }

    &__switch-to-register-div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        &.signin {
         margin: 0;
        }

        & h6 {
            margin: 0;
        }
    }

    &__forgot-password-div {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        & h6 {
            margin: 0;
            color: $secondaryColour;

            &:hover {
                text-decoration: underline;
            }
        }

        & button {
            border: none;
            outline: none;
            background-color: transparent;
        }
    }

    &__check-box-text-div {
        display: flex;
        align-items: center;

        & h6, & button {
            font-size: 0.85;
            margin: 0;
        }

        & span.buttons-box__terms-button {
            color: $secondaryColour;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__switch-to-login-button {
        color: $secondaryColour;

        &:hover {
            text-decoration: underline;
        }
    }

    &__terms-button {
        border: none;
        background-color: transparent;
    }

    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        border-radius: 5px;
        height: 100%;
        flex: 1;
        background-color: $secondaryColour;
        margin-right: 12px;
        transition: background-color 0.3s;

        &:hover {
            background-color: lighten($secondaryColour, 10%);
        }
        
        &:last-child {
            margin-right: 0;
        } 

        & h6, & i {
            margin: 0;
            font-size: 0.95em;
            color: $lightFont;
        }

        & i {
            margin-right: 10px;
        }
    }

    &__form {
        padding: 15px 20px;
        border: none !important;
        outline: none !important;

        & textarea {
            border: 1px solid $primaryGreyColour;
            border-radius: 5px;
            width: 100%;
            height: 150px;
            padding: 10px;
        }
    }

    &__send-report-button {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: $successGreen;
        color: white;
        border: none;
        font-weight: 600;
    }

    &__close-report-button {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: $primaryGreyColour;
        border: none;
        color: rgb(63, 63, 63);
        font-weight: 600;
    }

    &__new-arrivals {
        width: 100%;
        height: 3em;
        border: none;
        outline: none;
        margin-bottom: 20px;
        border-radius: 4px;
        background-color: $secondaryColour;
        color: $lightFont;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        & h1 {
            font-size: 1.2em;
            font-weight: 600;
            color: $lightFont;
            margin: 0;
        }

        & i {
            font-size: 1.2em;
            color: $lightFont;
        }
    }

    &__modal-header {
        margin: 0;
    }

    &__star-rating-inner-div {
        display: flex;
    }

    &__star-rating-icon-div {
        width: 3.5em;
        height: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;

        & i {
            font-size: 2.3em;
            color: lightgrey;
            transition: color 0.3s;

            &.active {
                color: $secondaryColour;
            }
        }
    }

    &__review-text-area {
        border: 1px solid $primaryGreyColour;
            border-radius: 5px;
            width: 100%;
            height: 150px;
            padding: 10px;
    }

    &__modal-logo {
        height: 60px;
    }

    &__modal-title-div {
        padding: 40px 0 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid lightgrey;
        margin-bottom: 15px;

        &.sign-in {
            padding-top: 10px;
        }
    }

    &__modal-title-div-alt {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        // border-bottom: 1px solid lightgrey;
        // margin-bottom: 15px;
    }

    &__modal-logo-div {
        position: absolute;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: #fff;
        z-index: 3;
        top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@include mobileScreen {
    .buttons-box {
        &__country-code-div{
            width: 38%;
        }

        &__country-code-text {
            display: none;

            &--mobile {
                display: block;
            }
        }

        &__phone-input{
            width: 50%;
        }

        &__verify-phone-number-button {
            display: none;
        }

    &__verify-phone-number-button--mobile {
            display: block;
            border: none;
            outline: none;
            padding: 0 10px;
            border-radius: 4px;
            background-color: $secondaryColour;
            color: $lightFont;
            margin-top: 8px;
            height: 2em;
    }
  }
}

