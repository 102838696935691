.chat-list {
    width: 100%;
    min-height: 200px;
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    background-color: $primaryMidGreyColour;
    margin-bottom: 20px;

    &__header {
        border-bottom: 1px solid $primaryGreyColour;
        padding: 15px;
        display: flex;
        align-items: center;

        & h3 {
            font-weight: 700;
            color: $secondaryColour;
            margin: 0;
            margin-right: 30px;
        }
    }

    &__card-info-div {
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;

        & * {
            margin: 0;
        }

        & h6 {
            text-transform: capitalize;
            font-weight: 700;
        }
    }

    &__new-message {
        display: flex;
        align-items: center;

        &__icon {
            height: 20px;
            min-width: 20px;
            padding: 3px;
            border-radius: 5px;
            background-color: $secondaryColour;
            display: flex;
            align-items: center;
            justify-content: center;

            &-value {
                margin: 0;
                font-size: 0.65em;
                color: white;
                line-height: 1;
            }
        }
    }

    &__header-input {
        height: 1.8em;
        border-radius: 3px;
        border: 1px solid $primaryGreyColour;
        @include paddingHorizontal(0.5em);

        &:focus {
          outline: 2px solid $primaryGreyColour;
        }
    }

    &__card-div {
        padding: 8px;
    }

    &__card {
        width: 100%;
        border-radius: 5px;
        border: 1px solid $primaryGreyColour;
        padding: 8px;
        display: flex;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f3f3f3;
        }

        &:last-child {
            margin-bottom: 0px;
        }

        &-inner-div {
            display: flex;
            align-items: center;
        }

        &-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        &-image-div {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $secondaryGreyColour;
            margin-right: 15px;
        }

        &-new-message {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $onlineColour;
        }

        & h5 {
            font-weight: 700;
        }
    }

    &__list-empty {
        text-align: center;
        color: $primaryGreyColour;
    }

    &__main-section {
        width: 100%;
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__mobile-header {
        border-bottom: 1px solid $primaryGreyColour;
        width: 100%;
        height: 60px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $primaryMidGreyColour;

        & h4 {
            margin: 0;
        }

        & a.h6 {
            margin: 0;
        }

        &__user-info {
            display: flex;
            align-items: center;
            gap: 10px;

            & h6 {
                margin: 0;
            }
        }

        &__image-div {
            height: 32px;
            width: 32px;
            border: 1px solid $primaryGreyColour;
            border-radius: 50%;
            overflow: hidden;
        }

        &__image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }

        &__button {
            padding: 0.3em;
            border-radius: 4px;
            background-color: transparent;
            border: 1px solid $primaryGreyColour;
            display: flex;
            align-items: center;
            gap: 5px;

            &-icon {
                font-size: 0.85em; 
            }

            & h6 {
                margin: 0;
            }
        }
    }

    &__header-input {
        width: 65%;
        height: 2em;
        border-radius: 5px;
        outline: none;

        &:focus {
            outline: none;
        }
    }

    &__display-chat {
        height: calc(100vh - 140px);
        padding: 15px;
        overflow: scroll;
        width: 100%;
    }

    &__mobile-card {
        width: 100%;
        border-radius: 5px;
        border: 1px solid $primaryGreyColour;
        padding: 8px;
        display: flex;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f3f3f3;
        }

        &:last-child {
            margin-bottom: 0px;
        }

        &-inner-div {
            display: flex;
            align-items: center;
        }

        &-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        &-image-div {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $secondaryGreyColour;
            margin-right: 15px;
        }

        &-new-message {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $onlineColour;
        }

        & h5 {
            font-weight: 700;
        }
    }

    &__mobile-message-list {
        padding-bottom: 0;
        height: calc(90vh - 140px);
        width: 100%;

        &__inner-div {
            padding: 15px;
            padding-bottom: 0;
            width: 100%;
            height: 100%;
            overflow: scroll;
        }
    }

    &__mobile-footer {
        height: 10vh;
        background-color: $primaryMidGreyColour;
        border-top: 1px solid $primaryGreyColour;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        &__input {
            height: 2em;
            width: 60vw;
            border-radius: 50px;
            border: 1px solid $primaryGreyColour;
            outline: none;
            padding: 0 15px;
        }

        &__button {
            height: 2em;
            width: 20vw;
            border: none;
            outline: none;
            border-radius: 50px;
            color: white;
            background-color: $secondaryColour;
        }
    }
}

@include mobileScreen {
    .chat-list {  
    &__card {
        width: 100% !important;

        &-inner-div {
            display: flex;
            align-items: center;
        }

        &-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        &-image-div {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $secondaryGreyColour;
            margin-right: 15px;
        }

        &-new-message {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $onlineColour;
        }

        & h5 {
            font-weight: 700;
        }
    }
}}