.modal {
    background-color: #FFFFFF80;

    &.hide {
        display: none !important;
    }
}

.modal-body {
    min-height: 300px;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $secondaryColour;
    box-shadow: 4px 4px 8px #00000010;
}

.modal__custom-content {

    &-left {
        min-height: 300px;
        flex: 4;
        background-color: $secondaryColour;
        padding: 4em 1.5em;

        & h2 {
            font-weight: 700;
            color: $lightFont;
        }

        & .line {
            height: 3px;
            width: 100px;
            background-color: $primaryColour;
            margin-bottom: 10px;
        }

        & p {
            line-height: 1.4;
            color: $lightFont;
            font-weight: 600;
            width: 80%;
        }
    }
    &-right {
        min-height: 300px;
        flex: 8;
        background-color: white;
        padding: 3em 2.5em;
        position: relative;

        &.reset-password form {
            padding-top: 3em;
        }

        & .button-close-custom {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        & form input {
            width: 100%;
            height: 2.5em;
            border-radius: 5px;
            border: 1px solid $secondaryColour;
            margin-bottom: 10px;
            padding: 0 1em;

            &:focus {
                outline: 2px solid $primaryColour;
            }
        }

        & .check-box-div {
            display: flex;
            align-items: center;
            margin: 10px 0;

            & input[type="checkbox"] {
                width: 1em;
                height: 1em;
                margin: 0;
                margin-right: 12px;
                border: 1px solid $secondaryColour;
            }

            & h6 {
                margin: 0;
                font-size: 0.9em;
            }
        }

        & .register-button {
            border: none;
            width: 100%;
            height: 2.5em;
            border-radius: 5px;
            background-color: $secondaryColour;
            color: $lightFont;
            display: block;
            font-weight: 700;
            text-align: center;
            line-height: 2.5;
            letter-spacing: 0.8px;
            margin-bottom: 3px;

            &__error-message {
                font-size: 0.95em;
                color: red;
                text-align: center;
            } 

            &:hover {
                background-color: lighten($secondaryColour, 5%);
            }
        } 

        & .switch-to-signin {
            font-size: 0.95em;
            text-align: center;
            margin: 0;

            &__button  {
                outline: none;
                border: none;
                color: $secondaryColour;
                cursor: pointer;
                background-color: transparent;
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__or-separator {
            text-align: center;
            margin: 0;
        }

        &__google-signin-button, &__facebook-signin-button {
            border: none;
            outline: none;
            border-radius: 5px;
            display: block;
            width: 100%;
            height: 2.5em;
            background-color: orangered;
            font-weight: 600;
            color: white;
            letter-spacing: 1px;
            margin-bottom: 0;

            & i {
                color: white;
                margin-right: 10px;
            }
        }

        &__facebook-signin-button {
            background-color: rgb(65, 84, 181);
        }

        &__forgot-password {
            border: none;
            background-color: transparent;
            font-weight: 600;
            color: $secondaryColour;
        }
    }

    &__terms-body {
        height: 50vh;
        width: 100%;
        padding: 15px;
        padding-bottom: 0;
        overflow-y: scroll;
    }

    &__footer-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.modal-body__left-inner-div{
    height: 100%;
    width: 100%;
    background-color: $secondaryColour;
}

.modal-body__left, .modal-body__right {
    padding: 0;
}

.button-close-custom {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

@include mobileScreen {
    .modal-dialog{
        margin-top: 2em;    
    }

    .modal-body {
        min-height: 300px;
    }
    
    .modal__custom-content {
        display: flex;
        flex-direction: column;

        &-left {
            min-height: 10px;
            padding: 1em;
    
            & h2 {
               text-align: center;
            }
    
            & .line {
                margin: 0 auto 10px;
            }
    
            & p {
                text-align: center;
                margin: 0 auto;
            }
        }
        &-right {
            min-height: 100px;
            flex: 1;
            padding: 1.5em 1em;
            position: relative;

            &.reset-password form{
                padding: 1.5em 1em;
            }
    
            & .button-close-custom {
                position: absolute;
                top: 10px;
                right: 10px;
            }

            & form {
                width: 100%;
                padding: none;
            }
    
            & form input {
                width: 100%;
                height: 2.5em;
                border-radius: 5px;
                border: 1px solid $secondaryColour;
                margin-bottom: 15px;
                padding: 0 1em;
    
                &:focus {
                    outline: 2px solid $primaryColour;
                }
            }
    
            & .check-box-div {
                display: flex;
                align-items: center;
                margin: 10px 0;
    
                & input[type="checkbox"] {
                    width: 1em;
                    height: 1em;
                    margin: 0;
                    margin-right: 12px;
                    border: 1px solid $secondaryColour;
                }
    
                & h6 {
                    margin: 0;
                    font-size: 0.9em;
                }
            }
    
            & .register-button {
                height: 2.5em;
                border-radius: 5px;
                background-color: $secondaryColour;
                color: $lightFont;
                display: block;
                font-weight: 700;
                text-align: center;
                line-height: 2.5;
                letter-spacing: 0.8px;
                margin-bottom: 15px;
    
                &:hover {
                    background-color: lighten($secondaryColour, 5%);
                }
            } 
    
            & .switch-to-signin {
                font-size: 0.95em;
                text-align: center;
                margin: 0;
    
                &__button  {
                    outline: none;
                    border: none;
                    color: $secondaryColour;
                    cursor: pointer;
                    background-color: transparent;
                    font-weight: 600;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &__terms-body {
            height: 65vh;
        }
    }
    
    .button-close-custom {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(255, 255, 255, 0.466);
    }
    
    .modal-body__left-inner-div{
        height: 100%;
        width: 100%;
        background-color: $secondaryColour;
    }
    
    .modal-body__left, .modal-body__right {
        padding: 0;
    }    
}


.terms-and-conditions {
    text-transform: capitalize;
    font-weight: 600;
    color: $secondaryColour;

    &:hover {
        text-decoration: underline;
    }
}