.welcome-page {
    &__form-div {
        margin-bottom: 30px;
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;
    }

    &__form {
        width: 100%;
        height: 100%;
        border: 1px solid $secondaryColour;
        border-radius: 8px;
        background-color: lighten($secondaryColour, 50%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    &__input {
        width: 50%;
        border-radius: 5px;
        background-color: white;
        padding: 5px 10px;
        outline: none;
        height: 2.3em;
        border: 1px solid $secondaryColour;
    }

    &__search-button {
        width: 120px;
        color: $lightFont;
        border-radius: 5px;
        background-color: $secondaryColour;
        border: none;
        height: 2.3em;
    }
}

@include largemobileScreen {
    .welcome-page {
        &__form-div {
            padding: 0 10px;
        }
    
        &__form {
            width: 100%;
            padding: 10px;
            gap: 5px;
        }

        &__input {
            width: unset;
            flex: 1;
        }

        &__search-button {
            width: 70px;
            color: $lightFont;
            border-radius: 5px;
            background-color: $secondaryColour;
            border: none;
            height: 2.3em;
        }
    }
}

@include mobileScreen {
    .welcome-page {
        &__form-div {
            padding: 0 10px;
        }
    
        &__form {
            width: 100%;
            padding: 10px;
            gap: 5px;
        }

        &__input {
            width: unset;
            flex: 1;
        }

        &__search-button {
            width: 70px;
            color: $lightFont;
            border-radius: 5px;
            background-color: $secondaryColour;
            border: none;
            height: 2.3em;
        }
    }
}