.pulse__container {
    padding: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pulse{
    height: 100px;
    width: 100px;
    background-color: $secondaryColour; 
    z-index: 2;
    position: relative;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 50px;
    color: #ffffff;

    i {
        color: white;
        font-size: 0.8em;
    }
}
.pulse:before,
.pulse:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100px;
    background-color: $secondaryColour;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
}
.pulse:before{
    animation: pulse 2s ease-out infinite;
}
.pulse:after{
    animation: pulse 2s 1s ease-out infinite;
}
@keyframes pulse{
    100%{
        transform: scale(1.6);
        opacity: 0;
    }
}