.main-section-div {
    background-color: $backgroundColour;
    height: auto;
    min-height: 406px;
    padding: 30px 0;
}

.main-section {
    margin: 0 auto;
    width: $largeDeviceWidth;
    height: 100%;

    &__left-div {
        width: 300px;
        margin-right: 10px;
    }

    &__right-div {
        flex: 1;    
        margin-left: 10px;

        &__category-alt {
            display: flex;
            flex-direction: column;

            & .hero {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-bottom: 20px;
            }
        }
    }
}

    .main-section__mobile-div{
        display: none;
    }

    @include largemobileScreen {
        .main-section-div {
            background-color: $backgroundColour;
            height: auto;
            padding: 20px 0;
        }
    
        .main-section {
            margin: 0 auto;
            width: 100%;
            height: 100%;
        
            &__left-div {
                display: none;
                flex: 1;
                margin-right: 10px;
            }
        
            &__right-div {
                flex: unset;   
                width: 100%; 
                margin: 0;
                padding: 0 0.5em;
            }
        }
    
        .main-section__mobile-div{
            display: block;
            margin-bottom: 30px;
        }
    }    

@include mobileScreen {
    .main-section-div {
        background-color: $backgroundColour;
        height: auto;
        padding: 15px 0;
    }

    .main-section {
        margin: 0 auto;
        width: 100%;
        height: 100%;
    
        &__left-div {
            display: none;
            flex: 1;
            margin-right: 10px;
        }
    
        &__right-div {
            flex: unset;   
            width: 100vw; 
            margin: 0;
            padding: 0 0.5em;
        }
    }

    .main-section__mobile-div{
        display: block;
        margin-bottom: 30px;
    }
}