.item-type-button {
    &__outer-div {
        display: flex;
        justify-content: center;
    }

    width: 250px;
    height: 40px;
    border: 1px solid #d3d3d3;
    border-radius: 50px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    &__top-button {
        position: absolute;
        border: 2px solid #f3f3f3;
        height: 100%;
        width: 55%;
        border-radius: 50px;
        color: $lightFont;
        font-weight: 700;
        background-color: $secondaryColour;
        right: 45%;
        transition: right 0.9s;


        &.cars {
            right: 0;
        }
    }



    &__bottom-button-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 50px;

        & button {
            flex: 1;
            outline: none;
            border: none;
            background-color: #f3f3f3;
            font-weight: 700;
            color: rgb(128, 128, 128);
        }
    }
}

@include mobileScreen{
    .item-type-button {
        width: 200px;
        height: 40px;
        margin-bottom: 0;
        margin-top: 10px;
    
        &__top-button {
            font-weight: 600;
    
            &.cars {
                right: 0;
            }
        }
    
        &__bottom-button-div {
            & button {
                font-weight: 600;
            }
        }
    }
}