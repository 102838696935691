.start-chat-button {
    border-radius: 15px;
    position: absolute;
    z-index: 4;
    bottom: 25px;
    right: 50px;
    background-color: $secondaryColour;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    height: 50px;
    min-width: 50px;
    box-shadow: 4px 4px 5px #00000030; 
    border: none;
    gap: 10px;
    padding: 0 20px;


    & * {
        font-size: 1.1em;
        color: $lightFont;
        margin: 0;
    }

    & i {
        margin-top: 5px;
    }

    &:hover {
        background-color: lighten($secondaryColour, 10%);
    }
}

@include mobileScreen {
    .start-chat-button {
        bottom: 10px;
        right: 0;
        height: 40px;
        min-width: 40px;
        box-shadow: 2px 2px 4px #00000030; 
        border-radius: 10px;
        padding: 0 10px;
        gap: 8px;
    
        & * {
            font-size: 0.8em;
            margin: 0;
        }
    }    
}