form input:focus-visible, form textarea:focus-visible  {
    outline-color:  $secondaryColour !important;
}

.p-multiselect-label {
    height: 2.5em;
    display: flex;
    align-items: center;
    padding-left: 15px;
    width: 100%;
    overflow: hidden;
    text-wrap: wrap;
}

.p-multiselect-token {
    margin-right: 10px;
    padding: 4px 10px;
    display: flex;
    column-gap: 4px;
}

.new-item-form {
    &__posted-items-count {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        &__upgrade-button {
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            outline: none;
            background-color: $secondaryColour;
            color: $lightFont;
            font-weight: 600;
        }

        &__progress-bar {
            height: 100%;
            border-radius: 50px;
            background-color: $secondaryColour;
        }

        &__progress-bar-div {
            border: 2px solid $secondaryColour;
            height: 0.7em;
            width: 700px;
            border-radius: 50px;
            margin-bottom: 15px;
        }
    }

    &__input-div {
        display: flex;
        flex-direction: column;
        height: auto;
        overflow: hidden;
        transition: height 0.3s;

        & option, & select {
            text-transform: capitalize;
        }

        &--none {
            display: none;
        }

        &__input-inner-div--alt {
            display: flex;
            align-items: center;
            gap: 20px;

            &__sub-div {
                display: flex;
                flex: 1;
                gap: 10px;
                align-items: center;

                &--alt {
                    display: flex;
                    align-items: center;
                }

                & input {
                    width: 100%;
                }
            }
        }
    }

    &__label {
        color: $secondaryColour;
        font-weight: 700;
        margin-bottom: 5px;

        &--alt {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & h6 {
                font-size: 1em;
                font-weight: 700;
                margin-bottom: 0;
            }
        }

        & span {
            font-weight: 500;
            margin-left: 10px;
            color: $secondaryColour;
        }

        & span.new-item-form__span--alt {
            font-weight: 500;
            margin-bottom: 0;
            color: rgb(223, 26, 26);
        }
    }

    &__file-input-outer-div {
        display: flex;
        gap: 10px;
        margin-top: 15px;
    }

    &__file-input-div {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        position: relative;

        &:first-child {
            & label {
              & div {
                padding-top: 15px;
              }
            }
        }

        & label {
            border-radius: 5px;
            width: 100%;
            height: 100%;
            position: relative;

            & div{
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background-color: $primaryMidGreyColour;
                border: 3px dashed $primaryGreyColour;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 5px;
                cursor: pointer;

            }

            & i, & h6 {
                font-size: 0.9em;
                color: $secondaryColour;
            }

            & h6 span {
                display: block;
                color: $secondaryColour;
                margin-top: 1px;
                font-size: 0.75em;
            }
        }
    }

    &__preview-div {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: $primaryMidGreyColour;
        position: relative;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 5px;

        }

        & button {
            position: absolute;
            width: 2em;
            height: 2em;
            border: none;
            top: -0.4em;
            right: -0.4em;
            background-color: rgba(176, 12, 12, 0.955);
            color: #fff;
            font-size: 0.6em;
            border-radius: 50%;
            z-index: 5;
        }
    }

    &__upload-image-input {
        display: none;
    }

    &__input {
        height: 2.5em;
        width: 100%;
        border-radius: 5px;
        border: 1px solid $primaryGreyColour;
        @include paddingHorizontal(1em);
    }

    &__radio-outer-div {
        justify-content: flex-start;
        flex-direction: column;

        &--promote {
            flex-direction: row;
        }
    }

    &__radio-inner-div {
        display: flex;
        align-items: center;
        margin-right: 50px;
        margin-bottom: 8px;
        height: 2em;
    }

    &__radio-input {
        margin-right: 8px;
        width: 15px;
        height: 15px;
    }

    &__textarea-div {
        display: flex;
        flex-direction: column;
    }

    &__textarea {
        border-radius: 5px;
        border: 1px solid $primaryGreyColour;
        padding: 0.5em;
        width: 100%;
        height: 120px;
    }

    &__radio-label {
        font-weight: 600;
        color: $secondaryColour;
    }

    &__upload-image-div {
        flex-direction: column;
    }

    &__promote-item-div {
        flex-direction: column;
    }

    &__post-item-button {
        border: none;
        padding: 0.5em 5em;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        background-color: $secondaryColour;
        text-transform: capitalize;

        &:active {
            padding: 0.48em 4.9em;
        }
    }

    &__search-location-button {
        border: none;
        padding: 0.5em 1em;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        background-color: $secondaryColour;
    }

    &__radio-inner-button-div {
        display: flex;
        padding: 10px;
        gap: 20px;

        &.alt {
            padding: 10px 0;
        }

        &__button-icon {
            position: absolute;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            top: -7.5px;
            left: -7.5px;
            background-color: lighten($secondaryColour, 30%);
            display: flex;
            justify-content: center;
            align-items: center;

            & i {
                color: white;
                font-size: 0.6em;
            }
        }

        &__radio-button{
            padding: 10px 1em;
            background-color: transparent;
            border: 2px solid $primaryGreyColour;
            border-radius: 8px;
            display: flex;
            gap: 5px;
            flex-direction: column;
            transition: all 0.3s;
            position: relative;

            &--item-type {
                width: 120px;
                height: 45px;
                background-color: transparent;
                border: 2px solid $primaryGreyColour;
                border-radius: 8px;
                display: flex;
                gap: 5px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: all 0.3s;
                position: relative;

                & .active-spot {
                    display: none;
                }

                &:hover {
                    border: 2px solid $secondaryColour !important;

                    & h6 {
                        color: $secondaryColour;
                    }
                }

                &.active {
                    border: 2px solid $secondaryColour !important;

                    & .active-spot {
                        display: block;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        position: absolute;
                        background-color: $secondaryColour;
                        top: 5px;
                        left: 5px;
                    }

                    & h6 {
                        color: $secondaryColour;
                    }
                }
                & h6 {
                    margin-bottom: 0;
                    font-weight: 700;
                }
            }

            &.active {
                border: 2px solid lighten($secondaryColour, 30%);
                background-color: rgb(245, 245, 245);
            }

            & h6 {
                color: $secondaryColour;
            }

            & h4 {
                color: rgb(117, 117, 117);
            }

            & * {
                margin: 0;
            }
        }
    }

    &__location-indicator {
        display: flex;
        align-items: center;
        gap: 7px;
        color:rgba(59, 59, 59, 0.6);

        & h6 {
            margin-bottom: 0; 
        }

        & i {
            color:rgba(59, 59, 59, 0.6);
            font-size: 0.9em;
        }
    }
}

@include mobileScreen{
    .new-item-form {
        width: 100%;

        &__input-div {
            &__input-inner-div--alt {
                flex-direction: column;
                gap: 3px;

                &__sub-div {
                    width: 100%;
                }
            }
        }

        &__posted-items-count {
            &__upgrade-button {
                padding: 8px 15px;
            }
    
            &__progress-bar-div {
                width: 90%;
            }

            &__text {
                font-size: 1.2em;
                text-align: center;
            }
        }

        &__radio-inner-button-div {
            display: flex;
            padding: 0;
            gap: 12px;
    
            &__button-icon {
                width: 15px;
                height: 15px;
                top: 5px;
                left: 5px;
            }
    
            &__radio-button{
                flex: 1;
                padding: 25px 12px 18px;
                background-color: transparent;
                border: 2px solid $primaryGreyColour;
                border-radius: 8px;
                display: flex;
                gap: 2px;
                flex-direction: column;
                transition: all 0.3s;
                position: relative;

                &--item-type {
                    flex: 1;
                    padding: 25px 12px 18px;
                    background-color: transparent;
                    border: 2px solid $primaryGreyColour;
                    border-radius: 8px;
                    display: flex;
                    gap: 2px;
                    flex-direction: column;
                    transition: all 0.3s;
                    position: relative;
                }
    
                &.active {
                    border: 2px solid lighten($secondaryColour, 30%);
                    background-color: rgb(245, 245, 245);
                }
    
                & h6 {
                    color: $secondaryColour;
                    text-align: left;
                    font-size: 0.9em;
                }
    
                & h4 {
                    color: rgb(117, 117, 117);
                    text-align: left;
                    font-size: 1.2em;
                }
    
                & * {
                    margin: 0;
                }
            }
        }

        &__radio-inner-div--promote {
            flex-direction: column;
            margin: 8px 0 15px;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__file-input-outer-div {
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 15px;
        }

        &__file-input-div {
            width: 28vw;
            height: 28vw;
            border-radius: 5px;
            position: relative;
    
            &:first-child {
                & label {
                  & div {
                    padding-top: 10px;
                  }
                }
            }
    
            & label {
                border-radius: 5px;
                width: 100%;
                height: 100%;
                position: relative;
    
                & div{
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    background-color: $primaryMidGreyColour;
                    border: 3px dashed $primaryGreyColour;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;
                    cursor: pointer;
    
                }
    
                & i, & h6 {
                    font-size: 0.8em;
                    color: $secondaryColour;
                }
    
                & h6 span {
                    display: block;
                    color: $secondaryColour;
                    margin-top: 1px;
                    font-size: 0.75em;
                }
            }
        }

        // &__promote-item-div {
        //     flex-direction: column;
        // }

        &__post-item-button {
            width: 100%;
            height: auto;
            padding: 0.5em 1em;
            font-weight: 600;
            color: white;
            margin: 10px 0 30px;
            background-color: $secondaryColour;
        }
    }
}