.search-info-box {
    width: 100%;
    height: auto;
    padding: 20px 15px;
    border-radius: 5px;
    border: 1px solid $secondaryColour;
    border: 1px solid $primaryGreyColour;
    // background-color: lighten($primaryColour, 20%);
    background-color: $primaryMidGreyColour;
    margin-bottom: 15px;

    &__capitalize {
        text-transform: capitalize;
    }

    & h4 {
        color: $secondaryColour;
        font-size: 1.3em;
        margin: 0;
    }
}

@include mobileScreen {
    .search-info-box {
        width: 100%;
        height: auto;
        padding: 20px 10px;
    
        & h4 {
            font-size: 1.05em;
            text-align: center;
        }
    }
}