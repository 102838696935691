// Colours
$primaryColour: #C99D9D;
$secondaryColour: #a18089;
$primaryColour: rgba(255, 255, 0, 0);
// $secondaryColour: #1c5d85;
// $secondaryColour: #2a027d;
$secondaryColour: #511989;
$primaryYellowColour: #fac007;
$primaryGreyColour: lightgrey;
$primaryMidGreyColour: rgb(243, 243, 243);
$secondaryGreyColour: rgb(156, 156, 156);
$lightFont: #ffffff;
$darkFont: #484848;
$backgroundColour: #f5eff2;
$backgroundColour: #fff;
$onlineColour: #7AFF4B;
$dangerRed: rgb(223, 64, 64);
$successGreen: rgb(53, 188, 53);

// Screen Sizes
$largeDeviceWidth: 1400px;

// Font Sizes
$smallFont: 0.9em;