.carousel__custom {
    background-color: rgb(244, 244, 244);
    position: relative;
    height: 100%;
    border-radius: 5px;

    & .carousel-item__custom {
        border-radius: 5px;
        width: 100%;
        height: 472px;
        // border: 1px solid red;
        overflow: hidden;

        & img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    &__dummy-image {
        object-fit: cover !important;
    }

    &__button-prev {
        height: 40px;
        width: 40px;
        border: 4px solid white;
        border-radius: 50%;
        padding: 0.5em;
        background-color: $secondaryColour;
        position: absolute;
        opacity: 1;
        left: -20px;
        top: calc(50% - 20px);
    }

    &__button-next {
        height: 40px;
        width: 40px;
        border: 4px solid white;
        border-radius: 50%;
        padding: 0.5em;
        background-color: $secondaryColour;
        position: absolute;
        opacity: 1;
        right: -20px;
        top: calc(50% - 20px);
    }

    &__add-to-wishlist-button {
        position: absolute;
        z-index: 4;
        top: 25px;
        right: 50px;
        height: 2.8em;
        width: 2.8em;
        border-radius: 50%;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fac007;

        & i {
            font-size: 1.2em;
            color: $lightFont;
        }
    }
}

@include mobileScreen{
    .carousel__custom {
        height: 305px;
    
        & .carousel-item__custom {
            border-radius: 5px;
            width: 100%;
            height: 305px;
            overflow: hidden;
    
            & img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    
        &__dummy-image {
            object-fit: cover !important;
        }
    
        &__button-prev {
            height: 40px;
            width: 40px;
            border: 4px solid white;
            border-radius: 50%;
            padding: 0.5em;
            background-color: $secondaryColour;
            position: absolute;
            opacity: 1;
            left: -20px;
            top: calc(50% - 20px);
        }
    
        &__button-next {
            height: 40px;
            width: 40px;
            border: 4px solid white;
            border-radius: 50%;
            padding: 0.5em;
            background-color: $secondaryColour;
            position: absolute;
            opacity: 1;
            right: -20px;
            top: calc(50% - 20px);
        }

        &__add-to-wishlist-button {
            top: 15px;
            right: 15px;
            height: 2.4em;
            width: 2.4em;
    
            & i {
                font-size: 1em;
            }
        }
    }
}
