.content-info-box {
    width: 100;
    height: auto;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid $primaryGreyColour;
    background-color: $primaryMidGreyColour;
    margin-bottom: 20px;

    &__text {
        color: $secondaryColour;
        margin: 0;
        font-weight: 600;
    }

    &__sub-text {
        margin-top: 5px;
        color: grey;
    }
}