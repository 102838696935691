.product-card {
    width: 100%;
    height: auto;
    position: relative;

    &__add-to-wish-list {
        background-color: #fff;
        background-color: $primaryYellowColour;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        justify-content: center;
        align-items: center;
        border: 1px solid $secondaryColour; 
        border: 1px solid white; 
        position: absolute;
        top: 100px;
        transition: all 0.3s;
        right: 5px;
        cursor: pointer;

        &:hover {
            color: white;
            background-color: $secondaryColour;
            background-color: $primaryYellowColour;
        }

        &:hover &__icon{
            color: white;
        }

        &:hover .product-card__link{
            background-color: lighten($primaryColour, 20%);
        }

        &__icon {
            font-size: 0.9em;
            color: $darkFont;
            transition: all 0.3s;
            color: white;
        }
    }

    &__link {
        display: block;
        border-radius: 5px;
        // border: 1px solid black;
        transition: all 0.3s;
        padding-bottom: 1px;

        &:hover {
            // border: 1px solid lighten($secondaryColour, 10%);
            background-color: $primaryMidGreyColour;
        }

        &:focus {
            outline: 2px solid lighten($secondaryColour, 10%);
        }
    }

    &__image-div {
        border-radius: 5px;
        width: 100%;
        height: 140px;
        background-color: rgba(128, 128, 128, 0.101);
        margin-bottom: 5px;
        border: 1px solid $primaryMidGreyColour;
    }

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }

    &__product-price {
        color: $secondaryColour;
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 5px;
    }

    &__product-name {
        line-height: 1.1;
        height: 1.2em;
        font-weight: 800;
        color: rgb(77, 77, 77);
        font-size: 1em;
        margin-bottom: 6px;
        text-transform: capitalize;
        overflow: hidden;
    }

    &__product-mile-range {
        font-size: 0.8em;
        height: 0.85em;
        font-weight: 400;
        color: rgb(95, 95, 95);
        line-height: 1;
        margin-bottom: 8px;
    }

    &__product-post-date {
        font-size: 0.8em;
        height: 0.85em;
        font-weight: 400;
        color: rgb(95, 95, 95);
        line-height: 1;
        margin-bottom: 18px;
    }

    &__product-location-div {
        margin-bottom: 10px;
    }

    &__product-location-icon {
        font-size: 0.8em;
        color: rgb(157, 157, 157);
    }

    &__product-location-name {
        margin: 0;
        font-size: 0.85em;
        line-height: 0.8;
        text-transform: capitalize; 
    }

    &__product-condition-div {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-flex;
        height: 1.1em;
        background-color: $primaryGreyColour;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 12px 8px;
        justify-content: center;
        align-items: center;
    }

    &__product-condition {
        margin: 0;
        font-weight: 500;
        color: $darkFont;
        font-size: 0.8em;
        line-height: 0.8;
    }

    &__promotion-div {
        position: absolute;
        top: 1px;
        right: 1px;
        padding: 3px 8px;
        background-color: rgba(209, 91, 109, 0.8);
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px; 
    }

    &__promotion {
        font-weight: 500;
        font-size: 0.8em;
        color: $lightFont;
        margin: 0;
    }
}

.product-card-loading {
    border-radius: 5px;
    width: 100%;
    height: auto;
    position: relative;
    background-color: $primaryMidGreyColour;

    &__image {
        border-radius: 5px;
        width: 100%;
        height: 140px;
        background-color: $primaryGreyColour;
        margin-bottom: 5px;
    }

    &__price {
        height: 1em;
        width: 40%;
        background-color: $primaryGreyColour;
        margin-bottom: 5px;
    }

    &__text-div {
        height: 2.4em;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & div {
            height: 1em;
            border-radius: 5px;
            background-color: $primaryGreyColour;
            width: 80%;

            &:last-child {
                width: 60%;
            }
        }
    }

    &__location {
        height: 0.85em;
        border-radius: 5px;
        background-color: $primaryGreyColour;
        width: 80%;
        margin-bottom: 10px;
    }

    &__condition {
        height: 1.1em;
        background-color: $primaryGreyColour;
        border-radius: 5px;
        width: 40%;
        align-items: center;
    }

    & .loading {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background-image: linear-gradient(to left , transparent , rgba(255,255,255,0.5), transparent);
            animation: loading 1.5s infinite;
        }
}
}