.ad-panel {
    border-radius: 5px;
    background-color: #E3E3E3;
    width: 295px;
    height: 295px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: sticky !important;
    top: 15px !important;
    
    &__title {
        text-align: center;
        font-weight: 700;
        color: rgb(171, 171, 171);
    }
}

.ad-panel-horizontal {
    border-radius: 5px;
    background-color: #E3E3E3;
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    
    &__title {
        text-align: center;
        font-weight: 700;
        color: rgb(171, 171, 171);
    }
}

.google-ad {
    background-color: #e3e3e31d;
    width: 295px;
    height: auto;
    margin-bottom: 20px;
    overflow: hidden;
    position: sticky !important;
    top: 15px !important;
}

.google-ad-panel-horizontal {
    background-color: #e3e3e31d;
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}