.search__categories-box {

    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
    }
    
    button {
        border: none;
        background-color: $primaryMidGreyColour;
        border: 1px solid $primaryGreyColour;
        padding: 0.5em 1em;
        border-radius: 50px;
        color: $secondaryColour;
        font-weight:700;
        letter-spacing: 0.8px;
        margin: 0.3em;
         
        & * {
            font-weight: 700;
            margin: 0;
        } 
    }
}

@include mobileScreen {
    .search__categories-box {
        & ul{
            flex-wrap: nowrap;
            width: 100%;
            overflow-x: scroll;
            justify-content: flex-start;
            align-items: center;
        }

        & h6 {
            text-wrap: nowrap;
            font-size: 0.9em;
            margin-bottom: 0;
            font-weight: 700;
        }

        & button {
            max-width: 400px;
            display: inline-block;
        }
    }
}