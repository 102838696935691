.top-brands {
    width: 100%;
    height: auto;
    display: flex; 
    justify-content: flex-start;
    margin-bottom: 20px;
    overflow-x: scroll;
    gap: 15px;
    
    &::-webkit-scrollbar {
        display: none;
    }

    &__brand-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        height: auto;
    }

    &__image-div {
        border-radius: 50%;
        border: 1px solid $primaryGreyColour;
        width: 70px;
        height: 70px;
        margin-bottom: 5px;
        padding: 5px;
    }

    &__image {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__text {
        text-align: center;
        font-size: 0.9em;
    }
}

@include mobileScreen {
    .top-brands {
        margin-bottom: 20px;
        gap: 15px;
    
        &__image-div {
            width: 55px;
            height: 55px;
            margin-bottom: 5px;
            padding: 5px;
        }

        &__text {
            font-size: 0.8em;
        }
    }
}

