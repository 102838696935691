.navbar-custom {
  background-color: #511989;
  position: relative;
}
.navbar-custom__app-logo {
  width: 50px;
  margin-right: 10px;
}
.navbar-custom__mobile-search-div {
  display: none;
}
.navbar-custom__top-div {
  background-color: rgba(255, 255, 255, 0.1254901961);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.navbar-custom__top-div__inner-div {
  margin: 0 auto;
  width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-custom__top-div ul {
  margin: 0;
}
.navbar-custom__top-div li {
  margin-left: 5px;
  cursor: pointer;
}
.navbar-custom__top-div a {
  font-size: 0.9em;
  font-weight: 600;
  color: #ffffff;
}
.navbar-custom__item-type__title {
  color: #ffffff;
  font-size: 0.83em;
}
.navbar-custom__item-type__drop-down {
  width: 150px;
  border-radius: 4px;
}
.navbar-custom__bottom-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 1400px;
  margin: 0 auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.navbar-custom__brand-div {
  display: none;
  flex-direction: column;
}
.navbar-custom__brand-div p {
  color: white;
  margin: 0;
}
.navbar-custom__top-brand-div {
  display: flex;
  flex-direction: column;
}
.navbar-custom__top-brand-div p {
  color: white;
  margin: 0;
}
.navbar-custom__brand {
  display: flex;
  align-items: center;
}
.navbar-custom__brand-text {
  font-family: "Mogra", cursive;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-size: 1.8em;
  margin: 0;
  cursor: pointer;
  padding-top: 5px;
}
.navbar-custom__brand-text--small {
  display: none;
}
.navbar-custom bottom-brand-logo {
  display: none;
}
.navbar-custom__pop-over {
  position: absolute;
  top: 25px;
  border: 1px solid #511989;
  border-radius: 5px;
  background-color: white;
}
.navbar-custom__user-account-div {
  position: relative;
}
.navbar-custom__user-account-div__pop-over {
  position: absolute;
  top: 60px;
  left: 9px;
  border: 1px solid #511989;
  border-radius: 5px;
  background-color: white;
  width: 150px !important;
  min-height: 50px;
  padding: 0;
  overflow: hidden;
  z-index: 5;
}
.navbar-custom__user-account-div__pop-over li {
  margin: 0;
  height: 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1882352941);
  transition: background-color 0.3s;
}
.navbar-custom__user-account-div__pop-over li:hover {
  background-color: rgba(255, 255, 102, 0);
}
.navbar-custom__user-account-div__pop-over li:last-child {
  border-bottom: unset;
}
.navbar-custom__user-account-div__pop-over-button {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0 12.8px !important;
  background-color: transparent;
  padding-left: 0.8em;
  padding-right: 0.8em;
}
.navbar-custom__user-account-div__pop-over-button i {
  color: #511989;
  font-size: 0.9em;
  margin-right: 12px;
}
.navbar-custom__user-account-div__pop-over-button h6 {
  color: #511989;
  font-size: 0.9em;
  margin: 0;
}
.navbar-custom__user-account-div .pop-over-hidden {
  display: none;
}
.navbar-custom__icon-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
  outline: none;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.4s;
}
.navbar-custom__icon-button__text-div * {
  margin: 0;
  text-align: left;
  color: #ffffff;
}
.navbar-custom__icon-button__text-div p {
  font-size: 0.85em;
  line-height: 1;
  margin-bottom: 3px;
  color: rgba(255, 255, 255, 0.5843137255);
}
.navbar-custom__icon-button:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}
.navbar-custom__data-count {
  min-width: 15px;
  height: 15px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: rgb(223, 64, 64);
  position: absolute;
  top: 10px;
  left: 30px;
  font-size: 0.65em;
  font-weight: 700;
  color: #ffffff;
}
.navbar-custom__mobile-data-count {
  min-width: 18px;
  height: 18px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(223, 64, 64);
  font-size: 0.7em;
  font-weight: 700;
  color: #ffffff;
}
.navbar-custom__text-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  outline: none;
  border: none;
  background-color: transparent;
  border-left: 2px solid white;
  padding-left: 25px;
}
.navbar-custom__text-button h6 {
  color: #ffffff;
  font-size: 0.85em;
  margin: 0;
}
.navbar-custom__icon {
  color: #ffffff;
  font-size: 0.8em;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 50%;
}
.navbar-custom .drawer-button, .navbar-custom .drawer-button__div, .navbar-custom .drawer-button__div__hide {
  display: none;
}
.navbar-custom__sign-in {
  padding: 0 15px;
  border-left: none;
}
.navbar-custom__register {
  padding-left: 15px;
}
.navbar-custom__search-div {
  position: absolute !important;
  z-index: 2;
  left: calc(50% - 175px);
}

.bottom-nav {
  width: 100%;
  height: 5.3em;
  background-color: #511989;
  position: fixed;
  top: -5.5em;
  z-index: 10;
  display: flex;
  justify-content: center;
  transition: top 0.3s;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5647058824);
}
.bottom-nav__content {
  width: 1400px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}
.bottom-nav__content-search-div {
  display: flex;
  width: 350px;
  height: 2.2em;
  position: relative;
}
.bottom-nav__content-search-div input {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: none;
  padding: 0 1em;
  padding-right: 80px;
  background-color: #fff;
}
.bottom-nav__content-search-div input:focus {
  outline: none;
  border: 1px solid rgb(176, 206, 251);
}
.bottom-nav__home-icon-link {
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #4e1885;
  border-radius: 4px;
  width: 55px;
  height: 55px;
}
.bottom-nav__home-icon-link i {
  color: #ffffff;
  font-size: 1.1em;
}
.bottom-nav__home-icon-link h6 {
  margin: 0;
  font-size: 0.9em;
}
.bottom-nav__content-search-button {
  height: 1.8em;
  width: 1.8em;
  border-radius: 50%;
  border: none;
  outline: none;
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  background-color: #511989;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-nav__content-search-button i {
  font-size: 0.8em;
  color: #FFF;
}
.bottom-nav--scrolled {
  top: 0;
}

@media (max-width: 1000px) {
  .navbar-custom.toggled {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
  }
  .navbar-custom__top-div {
    display: none;
  }
  .navbar-custom__bottom-div {
    width: 100%;
    margin: 0;
    padding: 0 1em;
    position: relative;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em;
  }
  .navbar-custom__brand-div {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
  .navbar-custom__brand-div p {
    display: block;
    color: white;
    font-size: 0.85em;
    margin: 0;
    letter-spacing: 0.2px;
    align-self: center;
  }
  .navbar-custom__brand {
    margin: 0;
  }
  .navbar-custom .drawer-button {
    display: flex;
    width: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    height: 22px;
    flex-direction: column;
    justify-content: space-between;
  }
  .navbar-custom .drawer-button__line {
    background-color: white;
    width: 100%;
    height: 3px;
  }
  .navbar-custom .drawer-button__line:last-child {
    width: 60%;
  }
  .navbar-custom .drawer-button__div {
    display: block;
    position: absolute;
    top: 80px;
    width: 100vw;
    right: 0;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    background-color: #fff;
    z-index: 6;
    padding: 0.8em;
    transition: right 0.2s;
  }
  .navbar-custom .drawer-button__div__hide {
    display: block;
    position: absolute;
    width: 100vw;
    top: 80px;
    right: 105vw;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    background-color: #fff;
    z-index: 6;
    padding: 0.8em;
    transition: right 0.3s;
    right: 105vw;
  }
  .navbar-custom .drawer-button__input-button {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 0.5em;
    background-color: #511989;
    color: #ffffff;
    border-radius: 5px;
    border: none;
  }
  .navbar-custom .drawer-button__menu-div ul {
    padding: 0;
  }
  .navbar-custom .drawer-button__menu-item {
    height: 2.8em;
    width: 100%;
    border-bottom: 1px solid lightgrey;
  }
  .navbar-custom .drawer-button__menu-item button {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0 0.5em !important;
  }
  .navbar-custom .drawer-button__menu-item__button-div {
    display: flex;
    align-items: center;
  }
  .navbar-custom .drawer-button__menu-item__button-div i {
    font-size: 0.9em;
    margin-right: 10px;
  }
  .navbar-custom .drawer-button__menu-item h6 {
    font-weight: 700;
    margin: 0;
    color: rgb(111, 111, 111);
  }
  .navbar-custom .drawer-button__menu-item i {
    color: rgb(111, 111, 111);
  }
  .navbar-custom .drawer-button__menu-item__inner-button-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12%;
    height: 100%;
  }
  .navbar-custom .drawer-button__menu-item__inner-button-div--modified {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 12%;
    height: 100%;
  }
  .navbar-custom .drawer-button__menu-div h5 {
    color: #511989;
    font-weight: 700;
  }
  .navbar-custom .drawer-button__input-div {
    width: 100%;
    height: 2.5em;
    margin-bottom: 20px;
    position: relative;
  }
  .navbar-custom .drawer-button__input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #511989;
    padding-left: 1em;
    padding-right: 1em;
  }
  .navbar-custom .drawer-button__sign-in-div {
    display: flex;
    justify-content: space-between;
    height: 2em;
    width: 100%;
    margin-bottom: 30px;
  }
  .navbar-custom .drawer-button__sign-in-button {
    margin-right: 20px;
    height: 100%;
    border-radius: 5px;
    background-color: #511989;
    color: white;
    border: none;
    flex: 1;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .navbar-custom .drawer-button__register-button {
    flex: 1;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #511989;
    background-color: transparent;
    color: #511989;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .navbar-custom .drawer-button__sign-out-div {
    height: 2.5em;
    width: 100%;
    margin-top: 30px;
  }
  .navbar-custom .drawer-button__sign-out-button {
    height: 100%;
    color: white;
    border: none;
    background-color: #511989;
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .navbar-custom__search-div {
    display: none;
  }
  .bottom-nav {
    display: none;
  }
}
@media (max-width: 430px) {
  .navbar-custom.toggled {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
  }
  .navbar-custom__mobile-search-div {
    display: block;
    margin-right: 15px;
    flex: 1;
    position: relative;
    height: 2.1em;
  }
  .navbar-custom__mobile-search-input {
    border-radius: 50px;
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid lightgrey;
    padding: 0 15px;
  }
  .navbar-custom__mobile-search-button {
    height: 1.8em;
    width: 1.8em;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: #511989;
    position: absolute;
    top: 0.15em;
    right: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-custom__mobile-search-button i {
    font-size: 0.7em;
    color: #ffffff;
  }
  .navbar-custom__top-div {
    display: none;
  }
  .navbar-custom__top-div__inner-div {
    width: 100%;
    padding: 0 0.8em;
  }
  .navbar-custom__top-div.alt {
    display: block;
    width: 100%;
  }
  .navbar-custom__top-div.alt p {
    display: none;
  }
  .navbar-custom__bottom-div {
    width: 100%;
    margin: 0;
    padding: 0 1em;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em;
  }
  .navbar-custom__brand-div {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
  .navbar-custom__brand-div p {
    display: block;
    color: white;
    font-size: 0.85em;
    margin: 0;
    letter-spacing: 0.2px;
    align-self: center;
  }
  .navbar-custom__brand {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
  }
  .navbar-custom__brand-text--small, .navbar-custom__brand-text.alt {
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    color: #ffffff;
    font-size: 0.6em;
    margin: 0;
    margin-top: 3px;
    cursor: pointer;
    padding-top: 0;
  }
  .navbar-custom .drawer-button {
    display: flex;
    width: 2.1em;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 2.1em;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5em;
  }
  .navbar-custom .drawer-button__line {
    background-color: #511989;
    width: 100%;
    height: 3px;
  }
  .navbar-custom .drawer-button__line:last-child {
    width: 60%;
  }
  .navbar-custom .drawer-button__div {
    display: block;
    position: absolute;
    top: 80px;
    width: 100vw;
    right: 0;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    background-color: #fff;
    z-index: 6;
    padding: 0.8em;
    transition: right 0.2s;
  }
  .navbar-custom .drawer-button__div__hide {
    display: block;
    position: absolute;
    width: 100vw;
    top: 80px;
    right: 105vw;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    background-color: #fff;
    z-index: 6;
    padding: 0.8em;
    transition: right 0.3s;
    right: 105vw;
  }
  .navbar-custom .drawer-button__input-button {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 0.5em;
    background-color: #511989;
    color: #ffffff;
    border-radius: 5px;
    border: none;
  }
  .navbar-custom .drawer-button__menu-div ul {
    padding: 0;
  }
  .navbar-custom .drawer-button__menu-item {
    height: 2.8em;
    width: 100%;
    border-bottom: 1px solid lightgrey;
  }
  .navbar-custom .drawer-button__menu-item button {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0 0.5em !important;
  }
  .navbar-custom .drawer-button__menu-item__button-div {
    display: flex;
    align-items: center;
  }
  .navbar-custom .drawer-button__menu-item__button-div i {
    font-size: 0.9em;
    margin-right: 10px;
  }
  .navbar-custom .drawer-button__menu-item h6 {
    font-weight: 700;
    margin: 0;
    color: rgb(111, 111, 111);
  }
  .navbar-custom .drawer-button__menu-item i {
    color: rgb(111, 111, 111);
  }
  .navbar-custom .drawer-button__menu-item__inner-button-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12%;
    height: 100%;
  }
  .navbar-custom .drawer-button__menu-item__inner-button-div--modified {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 12%;
    height: 100%;
  }
  .navbar-custom .drawer-button__menu-div h5 {
    color: #511989;
    font-weight: 700;
  }
  .navbar-custom .drawer-button__input-div {
    width: 100%;
    height: 2.5em;
    margin-bottom: 20px;
    position: relative;
  }
  .navbar-custom .drawer-button__input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #511989;
    padding-left: 1em;
    padding-right: 1em;
  }
  .navbar-custom .drawer-button__sign-in-div {
    display: flex;
    justify-content: space-between;
    height: 2em;
    width: 100%;
    margin-bottom: 30px;
  }
  .navbar-custom .drawer-button__sign-in-button {
    margin-right: 20px;
    height: 100%;
    border-radius: 5px;
    background-color: #511989;
    color: white;
    border: none;
    flex: 1;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .navbar-custom .drawer-button__register-button {
    flex: 1;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #511989;
    background-color: transparent;
    color: #511989;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .navbar-custom .drawer-button__sign-out-div {
    height: 2.5em;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .navbar-custom .drawer-button__sign-out-button {
    height: 100%;
    color: white;
    border: none;
    background-color: #511989;
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .navbar-custom .drawer-button__settings-div {
    height: 2.5em;
    width: 100%;
    margin-top: 30px;
  }
  .navbar-custom .drawer-button__settings-button {
    height: 100%;
    color: dimgrey;
    border: none;
    background-color: lightgrey;
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    letter-spacing: 0.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .navbar-custom .drawer-button__settings-button h6 {
    font-weight: 700;
  }
  .navbar-custom .drawer-button__settings-button * {
    margin: 0;
  }
  .navbar-custom__search-div {
    display: none;
  }
  .bottom-nav {
    display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #484848;
}

ul li {
  list-style: none;
}

a {
  color: #484848;
  text-decoration: none;
}

.main-auth-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.auth-form {
  width: 550px;
  height: auto;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 25px;
}
.auth-form__outer-div {
  display: flex;
  justify-content: center;
}
.auth-form__title-div__title {
  text-align: center;
}
.auth-form__title-div__sub-title-div {
  display: inline-flex;
  justify-content: center;
  border: 1px solid lightgrey;
  border-radius: 50px;
  padding: 5px;
  padding-left: 13px;
  gap: 8px;
  margin-bottom: 10px;
}
.auth-form__title-div__user-verified {
  background-color: rgb(53, 188, 53);
  color: white;
  border-radius: 50px;
  padding: 2px 8px;
  margin: 0;
}
.auth-form__title-div__user-not-verified {
  background-color: rgb(223, 64, 64);
  color: white;
  border-radius: 50px;
  padding: 1px 8px;
  margin: 0;
}
.auth-form__title-div__sub-title-outer-div {
  display: flex;
  justify-content: center;
}
.auth-form__title-div__sub-title {
  margin: 0;
}
.auth-form__title-div__underline {
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: lightgrey;
}
.auth-form__forgot-password-button {
  border: none;
  background-color: transparent;
  outline: none;
  font-weight: 700;
  text-align: left;
  color: #511989;
}
.auth-form__forgot-password-button:hover {
  text-decoration: underline;
}
.auth-form__div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.auth-form__input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.auth-form__input-div label {
  font-weight: 600;
  margin-bottom: 5px;
}
.auth-form__input-div span {
  font-weight: 600;
  color: lightgrey;
}
.auth-form__input-div input {
  width: 100%;
  height: 2.3em;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 5px 10px;
}
.auth-form__register-button {
  width: 100%;
  height: 2.3em;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #511989;
  border: none;
  outline: none;
}
.auth-form__or-separator {
  text-align: center;
  font-weight: 600;
  margin: 0;
}
.auth-form__google-button {
  background-color: orangered;
  color: #ffffff;
  width: 100%;
  height: 2.3em;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  outline: none;
}
.auth-form__switch-to-sign-in {
  text-align: center;
}
.auth-form__switch-to-sign-in__button {
  border: none;
  background-color: transparent;
  outline: none;
  color: #511989;
  font-weight: 700;
}
.auth-form__switch-to-sign-in__button:hover {
  text-decoration: underline;
}
.auth-form__display-name-div {
  width: 100%;
  display: flex;
  gap: 10px;
}
.auth-form__check-box-div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.auth-form__check-box-div h6 {
  margin-bottom: 0;
}
.auth-form__check-box-div span {
  font-weight: 700;
  color: #511989;
}
.auth-form__check-box-div span:hover {
  text-decoration: underline;
}

@media (max-width: 430px) {
  .auth-form {
    border: none;
  }
  .auth-form__input-div--first-name {
    margin-bottom: 10px;
  }
  .auth-form__title-div__underline {
    margin-bottom: 20px;
  }
  .auth-form__display-name-div {
    display: block;
    gap: unset;
  }
}
.verify-email-box__outer-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.verify-email-box {
  width: 600px;
  height: auto;
  background-color: rgb(243, 243, 243);
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.verify-email-box--success {
  border: 1px solid rgb(53, 188, 53);
  background-color: rgb(234, 239, 234);
}
.verify-email-box__outer-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.verify-email-box__title-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.verify-email-box__title {
  text-align: center;
}
.verify-email-box__title-div__underline {
  width: 80%;
  height: 1px;
  background-color: lightgrey;
}
.verify-email-box__info-text span {
  color: dimgrey;
  font-weight: 800;
}
.verify-email-box__resend-email-button {
  height: 2.3em;
  padding: 4px 20px;
  border-radius: 4px;
  color: white;
  border: none;
  outline: none;
  background-color: #511989;
}
.verify-email-box__resend-email-button--success {
  background-color: rgb(53, 188, 53);
}
.verify-email-box__countdown {
  font-weight: 700;
  font-size: 1.5em;
  color: rgb(223, 64, 64);
}
.verify-email-box__resend-email-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 50px;
}
.verify-email-box__resend-mail-button {
  color: white;
  padding: 3px 20px;
  height: 2.3em;
  width: 35%;
  border-radius: 4px;
  background-color: rgb(53, 188, 53);
  border: none;
  outline: none;
}

.sell-now {
  background-color: #fac007;
  border-radius: 50px;
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  border: 1px solid #fac007;
  color: #484848;
  font-weight: 700;
}
.sell-now h6 {
  margin-bottom: 0;
  color: #484848;
}
.sell-now:hover {
  background-color: #f2b905;
  border: 1px solid #f2b905;
  color: #484848;
}
.sell-now:active {
  border: 1px solid #fac007 !important;
  background-color: #fac007 !important;
}

.sell-now-button-box__mobile {
  display: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 430px) {
  .navbar-custom .sell-now {
    display: none;
  }
  .sell-now {
    padding: 0.3em 2em;
  }
  .sell-now-button-box__mobile {
    display: flex;
  }
}
.categories-box {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.categories-box__title {
  color: #484848;
  margin: 0;
  font-weight: 700;
}
.categories-box__title-div {
  border: 1px solid #fac007;
  background-color: #fac007;
  padding-left: 1em;
  padding-right: 1em;
  height: 50px;
}
.categories-box__lines-div {
  width: 1em;
  height: 0.8em;
  margin-right: 1em;
}
.categories-box__lines {
  width: 100%;
  height: 2px;
  background-color: #484848;
}
.categories-box__list {
  padding: 0;
  margin: 0;
  height: 350px;
  border: 1px solid lightgrey;
}
.categories-box__list-item {
  flex: 1;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.062745098);
}
.categories-box__list-item-icon {
  font-size: 0.8em;
}
.categories-box__list-item:last-child {
  border-bottom: none;
}
.categories-box__list-item a {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: 600;
  transition: all 0.3s;
  padding-left: 1em;
  padding-right: 1em;
}
.categories-box__list-item a:hover {
  background-color: rgb(243, 243, 243);
  padding-right: 1.3em;
}
.categories-box__list-item p {
  margin: 0;
}

@media (max-width: 430px) {
  .categories-box {
    display: none;
  }
}
.hero {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.hero__text-large {
  display: block;
}
.hero__text-small {
  display: none;
}
.hero__form-element {
  width: 100%;
  height: 2.5em;
  position: relative;
}
.hero__main-div {
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  height: 165px;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
}
.hero__main-div__image {
  position: absolute;
  bottom: 0;
  right: 100px;
  height: 150px;
}
.hero__main-div h1 {
  width: 80%;
  font-size: 1.8em;
  font-weight: 700;
  color: #511989;
  margin-bottom: 5px;
}
.hero__main-div p {
  width: 80%;
  font-weight: 500;
  margin-bottom: 10px;
}
.hero__search-div {
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  height: 130px;
  border: 1px solid lightgrey;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hero__search-div__image {
  position: absolute;
  z-index: 1;
  bottom: 0;
  height: 220px;
  left: 230px;
}
.hero__search-div__input {
  border: none;
  height: 2.5em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #511989;
  padding: 0 8em 0 1em;
}
.hero__search-div__input:focus {
  outline: 2px solid #842fd9;
}
.hero__search-div__button {
  border: none;
  outline: none;
  height: 2.5em;
  width: 20%;
  right: 0;
  z-index: 1;
  position: absolute;
  border-radius: 5px;
  color: #ffffff;
  background-color: #511989;
  font-weight: 700;
  letter-spacing: 1px;
}
.hero__form {
  width: 600px;
  background-color: white;
  height: 2.5em;
  z-index: 2;
  border-radius: 5px;
}
.hero__search-box {
  border: none;
  width: 100%;
  height: 2.5em;
  padding-left: 1em;
  padding-right: 1em;
  border-start-start-radius: 5px;
}

.hero-section-brands {
  max-width: 1000px;
  overflow: scroll;
  display: flex;
  gap: 15px;
}
.hero-section-brands__div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
}
.hero-section-brands::-webkit-scrollbar {
  display: none;
}

@media (max-width: 430px) {
  .hero {
    width: 100%;
    height: auto;
  }
  .hero__text-large {
    display: none;
  }
  .hero__text-small {
    display: block;
  }
  .hero__main-div {
    width: 100%;
    height: 180px;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
  }
  .hero__main-div__image {
    position: absolute;
    bottom: 0;
    right: 10px;
    height: 140px;
  }
  .hero__main-div h1 {
    width: 80%;
    font-weight: 700;
    font-size: 1.2em;
    color: #511989;
    margin-bottom: 5px;
  }
  .hero__main-div p {
    width: 60%;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .hero__search-div {
    height: 90px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .hero__search-div__image {
    display: none;
  }
  .hero__search-div__input {
    border: none;
    height: 2.5em;
    width: 80%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #511989;
    padding-left: 1em;
    padding-right: 1em;
  }
  .hero__search-div__input:focus {
    outline: 2px solid #842fd9;
  }
  .hero__search-div__button {
    border: none;
    outline: none;
    height: 2.5em;
    width: 20%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #ffffff;
    background-color: #511989;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .hero__form {
    width: 600px;
    background-color: white;
    height: 2.5em;
    z-index: 2;
    border-radius: 5px;
  }
  .hero__search-box {
    border: none;
    width: 100%;
    height: 2.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-start-start-radius: 5px;
  }
}
.section-header {
  width: 100%;
  height: 2em;
  margin-bottom: 20px;
}
.section-header__div {
  height: 100%;
  width: auto;
  color: #ffffff;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #511989;
  border-radius: 5px;
  border-bottom-right-radius: 0;
}
.section-header__div h6 {
  margin: 0;
  letter-spacing: 0.8px;
}
.section-header__line {
  height: 3px;
  background-color: #511989;
  flex: 1;
}

.product-card {
  width: 100%;
  height: auto;
  position: relative;
}
.product-card__add-to-wish-list {
  background-color: #fff;
  background-color: #fac007;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border: 1px solid #511989;
  border: 1px solid white;
  position: absolute;
  top: 100px;
  transition: all 0.3s;
  right: 5px;
  cursor: pointer;
}
.product-card__add-to-wish-list:hover {
  color: white;
  background-color: #511989;
  background-color: #fac007;
}
.product-card__add-to-wish-list:hover .product-card__add-to-wish-list__icon {
  color: white;
}
.product-card__add-to-wish-list:hover .product-card__link {
  background-color: rgba(255, 255, 102, 0);
}
.product-card__add-to-wish-list__icon {
  font-size: 0.9em;
  color: #484848;
  transition: all 0.3s;
  color: white;
}
.product-card__link {
  display: block;
  border-radius: 5px;
  transition: all 0.3s;
  padding-bottom: 1px;
}
.product-card__link:hover {
  background-color: rgb(243, 243, 243);
}
.product-card__link:focus {
  outline: 2px solid #6b21b4;
}
.product-card__image-div {
  border-radius: 5px;
  width: 100%;
  height: 140px;
  background-color: rgba(128, 128, 128, 0.101);
  margin-bottom: 5px;
  border: 1px solid rgb(243, 243, 243);
}
.product-card__image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-card__product-price {
  color: #511989;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 5px;
}
.product-card__product-name {
  line-height: 1.1;
  height: 1.2em;
  font-weight: 800;
  color: rgb(77, 77, 77);
  font-size: 1em;
  margin-bottom: 6px;
  text-transform: capitalize;
  overflow: hidden;
}
.product-card__product-mile-range {
  font-size: 0.8em;
  height: 0.85em;
  font-weight: 400;
  color: rgb(95, 95, 95);
  line-height: 1;
  margin-bottom: 8px;
}
.product-card__product-post-date {
  font-size: 0.8em;
  height: 0.85em;
  font-weight: 400;
  color: rgb(95, 95, 95);
  line-height: 1;
  margin-bottom: 18px;
}
.product-card__product-location-div {
  margin-bottom: 10px;
}
.product-card__product-location-icon {
  font-size: 0.8em;
  color: rgb(157, 157, 157);
}
.product-card__product-location-name {
  margin: 0;
  font-size: 0.85em;
  line-height: 0.8;
  text-transform: capitalize;
}
.product-card__product-condition-div {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-flex;
  height: 1.1em;
  background-color: lightgrey;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
}
.product-card__product-condition {
  margin: 0;
  font-weight: 500;
  color: #484848;
  font-size: 0.8em;
  line-height: 0.8;
}
.product-card__promotion-div {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 3px 8px;
  background-color: rgba(209, 91, 109, 0.8);
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.product-card__promotion {
  font-weight: 500;
  font-size: 0.8em;
  color: #ffffff;
  margin: 0;
}

.product-card-loading {
  border-radius: 5px;
  width: 100%;
  height: auto;
  position: relative;
  background-color: rgb(243, 243, 243);
}
.product-card-loading__image {
  border-radius: 5px;
  width: 100%;
  height: 140px;
  background-color: lightgrey;
  margin-bottom: 5px;
}
.product-card-loading__price {
  height: 1em;
  width: 40%;
  background-color: lightgrey;
  margin-bottom: 5px;
}
.product-card-loading__text-div {
  height: 2.4em;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-card-loading__text-div div {
  height: 1em;
  border-radius: 5px;
  background-color: lightgrey;
  width: 80%;
}
.product-card-loading__text-div div:last-child {
  width: 60%;
}
.product-card-loading__location {
  height: 0.85em;
  border-radius: 5px;
  background-color: lightgrey;
  width: 80%;
  margin-bottom: 10px;
}
.product-card-loading__condition {
  height: 1.1em;
  background-color: lightgrey;
  border-radius: 5px;
  width: 40%;
  align-items: center;
}
.product-card-loading .loading {
  position: relative;
  overflow: hidden;
}
.product-card-loading .loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: loading 1.5s infinite;
}

.pagination-div {
  height: 105px;
  justify-content: center;
  align-items: center;
}

.pagination {
  height: 45px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.pagination__prev-page-item button, .pagination__next-page-item button {
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: lightgrey;
}
.pagination h6 {
  margin: 0;
}
.pagination__buttons-div {
  margin: 0 15px;
}
.pagination__page-item button {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  margin: 0 5px;
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.pagination__page-item button:hover {
  background-color: #511989;
  color: #ffffff;
}

.ad-panel {
  border-radius: 5px;
  background-color: #E3E3E3;
  width: 295px;
  height: 295px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: sticky !important;
  top: 15px !important;
}
.ad-panel__title {
  text-align: center;
  font-weight: 700;
  color: rgb(171, 171, 171);
}

.ad-panel-horizontal {
  border-radius: 5px;
  background-color: #E3E3E3;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.ad-panel-horizontal__title {
  text-align: center;
  font-weight: 700;
  color: rgb(171, 171, 171);
}

.google-ad {
  background-color: rgba(227, 227, 227, 0.1137254902);
  width: 295px;
  height: auto;
  margin-bottom: 20px;
  overflow: hidden;
  position: sticky !important;
  top: 15px !important;
}

.google-ad-panel-horizontal {
  background-color: rgba(227, 227, 227, 0.1137254902);
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-card {
  padding: 15px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.filter-card__location-select {
  width: 100%;
  height: 2.5em;
  display: inline-block;
}
.filter-card__location-div {
  margin-bottom: 15px;
}
.filter-card__price-range-div {
  margin-bottom: 30px;
}
.filter-card__price-input {
  width: 100%;
  display: flex;
  margin: 20px 0;
}
.filter-card__price-input .filter-card__field {
  display: flex;
  width: 100%;
  height: 2em;
  align-items: center;
}
.filter-card__field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 1em;
  margin-left: 0.5em;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}
.filter-card__price-input .filter-card__separator {
  width: 100px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}
.filter-card__slider {
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 5px;
}
.filter-card__slider .filter-card__progress {
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #511989;
}
.filter-card__range-input {
  position: relative;
}
.filter-card__range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.filter-card input[type=number]::-webkit-outer-spin-button, .filter-card input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.filter-card input[type=range]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #511989;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
.filter-card input[type=range]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #511989;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
.filter-card__item-condition-div {
  margin-bottom: 15px;
}
.filter-card__category-div {
  margin-bottom: 15px;
}
.filter-card__item-condition-inner-div {
  display: flex;
  justify-content: flex-start;
}
.filter-card__reset-button {
  height: 2em;
  background-color: lightgrey;
  color: #484848;
  border-radius: 5px;
  text-align: center;
  border: none;
  outline: none;
  margin: 0;
  font-weight: 600;
  width: 80px;
}
.filter-card__filter-button {
  height: 2em;
  background-color: #511989;
  color: #ffffff;
  border-radius: 5px;
  text-align: center;
  border: none;
  outline: none;
  flex: 1;
  font-weight: 600;
  margin-right: 10px;
}

.item-image-box {
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
}
.item-image-box__top-div {
  border-radius: 5px;
  background-color: #FFFFFF;
  width: 100%;
  height: 520px;
  margin-bottom: 0;
  position: relative;
  padding: 1.5em 2em;
}
.item-image-box__bottom-div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}
.item-image-box__bottom-div__image-div {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #FFFFFF;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  transition: border 0.3s;
  overflow: hidden;
}
.item-image-box__bottom-div__image-div img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.item-image-box__bottom-div__image-div:hover {
  border: 1px solid #511989;
}
.item-image-box__bottom-div__image-div:last-child {
  margin: 0;
}

.item-image-box-loading {
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
}
.item-image-box-loading__top-div {
  border-radius: 5px;
  background-color: #FFFFFF;
  width: 100%;
  height: 520px;
  margin-bottom: 0px;
  padding: 1.5em 10em;
}
.item-image-box-loading__top-div div {
  height: 100%;
  border-radius: 5px;
  background-color: lightgrey;
}
.item-image-box-loading__bottom-div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  gap: 5px;
}
.item-image-box-loading__bottom-div div {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: lightgrey;
  gap: 15px;
}
.item-image-box-loading .loading {
  position: relative;
  overflow: hidden;
}
.item-image-box-loading .loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: loading 1.5s infinite;
}

@media (max-width: 430px) {
  .item-image-box {
    display: block;
  }
  .item-image-box__top-div {
    width: 100%;
    height: 320px;
    padding: 0.5em;
    margin-bottom: 15px;
  }
  .item-image-box__bottom-div {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex: unset;
    height: 60px;
    gap: 8px;
  }
  .item-image-box__bottom-div__image-div {
    width: 60px;
    height: 60px;
    margin-right: 0;
  }
  .item-image-box-loading {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    background-color: rgb(243, 243, 243);
    padding: 15px;
    margin-bottom: 20px;
    display: block;
    gap: unset;
  }
  .item-image-box-loading__top-div {
    border-radius: 5px;
    background-color: #FFFFFF;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
    padding: 10px;
  }
  .item-image-box-loading__top-div div {
    height: 300px;
    border-radius: 5px;
    background-color: lightgrey;
  }
  .item-image-box-loading__bottom-div {
    height: 60px;
    flex-direction: row;
    flex: unset;
    gap: unset;
  }
  .item-image-box-loading__bottom-div div {
    width: 160px;
    height: 60px;
  }
  .item-image-box-loading .loading {
    position: relative;
    overflow: hidden;
  }
  .item-image-box-loading .loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation: loading 1.5s infinite;
  }
}
.product-detail {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
}
.product-detail__title {
  color: #511989;
  margin-bottom: 2px;
  font-weight: 700;
}
.product-detail__name {
  font-size: 1.4em;
  font-weight: 700;
  color: rgb(107, 107, 107);
}
.product-detail__price, .product-detail__condition {
  font-size: 1.3em;
  font-weight: 700;
  color: rgb(107, 107, 107);
}
.product-detail__detail {
  font-weight: 500;
  line-height: 1.3;
  color: rgb(107, 107, 107);
  margin: 0;
}
.product-detail [class$=div] {
  border-bottom: 1px solid lightgrey;
  margin-top: 8px;
}
.product-detail [class$=div]:last-child {
  border-bottom: none;
}
.product-detail [class$=div]:first-child {
  margin-top: 0;
}

.product-location {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  margin-top: 20px;
  margin-bottom: 20px;
}
.product-location__title {
  color: #511989;
  margin-bottom: 2px;
  font-weight: 700;
}
.product-location__name, .product-location__distance {
  font-size: 1.2em;
  font-weight: 700;
  color: rgb(107, 107, 107);
}
.product-location__name {
  text-transform: capitalize;
}
.product-location__miles-away {
  margin-bottom: 0;
}
.product-location__distance {
  margin: 0;
}
.product-location__distance span {
  font-size: 1.3em;
  font-weight: 700;
  color: rgb(107, 107, 107);
}
.product-location__distance p {
  color: rgb(107, 107, 107);
}
.product-location [class$=div] {
  border-bottom: 1px solid lightgrey;
  margin-top: 8px;
}
.product-location [class$=div]:last-child {
  border-bottom: none;
}
.product-location [class$=div]:first-child {
  margin-top: 0;
}

.vendor-details {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  margin-top: 20px;
  margin-bottom: 20px;
}
.vendor-details__info-div {
  align-content: center;
  height: 70px;
}
.vendor-details__profile-image-div {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 15px;
  position: relative;
  border: 1px solid lightgrey;
}
.vendor-details__profile-image-div img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.vendor-details__profile-title {
  color: #511989;
  margin-bottom: 4px;
  font-weight: 700;
}
.vendor-details__profile-name {
  font-size: 1.2em;
  font-weight: 700;
  color: rgb(107, 107, 107);
  margin: 0;
}
.vendor-details__online-indicator {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  background-color: #7AFF4B;
  border: 1px solid #511989;
  left: 3px;
  top: 6px;
}
.vendor-details a:hover {
  text-decoration: underline;
}
.vendor-details__button-div {
  display: block;
  border-radius: 5px;
  background-color: #511989;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.vendor-details__button-div h5 {
  color: #ffffff;
  margin: 0;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.8px;
}

.product-detail-loading {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  margin-bottom: 20px;
}
.product-detail-loading__vendor-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: lightgrey;
  margin-right: 15px;
}
.product-detail-loading__vendor-info-div {
  flex: 1;
}
.product-detail-loading__inner-div {
  margin-bottom: 20px;
}
.product-detail-loading__inner-div--alt {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-detail-loading__Loader-top {
  border-radius: 2.5px;
  height: 18px;
  width: 40%;
  background-color: lightgrey;
  margin-bottom: 8px;
}
.product-detail-loading__Loader-bottom {
  border-radius: 2.5px;
  height: 18px;
  width: 100%;
  background-color: lightgrey;
  margin-bottom: 8px;
}
.product-detail-loading__Loader-bottom--alt {
  width: 60%;
}
.product-detail-loading .loading {
  position: relative;
  overflow: hidden;
}
.product-detail-loading .loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: loading 1.5s infinite;
}

.views-box {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.views-box__icon-div {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.views-box__icon-div i {
  font-size: 1.1em;
}
.views-box h5 {
  margin: 0;
  font-weight: 700;
}

.buttons-box-loading {
  width: 100%;
  height: 2.5em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
}
.buttons-box-loading__button {
  height: 100%;
  flex: 1;
  border-radius: 5px;
  background-color: lightgrey;
  margin-right: 12px;
}
.buttons-box-loading__button:last-child {
  margin-right: 0;
}
.buttons-box-loading__button.loading {
  position: relative;
  overflow: hidden;
}
.buttons-box-loading__button.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
.chat-wall {
  height: auto;
  border: 1px solid lightgrey;
  border-radius: 5px;
  overflow: hidden;
}
.chat-wall__header {
  border-bottom: 1px solid lightgrey;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  display: flex;
  align-items: center;
}
.chat-wall__header-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.chat-wall__header-image-div {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgb(156, 156, 156);
  margin-right: 15px;
}
.chat-wall__header-user-info h4, .chat-wall__header-user-info p {
  margin: 0;
}
.chat-wall__header-user-info a:hover {
  text-decoration: underline;
}
.chat-wall__header h3 {
  font-weight: 700;
  color: #511989;
}
.chat-wall__main {
  height: 60vh;
  padding: 15px;
  overflow-y: scroll;
}
.chat-wall__footer {
  border-top: 1px solid lightgrey;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: rgb(243, 243, 243);
  padding: 15px;
  justify-content: center;
}
.chat-wall__input-div {
  width: 80%;
  height: 2.5em;
  background-color: #FFFFFF;
  border-radius: 5px;
  justify-content: space-between;
}
.chat-wall__input {
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #511989;
  padding-left: 1em;
  padding-right: 1em;
  width: 80%;
  height: 100%;
}
.chat-wall__input.disabled {
  border: 1px solid rgb(179, 180, 181);
}
.chat-wall__input:focus {
  outline: none;
}
.chat-wall__button {
  flex: 1;
  height: 100%;
  outline: none;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  background-color: #511989;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}
.chat-wall__button.disabled {
  cursor: not-allowed;
  background-color: rgb(179, 180, 181);
}

.chat-card {
  min-height: 3em;
  align-items: flex-start;
  margin-bottom: 15px;
}
.chat-card__image-div {
  height: 3.5em;
  width: 3.5em;
  border-radius: 50%;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  margin-right: 15px;
}
.chat-card__image {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chat-card__message-div {
  min-height: 3em;
  max-width: 380px;
  display: inline-flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid #511989;
  background-color: #511989;
  border-radius: 30px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
}
.chat-card__message {
  margin: 0;
  line-height: 1.3;
  color: #ffffff;
}
.chat-card__product-link-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.chat-card__product-link {
  font-size: 0.8em;
  padding: 3px 18px;
  color: #ffffff;
  margin-top: 8px;
  border-radius: 4px;
  border-bottom-left-radius: 10px;
  background-color: #842fd9;
  text-align: left;
  display: inline-block;
  cursor: pointer;
}
.chat-card--alt {
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-bottom: 15px;
}
.chat-card--alt .chat-card__image-div {
  margin-left: 15px;
  margin-right: 0;
  align-items: flex-end;
}
.chat-card--alt .chat-card__message-div {
  border: 1px solid darkgrey;
  background-color: #E9E9E9;
  border-radius: 30px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
}
.chat-card--alt .chat-card__message {
  margin: 0;
  line-height: 1.3;
  color: #484848;
}
.chat-card--alt .chat-card__product-link-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.chat-card--alt .chat-card__product-link {
  text-align: right;
  padding: 3px 18px;
  color: #ffffff;
  border-radius: 4px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 4px;
}

@media (max-width: 430px) {
  .chat-wall__main {
    height: 60vh;
    padding: 0.8em 10px;
  }
  .chat-wall__input-div {
    width: 100%;
  }
  .chat-wall__input {
    width: 80%;
  }
  .chat-wall-user-info a:hover {
    text-decoration: none;
  }
  .chat-card {
    margin-bottom: 0.8em;
  }
  .chat-card__image-div {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }
  .chat-card__message-div {
    min-height: 40px;
    max-width: 70vw;
    display: inline-block;
    padding: 10px;
    border: 1px solid #511989;
    background-color: rgba(255, 255, 102, 0);
    border: 1px solid #511989;
    background-color: #511989;
    border-radius: 18px;
    border-top-left-radius: 6px;
    box-sizing: border-box;
  }
  .chat-card__message {
    margin: 0;
    line-height: 1.3;
  }
  .chat-card--alt {
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .chat-card--alt .chat-card__image-div {
    margin-left: 15px;
    margin-right: 0;
  }
  .chat-card--alt .chat-card__message-div {
    border: 1px solid darkgrey;
    background-color: #E9E9E9;
    border-radius: 18px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
  }
}
.chat-list {
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  margin-bottom: 20px;
}
.chat-list__header {
  border-bottom: 1px solid lightgrey;
  padding: 15px;
  display: flex;
  align-items: center;
}
.chat-list__header h3 {
  font-weight: 700;
  color: #511989;
  margin: 0;
  margin-right: 30px;
}
.chat-list__card-info-div {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
}
.chat-list__card-info-div * {
  margin: 0;
}
.chat-list__card-info-div h6 {
  text-transform: capitalize;
  font-weight: 700;
}
.chat-list__new-message {
  display: flex;
  align-items: center;
}
.chat-list__new-message__icon {
  height: 20px;
  min-width: 20px;
  padding: 3px;
  border-radius: 5px;
  background-color: #511989;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-list__new-message__icon-value {
  margin: 0;
  font-size: 0.65em;
  color: white;
  line-height: 1;
}
.chat-list__header-input {
  height: 1.8em;
  border-radius: 3px;
  border: 1px solid lightgrey;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.chat-list__header-input:focus {
  outline: 2px solid lightgrey;
}
.chat-list__card-div {
  padding: 8px;
}
.chat-list__card {
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 8px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: background-color 0.3s;
}
.chat-list__card:hover {
  background-color: #f3f3f3;
}
.chat-list__card:last-child {
  margin-bottom: 0px;
}
.chat-list__card-inner-div {
  display: flex;
  align-items: center;
}
.chat-list__card-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chat-list__card-image-div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(156, 156, 156);
  margin-right: 15px;
}
.chat-list__card-new-message {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #7AFF4B;
}
.chat-list__card h5 {
  font-weight: 700;
}
.chat-list__list-empty {
  text-align: center;
  color: lightgrey;
}
.chat-list__main-section {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.chat-list__mobile-header {
  border-bottom: 1px solid lightgrey;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(243, 243, 243);
}
.chat-list__mobile-header h4 {
  margin: 0;
}
.chat-list__mobile-header a.h6 {
  margin: 0;
}
.chat-list__mobile-header__user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.chat-list__mobile-header__user-info h6 {
  margin: 0;
}
.chat-list__mobile-header__image-div {
  height: 32px;
  width: 32px;
  border: 1px solid lightgrey;
  border-radius: 50%;
  overflow: hidden;
}
.chat-list__mobile-header__image {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.chat-list__mobile-header__button {
  padding: 0.3em;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat-list__mobile-header__button-icon {
  font-size: 0.85em;
}
.chat-list__mobile-header__button h6 {
  margin: 0;
}
.chat-list__header-input {
  width: 65%;
  height: 2em;
  border-radius: 5px;
  outline: none;
}
.chat-list__header-input:focus {
  outline: none;
}
.chat-list__display-chat {
  height: calc(100vh - 140px);
  padding: 15px;
  overflow: scroll;
  width: 100%;
}
.chat-list__mobile-card {
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 8px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: background-color 0.3s;
}
.chat-list__mobile-card:hover {
  background-color: #f3f3f3;
}
.chat-list__mobile-card:last-child {
  margin-bottom: 0px;
}
.chat-list__mobile-card-inner-div {
  display: flex;
  align-items: center;
}
.chat-list__mobile-card-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chat-list__mobile-card-image-div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(156, 156, 156);
  margin-right: 15px;
}
.chat-list__mobile-card-new-message {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #7AFF4B;
}
.chat-list__mobile-card h5 {
  font-weight: 700;
}
.chat-list__mobile-message-list {
  padding-bottom: 0;
  height: calc(90vh - 140px);
  width: 100%;
}
.chat-list__mobile-message-list__inner-div {
  padding: 15px;
  padding-bottom: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.chat-list__mobile-footer {
  height: 10vh;
  background-color: rgb(243, 243, 243);
  border-top: 1px solid lightgrey;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.chat-list__mobile-footer__input {
  height: 2em;
  width: 60vw;
  border-radius: 50px;
  border: 1px solid lightgrey;
  outline: none;
  padding: 0 15px;
}
.chat-list__mobile-footer__button {
  height: 2em;
  width: 20vw;
  border: none;
  outline: none;
  border-radius: 50px;
  color: white;
  background-color: #511989;
}

@media (max-width: 430px) {
  .chat-list__card {
    width: 100% !important;
  }
  .chat-list__card-inner-div {
    display: flex;
    align-items: center;
  }
  .chat-list__card-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .chat-list__card-image-div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(156, 156, 156);
    margin-right: 15px;
  }
  .chat-list__card-new-message {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #7AFF4B;
  }
  .chat-list__card h5 {
    font-weight: 700;
  }
}
.vendor-profile {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  width: 100%;
  height: auto;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.vendor-profile__image-div {
  border: 1px solid lightgrey;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  margin-bottom: 8px;
}
.vendor-profile__image-div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.vendor-profile__online-indicator {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #7AFF4B;
  left: 8px;
  top: 8px;
  border: 1px solid lightgrey;
}
.vendor-profile__user-name {
  text-align: center;
  font-weight: 700;
}
.vendor-profile__user-last-seen {
  text-align: center;
  margin: 0;
  color: darkgrey;
}

.search-bar {
  margin-bottom: 15px;
  justify-content: center;
}
.search-bar__input-div {
  width: 65%;
  height: 2.5em;
  border-radius: 5px;
}
.search-bar__input {
  border: none;
  height: 100%;
  width: 80%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #511989;
  padding-left: 1em;
  padding-right: 1em;
}
.search-bar__input:focus {
  outline: 2px solid #842fd9;
}
.search-bar__button {
  border: none;
  outline: none;
  height: 100%;
  flex: 1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  background-color: #511989;
  font-weight: 700;
  letter-spacing: 1px;
}

@media (max-width: 430px) {
  .search-bar__input-div {
    width: 100%;
  }
}
.search-info-box {
  width: 100%;
  height: auto;
  padding: 20px 15px;
  border-radius: 5px;
  border: 1px solid #511989;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  margin-bottom: 15px;
}
.search-info-box__capitalize {
  text-transform: capitalize;
}
.search-info-box h4 {
  color: #511989;
  font-size: 1.3em;
  margin: 0;
}

@media (max-width: 430px) {
  .search-info-box {
    width: 100%;
    height: auto;
    padding: 20px 10px;
  }
  .search-info-box h4 {
    font-size: 1.05em;
    text-align: center;
  }
}
.content-info-box {
  width: 100;
  height: auto;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  margin-bottom: 20px;
}
.content-info-box__text {
  color: #511989;
  margin: 0;
  font-weight: 600;
}
.content-info-box__sub-text {
  margin-top: 5px;
  color: grey;
}

.add-post-card {
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
.add-post-card:hover {
  background-color: lightgrey;
}
.add-post-card__icon-div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-post-card__icon {
  padding: 1em;
  border-radius: 50px;
  border: 2px solid #511989;
  color: #511989;
  margin-bottom: 0.5em;
}
.add-post-card__text {
  margin: 0;
  color: #511989;
  text-align: center;
}

form input:focus-visible, form textarea:focus-visible {
  outline-color: #511989 !important;
}

.p-multiselect-label {
  height: 2.5em;
  display: flex;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  overflow: hidden;
  text-wrap: wrap;
}

.p-multiselect-token {
  margin-right: 10px;
  padding: 4px 10px;
  display: flex;
  -moz-column-gap: 4px;
       column-gap: 4px;
}

.new-item-form__posted-items-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.new-item-form__posted-items-count__upgrade-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #511989;
  color: #ffffff;
  font-weight: 600;
}
.new-item-form__posted-items-count__progress-bar {
  height: 100%;
  border-radius: 50px;
  background-color: #511989;
}
.new-item-form__posted-items-count__progress-bar-div {
  border: 2px solid #511989;
  height: 0.7em;
  width: 700px;
  border-radius: 50px;
  margin-bottom: 15px;
}
.new-item-form__input-div {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  transition: height 0.3s;
}
.new-item-form__input-div option, .new-item-form__input-div select {
  text-transform: capitalize;
}
.new-item-form__input-div--none {
  display: none;
}
.new-item-form__input-div__input-inner-div--alt {
  display: flex;
  align-items: center;
  gap: 20px;
}
.new-item-form__input-div__input-inner-div--alt__sub-div {
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
}
.new-item-form__input-div__input-inner-div--alt__sub-div--alt {
  display: flex;
  align-items: center;
}
.new-item-form__input-div__input-inner-div--alt__sub-div input {
  width: 100%;
}
.new-item-form__label {
  color: #511989;
  font-weight: 700;
  margin-bottom: 5px;
}
.new-item-form__label--alt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new-item-form__label--alt h6 {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 0;
}
.new-item-form__label span {
  font-weight: 500;
  margin-left: 10px;
  color: #511989;
}
.new-item-form__label span.new-item-form__span--alt {
  font-weight: 500;
  margin-bottom: 0;
  color: rgb(223, 26, 26);
}
.new-item-form__file-input-outer-div {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.new-item-form__file-input-div {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  position: relative;
}
.new-item-form__file-input-div:first-child label div {
  padding-top: 15px;
}
.new-item-form__file-input-div label {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: relative;
}
.new-item-form__file-input-div label div {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  border: 3px dashed lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}
.new-item-form__file-input-div label i, .new-item-form__file-input-div label h6 {
  font-size: 0.9em;
  color: #511989;
}
.new-item-form__file-input-div label h6 span {
  display: block;
  color: #511989;
  margin-top: 1px;
  font-size: 0.75em;
}
.new-item-form__preview-div {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  position: relative;
}
.new-item-form__preview-div img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 5px;
}
.new-item-form__preview-div button {
  position: absolute;
  width: 2em;
  height: 2em;
  border: none;
  top: -0.4em;
  right: -0.4em;
  background-color: rgba(176, 12, 12, 0.955);
  color: #fff;
  font-size: 0.6em;
  border-radius: 50%;
  z-index: 5;
}
.new-item-form__upload-image-input {
  display: none;
}
.new-item-form__input {
  height: 2.5em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding-left: 1em;
  padding-right: 1em;
}
.new-item-form__radio-outer-div {
  justify-content: flex-start;
  flex-direction: column;
}
.new-item-form__radio-outer-div--promote {
  flex-direction: row;
}
.new-item-form__radio-inner-div {
  display: flex;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 8px;
  height: 2em;
}
.new-item-form__radio-input {
  margin-right: 8px;
  width: 15px;
  height: 15px;
}
.new-item-form__textarea-div {
  display: flex;
  flex-direction: column;
}
.new-item-form__textarea {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 0.5em;
  width: 100%;
  height: 120px;
}
.new-item-form__radio-label {
  font-weight: 600;
  color: #511989;
}
.new-item-form__upload-image-div {
  flex-direction: column;
}
.new-item-form__promote-item-div {
  flex-direction: column;
}
.new-item-form__post-item-button {
  border: none;
  padding: 0.5em 5em;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  background-color: #511989;
  text-transform: capitalize;
}
.new-item-form__post-item-button:active {
  padding: 0.48em 4.9em;
}
.new-item-form__search-location-button {
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  background-color: #511989;
}
.new-item-form__radio-inner-button-div {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.new-item-form__radio-inner-button-div.alt {
  padding: 10px 0;
}
.new-item-form__radio-inner-button-div__button-icon {
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  top: -7.5px;
  left: -7.5px;
  background-color: #9e5ae1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-item-form__radio-inner-button-div__button-icon i {
  color: white;
  font-size: 0.6em;
}
.new-item-form__radio-inner-button-div__radio-button {
  padding: 10px 1em;
  background-color: transparent;
  border: 2px solid lightgrey;
  border-radius: 8px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  transition: all 0.3s;
  position: relative;
}
.new-item-form__radio-inner-button-div__radio-button--item-type {
  width: 120px;
  height: 45px;
  background-color: transparent;
  border: 2px solid lightgrey;
  border-radius: 8px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  position: relative;
}
.new-item-form__radio-inner-button-div__radio-button--item-type .active-spot {
  display: none;
}
.new-item-form__radio-inner-button-div__radio-button--item-type:hover {
  border: 2px solid #511989 !important;
}
.new-item-form__radio-inner-button-div__radio-button--item-type:hover h6 {
  color: #511989;
}
.new-item-form__radio-inner-button-div__radio-button--item-type.active {
  border: 2px solid #511989 !important;
}
.new-item-form__radio-inner-button-div__radio-button--item-type.active .active-spot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  background-color: #511989;
  top: 5px;
  left: 5px;
}
.new-item-form__radio-inner-button-div__radio-button--item-type.active h6 {
  color: #511989;
}
.new-item-form__radio-inner-button-div__radio-button--item-type h6 {
  margin-bottom: 0;
  font-weight: 700;
}
.new-item-form__radio-inner-button-div__radio-button.active {
  border: 2px solid #9e5ae1;
  background-color: rgb(245, 245, 245);
}
.new-item-form__radio-inner-button-div__radio-button h6 {
  color: #511989;
}
.new-item-form__radio-inner-button-div__radio-button h4 {
  color: rgb(117, 117, 117);
}
.new-item-form__radio-inner-button-div__radio-button * {
  margin: 0;
}
.new-item-form__location-indicator {
  display: flex;
  align-items: center;
  gap: 7px;
  color: rgba(59, 59, 59, 0.6);
}
.new-item-form__location-indicator h6 {
  margin-bottom: 0;
}
.new-item-form__location-indicator i {
  color: rgba(59, 59, 59, 0.6);
  font-size: 0.9em;
}

@media (max-width: 430px) {
  .new-item-form {
    width: 100%;
  }
  .new-item-form__input-div__input-inner-div--alt {
    flex-direction: column;
    gap: 3px;
  }
  .new-item-form__input-div__input-inner-div--alt__sub-div {
    width: 100%;
  }
  .new-item-form__posted-items-count__upgrade-button {
    padding: 8px 15px;
  }
  .new-item-form__posted-items-count__progress-bar-div {
    width: 90%;
  }
  .new-item-form__posted-items-count__text {
    font-size: 1.2em;
    text-align: center;
  }
  .new-item-form__radio-inner-button-div {
    display: flex;
    padding: 0;
    gap: 12px;
  }
  .new-item-form__radio-inner-button-div__button-icon {
    width: 15px;
    height: 15px;
    top: 5px;
    left: 5px;
  }
  .new-item-form__radio-inner-button-div__radio-button {
    flex: 1;
    padding: 25px 12px 18px;
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 8px;
    display: flex;
    gap: 2px;
    flex-direction: column;
    transition: all 0.3s;
    position: relative;
  }
  .new-item-form__radio-inner-button-div__radio-button--item-type {
    flex: 1;
    padding: 25px 12px 18px;
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 8px;
    display: flex;
    gap: 2px;
    flex-direction: column;
    transition: all 0.3s;
    position: relative;
  }
  .new-item-form__radio-inner-button-div__radio-button.active {
    border: 2px solid #9e5ae1;
    background-color: rgb(245, 245, 245);
  }
  .new-item-form__radio-inner-button-div__radio-button h6 {
    color: #511989;
    text-align: left;
    font-size: 0.9em;
  }
  .new-item-form__radio-inner-button-div__radio-button h4 {
    color: rgb(117, 117, 117);
    text-align: left;
    font-size: 1.2em;
  }
  .new-item-form__radio-inner-button-div__radio-button * {
    margin: 0;
  }
  .new-item-form__radio-inner-div--promote {
    flex-direction: column;
    margin: 8px 0 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .new-item-form__file-input-outer-div {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
  }
  .new-item-form__file-input-div {
    width: 28vw;
    height: 28vw;
    border-radius: 5px;
    position: relative;
  }
  .new-item-form__file-input-div:first-child label div {
    padding-top: 10px;
  }
  .new-item-form__file-input-div label {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .new-item-form__file-input-div label div {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgb(243, 243, 243);
    border: 3px dashed lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
  }
  .new-item-form__file-input-div label i, .new-item-form__file-input-div label h6 {
    font-size: 0.8em;
    color: #511989;
  }
  .new-item-form__file-input-div label h6 span {
    display: block;
    color: #511989;
    margin-top: 1px;
    font-size: 0.75em;
  }
  .new-item-form__post-item-button {
    width: 100%;
    height: auto;
    padding: 0.5em 1em;
    font-weight: 600;
    color: white;
    margin: 10px 0 30px;
    background-color: #511989;
  }
}
.modal {
  background-color: rgba(255, 255, 255, 0.5019607843);
}
.modal.hide {
  display: none !important;
}

.modal-body {
  min-height: 300px;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #511989;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.062745098);
}

.modal__custom-content-left {
  min-height: 300px;
  flex: 4;
  background-color: #511989;
  padding: 4em 1.5em;
}
.modal__custom-content-left h2 {
  font-weight: 700;
  color: #ffffff;
}
.modal__custom-content-left .line {
  height: 3px;
  width: 100px;
  background-color: rgba(255, 255, 0, 0);
  margin-bottom: 10px;
}
.modal__custom-content-left p {
  line-height: 1.4;
  color: #ffffff;
  font-weight: 600;
  width: 80%;
}
.modal__custom-content-right {
  min-height: 300px;
  flex: 8;
  background-color: white;
  padding: 3em 2.5em;
  position: relative;
}
.modal__custom-content-right.reset-password form {
  padding-top: 3em;
}
.modal__custom-content-right .button-close-custom {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal__custom-content-right form input {
  width: 100%;
  height: 2.5em;
  border-radius: 5px;
  border: 1px solid #511989;
  margin-bottom: 10px;
  padding: 0 1em;
}
.modal__custom-content-right form input:focus {
  outline: 2px solid rgba(255, 255, 0, 0);
}
.modal__custom-content-right .check-box-div {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.modal__custom-content-right .check-box-div input[type=checkbox] {
  width: 1em;
  height: 1em;
  margin: 0;
  margin-right: 12px;
  border: 1px solid #511989;
}
.modal__custom-content-right .check-box-div h6 {
  margin: 0;
  font-size: 0.9em;
}
.modal__custom-content-right .register-button {
  border: none;
  width: 100%;
  height: 2.5em;
  border-radius: 5px;
  background-color: #511989;
  color: #ffffff;
  display: block;
  font-weight: 700;
  text-align: center;
  line-height: 2.5;
  letter-spacing: 0.8px;
  margin-bottom: 3px;
}
.modal__custom-content-right .register-button__error-message {
  font-size: 0.95em;
  color: red;
  text-align: center;
}
.modal__custom-content-right .register-button:hover {
  background-color: #5e1d9f;
}
.modal__custom-content-right .switch-to-signin {
  font-size: 0.95em;
  text-align: center;
  margin: 0;
}
.modal__custom-content-right .switch-to-signin__button {
  outline: none;
  border: none;
  color: #511989;
  cursor: pointer;
  background-color: transparent;
  font-weight: 600;
}
.modal__custom-content-right .switch-to-signin__button:hover {
  text-decoration: underline;
}
.modal__custom-content-right__or-separator {
  text-align: center;
  margin: 0;
}
.modal__custom-content-right__google-signin-button, .modal__custom-content-right__facebook-signin-button {
  border: none;
  outline: none;
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 2.5em;
  background-color: orangered;
  font-weight: 600;
  color: white;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.modal__custom-content-right__google-signin-button i, .modal__custom-content-right__facebook-signin-button i {
  color: white;
  margin-right: 10px;
}
.modal__custom-content-right__facebook-signin-button {
  background-color: rgb(65, 84, 181);
}
.modal__custom-content-right__forgot-password {
  border: none;
  background-color: transparent;
  font-weight: 600;
  color: #511989;
}
.modal__custom-content__terms-body {
  height: 50vh;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
  overflow-y: scroll;
}
.modal__custom-content__footer-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body__left-inner-div {
  height: 100%;
  width: 100%;
  background-color: #511989;
}

.modal-body__left, .modal-body__right {
  padding: 0;
}

.button-close-custom {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

@media (max-width: 430px) {
  .modal-dialog {
    margin-top: 2em;
  }
  .modal-body {
    min-height: 300px;
  }
  .modal__custom-content {
    display: flex;
    flex-direction: column;
  }
  .modal__custom-content-left {
    min-height: 10px;
    padding: 1em;
  }
  .modal__custom-content-left h2 {
    text-align: center;
  }
  .modal__custom-content-left .line {
    margin: 0 auto 10px;
  }
  .modal__custom-content-left p {
    text-align: center;
    margin: 0 auto;
  }
  .modal__custom-content-right {
    min-height: 100px;
    flex: 1;
    padding: 1.5em 1em;
    position: relative;
  }
  .modal__custom-content-right.reset-password form {
    padding: 1.5em 1em;
  }
  .modal__custom-content-right .button-close-custom {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modal__custom-content-right form {
    width: 100%;
    padding: none;
  }
  .modal__custom-content-right form input {
    width: 100%;
    height: 2.5em;
    border-radius: 5px;
    border: 1px solid #511989;
    margin-bottom: 15px;
    padding: 0 1em;
  }
  .modal__custom-content-right form input:focus {
    outline: 2px solid rgba(255, 255, 0, 0);
  }
  .modal__custom-content-right .check-box-div {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .modal__custom-content-right .check-box-div input[type=checkbox] {
    width: 1em;
    height: 1em;
    margin: 0;
    margin-right: 12px;
    border: 1px solid #511989;
  }
  .modal__custom-content-right .check-box-div h6 {
    margin: 0;
    font-size: 0.9em;
  }
  .modal__custom-content-right .register-button {
    height: 2.5em;
    border-radius: 5px;
    background-color: #511989;
    color: #ffffff;
    display: block;
    font-weight: 700;
    text-align: center;
    line-height: 2.5;
    letter-spacing: 0.8px;
    margin-bottom: 15px;
  }
  .modal__custom-content-right .register-button:hover {
    background-color: #5e1d9f;
  }
  .modal__custom-content-right .switch-to-signin {
    font-size: 0.95em;
    text-align: center;
    margin: 0;
  }
  .modal__custom-content-right .switch-to-signin__button {
    outline: none;
    border: none;
    color: #511989;
    cursor: pointer;
    background-color: transparent;
    font-weight: 600;
  }
  .modal__custom-content-right .switch-to-signin__button:hover {
    text-decoration: underline;
  }
  .modal__custom-content__terms-body {
    height: 65vh;
  }
  .button-close-custom {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.466);
  }
  .modal-body__left-inner-div {
    height: 100%;
    width: 100%;
    background-color: #511989;
  }
  .modal-body__left, .modal-body__right {
    padding: 0;
  }
}
.terms-and-conditions {
  text-transform: capitalize;
  font-weight: 600;
  color: #511989;
}
.terms-and-conditions:hover {
  text-decoration: underline;
}

.user-detail-box {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  margin-bottom: 20px;
}
.user-detail-box__image-div {
  border: 1px solid lightgrey;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  margin: 0 auto 8px;
  justify-content: center;
}
.user-detail-box__image-div img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.user-detail-box__online-indicator {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #7AFF4B;
  left: 8px;
  top: 8px;
  border: 1px solid lightgrey;
}
.user-detail-box__user-name {
  text-align: center;
  font-weight: 700;
}
.user-detail-box__user-info-div {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgrey;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.user-detail-box__user-info-div--bio {
  display: block;
  justify-content: unset;
}
.user-detail-box__user-info-div--bio h6 {
  margin-bottom: 5px;
}
.user-detail-box__user-info-title, .user-detail-box__user-info-value {
  font-weight: 700;
  color: #484848;
  margin: 0;
}
.user-detail-box__user-rating-div {
  display: flex;
  gap: 3px;
}
.user-detail-box__user-rating-div i {
  color: rgba(0, 0, 0, 0.163);
  position: relative;
  overflow: hidden;
  font-size: 0.9em;
}
.user-detail-box__user-rating-div i.active {
  color: #511989;
}
.user-detail-box__user-info-value--bio {
  font-weight: 400;
  line-height: 1.2;
  font-size: 0.9em;
}
.user-detail-box__start-chat-div {
  border-radius: 5px;
  background-color: #511989;
  border: none;
  outline: none;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}
.user-detail-box__start-chat-div h5 {
  color: #ffffff;
  margin: 0;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.8px;
}
.user-detail-box__edit-user-button {
  width: 100%;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  background-color: #511989;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.user-detail-box__edit-user-button i {
  color: #ffffff;
  margin-right: 10px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.8px;
}
.user-detail-box__delete-user-button {
  width: 100%;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  background-color: rgb(223, 64, 64);
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.user-detail-box__delete-user-button i {
  color: #ffffff;
  margin-right: 10px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.8px;
}
.user-detail-box__report-user-button {
  align-items: center;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 10px;
  transition: background-color 0.3s;
}
.user-detail-box__report-user-button:hover {
  background-color: rgba(116, 116, 116, 0.167);
}
.user-detail-box__report-user-icon {
  height: 1em;
  width: 1em;
  color: grey;
  margin-right: 10px;
}
.user-detail-box__report-user {
  margin: 0;
  line-height: 1;
  font-size: 0.9em;
}
.user-detail-box__buttons-box__discard-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgb(223, 64, 64);
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.user-detail-box__buttons-box__discard-button.button-disabled {
  cursor: not-allowed;
  background-color: #f5c3c3;
}
.user-detail-box__delete-account-modal__input {
  height: 2.3em;
  width: 80%;
  border-radius: 4px;
  padding: 0 10px;
  margin-top: 15px;
}
.user-detail-box__delete-account-modal__input-or {
  margin: 0;
}
.user-detail-box__delete-account-modal__google-delete {
  height: 2.3em;
  width: 80%;
  border-radius: 4px;
  padding: 0 10px;
  border: none;
  outline: none;
  background-color: orangered;
  color: #ffffff;
}
.user-detail-box__rate-user-button {
  display: flex;
  width: 100%;
  height: auto;
  border: 1px solid rgba(116, 116, 116, 0.167);
  border-radius: 4px;
  align-items: center;
  padding: 5px 8px;
  gap: 10px;
  margin-bottom: 20px;
}
.user-detail-box__rate-user-button * {
  margin: 0;
}
.user-detail-box__rate-user-button:hover {
  background-color: rgba(67, 67, 67, 0.1);
}

.edit-profile__form-image-div {
  height: 100%;
  width: 150px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid lightgrey;
}
.edit-profile__form-image-div img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.edit-profile__edit-image-buttons-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
}
.edit-profile__edit-image-div {
  height: 150px;
  display: flex;
  gap: 15px;
}
.edit-profile__input {
  display: none;
}
.edit-profile__update-image-button, .edit-profile__remove-image-button {
  height: 2.2em;
  width: 170px;
  border-radius: 4px;
  background-color: lightgrey;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.edit-profile__update-image-button:hover, .edit-profile__remove-image-button:hover {
  background-color: #c6c6c6;
}
.edit-profile__update-image-button *, .edit-profile__remove-image-button * {
  margin: 0;
}
.edit-profile__remove-image-button {
  background-color: rgb(223, 64, 64);
  color: #fff;
}
.edit-profile__remove-image-button:hover {
  background-color: #db2a2a;
}
.edit-profile__remove-image-button i {
  color: #fff;
}

@media (max-width: 430px) {
  .user-detail-box {
    background-color: rgba(255, 255, 102, 0);
    padding: 0.5em;
  }
  .user-detail-box__user-name {
    font-size: 1.6em;
  }
  .user-detail-box__user-rating-div {
    display: flex;
    gap: 1px;
    margin-bottom: 10px;
  }
  .user-detail-box__user-rating-div i {
    color: rgba(255, 255, 255, 0.3607843137);
    font-size: 12px;
  }
  .user-detail-box__user-rating-div i.active {
    color: yellow;
  }
  .user-detail-box__user-info-outer-div {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 10px;
  }
  .user-detail-box__user-info-outer-div .user-detail-box__user-info-div {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    height: 80px;
    border-radius: 5px;
    padding: 0.5em 0.3em;
    background-color: #511989;
  }
  .user-detail-box__user-info-outer-div .user-detail-box__user-info-div .user-detail-box__user-info-title {
    color: #ffffff;
  }
  .user-detail-box__user-info-outer-div .user-detail-box__user-info-div .user-detail-box__user-info-value {
    color: #ffffff;
    font-size: 1.8em;
  }
  .user-detail-box__user-info-title, .user-detail-box__user-info-value {
    font-weight: 700;
    color: #511989;
    margin: 0;
  }
  .user-detail-box__user-info-div--bio {
    border: 1px solid #511989;
    border-radius: 5px;
    background-color: rgba(255, 255, 102, 0);
    padding: 0.5em;
    margin: 15px 0;
  }
  .user-detail-box__user-info-value--bio {
    font-weight: 400;
    line-height: 1.4;
    font-size: 0.9em;
  }
  .user-detail-box__start-chat-div {
    display: block;
    border-radius: 5px;
    background-color: #511989;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 8px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .user-detail-box__start-chat-div h5 {
    color: #ffffff;
    margin: 0;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.8px;
  }
  .user-detail-box__rate-user-button {
    padding: 10px 8px;
    gap: 10px;
    margin-bottom: 20px;
  }
  .user-detail-box__rate-user-button * {
    margin: 0;
  }
  .user-detail-box__delete-account-modal__google-delete {
    height: 2.3em;
    width: 100%;
    font-size: 0.9em;
    padding: 0 2px;
    background-color: orangered;
    color: #ffffff;
  }
  .user-detail-box__delete-account-modal__heading-text {
    text-align: center;
    width: 100%;
  }
  .user-detail-box__delete-account-modal__input {
    width: 100%;
  }
  .user-detail-box__report-user-icon {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-detail-box__report-user {
    margin: 0;
    line-height: 1;
    font-size: 0.9em;
  }
  .edit-profile__form {
    width: 100%;
  }
}
.wish-list__item {
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
}
.wish-list__item:last-child {
  margin-bottom: 0;
}
.wish-list__item a {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(243, 243, 243);
  border-radius: 5px;
  padding: 10px;
  padding-right: 40px;
  transition: background-color 0.3s;
}
.wish-list__item a:hover {
  background-color: rgb(243, 243, 243);
}
.wish-list__item:hover {
  background-color: rgb(243, 243, 243);
}
.wish-list__image-div {
  width: 220px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(243, 243, 243);
  background-color: lightgrey;
  margin-right: 10px;
}
.wish-list__image-div img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-fit: cover;
     object-fit: cover;
}
.wish-list__info-div {
  margin-right: 10px;
  flex: 1;
}
.wish-list__button-div {
  height: 25px;
  width: 25px;
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
}
.wish-list__button-div button {
  height: 100%;
  width: 100%;
  margin-bottom: 8px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  background-color: rgb(195, 80, 80);
}
.wish-list__empty-div {
  width: 50%;
  min-height: 250px;
  border-radius: 5px;
  margin: 50px auto 30px;
  background-color: rgb(232, 232, 232);
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
}
.wish-list__empty-div * {
  color: rgb(158, 158, 158);
  text-align: center;
}
.wish-list__empty-div i {
  font-size: 5em;
}
.wish-list__empty-div h6 {
  margin: 0;
}
.wish-list__empty-div a {
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: rgb(219, 219, 219);
  transition: background-color 0.3s;
}
.wish-list__empty-div a:hover {
  background-color: rgb(203, 203, 203);
}
.wish-list__remove-all-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wish-list__remove-all-button {
  padding: 0.25em 1em;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: rgb(156, 156, 156);
  color: #fff;
  margin-top: 15px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

@media (max-width: 430px) {
  .wish-list__empty-div {
    width: 90%;
    min-height: 250px;
    margin: 30px auto;
    padding: 1.5em;
  }
  .wish-list__empty-div i {
    font-size: 5em;
    margin-bottom: 10px;
  }
  .wish-list__item {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
  }
  .wish-list__item:last-child {
    margin-bottom: 0;
  }
  .wish-list__item a {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(243, 243, 243);
    border-radius: 5px;
    padding: 5px;
    padding-right: 20px;
    transition: background-color 0.3s;
  }
  .wish-list__item a:hover {
    background-color: rgb(243, 243, 243);
  }
  .wish-list__item:hover {
    background-color: rgb(243, 243, 243);
  }
  .wish-list__image-div {
    width: 120px;
    height: 100%;
    border-radius: 5px;
    border: 1px solid rgb(243, 243, 243);
    background-color: lightgrey;
    margin-right: 10px;
  }
  .wish-list__image-div img {
    height: 100%;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .wish-list__info-div {
    margin-right: 10px;
    flex: 1;
  }
  .wish-list__button-div {
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 3;
    top: 5px;
    right: 5px;
  }
  .wish-list__button-div button {
    height: 100%;
    width: 100%;
    margin-bottom: 8px;
    outline: none;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    background-color: rgb(195, 80, 80);
    font-size: 0.8em;
  }
  .wish-list__empty-div {
    width: 90%;
    min-height: 250px;
    border-radius: 5px;
    margin: 30px auto;
    background-color: rgb(232, 232, 232);
    border: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
  }
  .wish-list__empty-div * {
    color: rgb(158, 158, 158);
    text-align: center;
  }
  .wish-list__empty-div i {
    font-size: 5em;
    margin-bottom: 10px;
  }
  .wish-list__empty-div h6 {
    margin: 0;
  }
  .wish-list__empty-div a {
    padding: 0.5em 1em;
    border-radius: 5px;
    background-color: rgb(219, 219, 219);
    transition: background-color 0.3s;
  }
  .wish-list__empty-div a:hover {
    background-color: rgb(203, 203, 203);
  }
}
.start-chat-button {
  border-radius: 15px;
  position: absolute;
  z-index: 4;
  bottom: 25px;
  right: 50px;
  background-color: #511989;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  height: 50px;
  min-width: 50px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1882352941);
  border: none;
  gap: 10px;
  padding: 0 20px;
}
.start-chat-button * {
  font-size: 1.1em;
  color: #ffffff;
  margin: 0;
}
.start-chat-button i {
  margin-top: 5px;
}
.start-chat-button:hover {
  background-color: #6b21b4;
}

@media (max-width: 430px) {
  .start-chat-button {
    bottom: 10px;
    right: 0;
    height: 40px;
    min-width: 40px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1882352941);
    border-radius: 10px;
    padding: 0 10px;
    gap: 8px;
  }
  .start-chat-button * {
    font-size: 0.8em;
    margin: 0;
  }
}
.buttons-box {
  width: 100%;
  height: 2.5em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.buttons-box__edit-item-button {
  height: 2.5em;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #511989;
  color: white;
  gap: 10px;
  border: none;
  outline: none;
}
.buttons-box__edit-item-button * {
  margin: 0;
  color: #ffffff;
}
.buttons-box__delete {
  background-color: rgb(223, 64, 64) !important;
}
.buttons-box__delete:hover {
  background-color: #e14d4d !important;
}
.buttons-box__sold {
  background-color: rgb(243, 243, 243) !important;
  border: 1px solid lightgrey !important;
}
.buttons-box__sold h6, .buttons-box__sold i {
  color: rgb(148, 148, 148) !important;
}
.buttons-box__sold:hover {
  background-color: #cecece !important;
}
.buttons-box__modal .modal-body {
  border: none;
  outline: none;
  min-height: 100px !important;
}
.buttons-box__share-modal__social-inner-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.buttons-box__share-modal__social-inner-div h6 {
  font-size: 0.7em;
}
.buttons-box__share-modal__whatsapp {
  background-color: #21cc62;
}
.buttons-box__share-modal__whatsapp i {
  font-size: 2.3em !important;
}
.buttons-box__share-modal__facebook {
  background-color: #1874ee;
}
.buttons-box__share-modal__twitter {
  background-color: #1c9cea;
}
.buttons-box__share-modal__twitter i {
  font-size: 2.3em !important;
}
.buttons-box__share-modal__instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}
.buttons-box__share-modal__instagram i {
  font-size: 2.3em !important;
}
.buttons-box__share-modal__copy-link-div {
  display: flex;
  justify-content: center;
}
.buttons-box__share-modal__copy-link-div:last-child {
  margin-bottom: 40px;
}
.buttons-box__share-modal__social-link-div {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 3.5em 0 2em;
}
.buttons-box__share-modal__social-link-div button {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  border: none;
}
.buttons-box__share-modal__social-link-div i {
  color: white;
  font-weight: 500;
  font-size: 2em;
}
.buttons-box__share-modal__copy-link-input {
  margin-top: 8px;
  width: 65%;
  border-radius: 5px;
  padding: 0.5em 2em;
  border: 1px solid lightgrey;
  font-weight: 600;
  color: rgb(195, 195, 195);
}
.buttons-box__share-modal__copy-link-input:focus {
  border: 1px solid lightgrey;
  outline: none;
}
.buttons-box__share-modal__copy-link-button {
  border-radius: 5px;
  background-color: #511989;
  color: #ffffff;
  border: none;
  padding: 0.5em 2em;
  font-weight: 600;
}
.buttons-box__sold-button {
  background-color: #511989;
  color: #ffffff;
  padding: 8px 10px;
  width: 60%;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  margin-bottom: 20px;
}
.buttons-box__sold-button.sign-in {
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
}
.buttons-box__sold-button--disabled {
  color: #ffffff;
  width: 100%;
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  background-color: #d1b0f1;
  cursor: not-allowed;
  margin-bottom: 20px;
}
.buttons-box__register-button {
  background-color: #511989;
  color: #ffffff;
  padding: 8px 10px;
  width: 60%;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  margin-bottom: 0;
}
.buttons-box__register-button.sign-in {
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
}
.buttons-box__register-button--disabled {
  color: #ffffff;
  width: 100%;
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  background-color: #d1b0f1;
  cursor: not-allowed;
  margin-bottom: 0;
}
.buttons-box__verify-button {
  width: 100%;
  background-color: #511989;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
}
.buttons-box__modal-title {
  margin: 0;
}
.buttons-box__discard-button {
  background-color: rgb(223, 64, 64);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
}
.buttons-box__modal-body-custom {
  overflow: visible;
}
.buttons-box__inner-modal-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em 2em;
  min-height: 100px;
}
.buttons-box__inner-modal-div__close-button {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  border-radius: 3px;
  background-color: transparent;
  transition: background-color 0.3s;
}
.buttons-box__inner-modal-div__close-button:hover {
  background-color: lightgrey;
}
.buttons-box__inner-modal-div__close-button div:first-child {
  position: absolute;
  width: 100%;
  top: calc(50% - 2px);
  background-color: lightgrey;
  background-color: dimgrey;
  height: 4px;
  border-radius: 3px;
  transform: rotate(45deg);
}
.buttons-box__inner-modal-div__close-button div:last-child {
  position: absolute;
  width: 100%;
  top: calc(50% - 2px);
  background-color: lightgrey;
  background-color: dimgrey;
  height: 4px;
  border-radius: 3px;
  transform: rotate(-45deg);
}
.buttons-box__inner-modal-div.register {
  padding: 0.5em 2em;
}
.buttons-box__inner-modal-div--alt {
  gap: 10px;
}
.buttons-box__inner-modal-div h4 {
  text-align: center;
  color: rgb(99, 99, 99);
}
.buttons-box__phone-input-div {
  position: relative;
}
.buttons-box__phone-input-div h6 {
  position: absolute;
  left: 10px;
  top: 5px;
  border-right: 1px solid grey;
  padding: 4px;
  padding-right: 7px;
  padding-left: 0;
  font-size: 1.1em;
  font-weight: 700;
}
.buttons-box__phone-input {
  flex: 1;
  height: 100%;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid grey;
}
.buttons-box__input-div--otp {
  width: 100% !important;
  margin-top: 2em;
}
.buttons-box__otp {
  width: 100% !important;
}
.buttons-box__input-outer-div {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.buttons-box__input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.buttons-box__input-div label {
  font-weight: 600;
  margin-bottom: 2px;
}
.buttons-box__input-div label span {
  color: lightgrey;
}
.buttons-box__input-div input {
  height: 2.3em;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid grey;
}
.buttons-box__input-div input:focus {
  outline: 1px solid #511989;
}
.buttons-box__inner-input-div {
  position: relative;
}
.buttons-box__inner-input-div input {
  padding-left: 45px;
}
.buttons-box__inner-input-div h6 {
  position: absolute;
  top: 0.5em;
  left: 10px;
  z-index: 2;
  font-weight: 700;
  color: grey;
  border-right: 1px solid grey;
  padding-right: 5px;
}
.buttons-box__country-code-text {
  display: block;
}
.buttons-box__country-code-text--mobile {
  display: none;
}
.buttons-box__phone-input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.buttons-box__phone-input-div label {
  font-weight: 600;
  margin-bottom: 2px;
}
.buttons-box__phone-input-div label span {
  color: lightgrey;
}
.buttons-box__phone-input-div input:focus {
  outline: 1px solid #511989;
}
.buttons-box__inner-phone-input-div {
  display: flex;
  justify-content: space-between;
  height: 2.3em;
  gap: 7px;
  position: relative;
}
.buttons-box__country-code-list {
  position: absolute;
  top: 2.5em;
  left: 0;
  z-index: 10;
  border-radius: 4px;
  background-color: #FFFFFF;
  max-height: 130px;
  min-width: 100%;
  overflow-y: scroll;
  box-shadow: 3px 3px 5px dimgrey;
  margin: 0;
  padding: 0;
}
.buttons-box__country-code-list-item {
  height: 2em;
  border: 1px solid rgb(239, 239, 239);
}
.buttons-box__country-code-list-item button {
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 8px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  transition: background-color 0.3s;
}
.buttons-box__country-code-list-item button:hover {
  background-color: rgba(211, 211, 211, 0.603);
}
.buttons-box__country-code-list-item:last-child {
  border: 1px solid transparent;
}
.buttons-box__country-code-list-item p {
  margin: 0;
  font-size: 0.9em;
}
.buttons-box__country-code-div {
  display: flex;
  padding: 8px;
  gap: 5px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid grey;
}
.buttons-box__country-code-div p {
  font-size: 0.85em;
  margin: 0;
}
.buttons-box__verify-phone-number-button {
  border: none;
  outline: none;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #511989;
  color: #ffffff;
}
.buttons-box__verify-phone-number-button--mobile {
  display: none;
}
.buttons-box__phone-input-carret-button {
  padding: 3px;
  border-radius: 100%;
  border: none;
  outline: none;
  background-color: lightgrey;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
}
.buttons-box__verification-error-message {
  font-size: 0.85em;
  color: rgb(223, 64, 64);
  font-weight: 500;
  margin: 0;
  margin-top: 3px;
}
.buttons-box__verification-success-message {
  font-size: 0.85em;
  color: rgb(53, 188, 53);
  font-weight: 500;
  margin: 0;
  margin-top: 3px;
}
.buttons-box__check-box-div {
  width: 100%;
  gap: 10px;
  display: flex;
}
.buttons-box__switch-to-login-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.buttons-box__switch-to-login-div.signin {
  margin: 0;
}
.buttons-box__switch-to-login-div h6 {
  margin: 0;
}
.buttons-box__switch-to-register-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.buttons-box__switch-to-register-div.signin {
  margin: 0;
}
.buttons-box__switch-to-register-div h6 {
  margin: 0;
}
.buttons-box__forgot-password-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.buttons-box__forgot-password-div h6 {
  margin: 0;
  color: #511989;
}
.buttons-box__forgot-password-div h6:hover {
  text-decoration: underline;
}
.buttons-box__forgot-password-div button {
  border: none;
  outline: none;
  background-color: transparent;
}
.buttons-box__check-box-text-div {
  display: flex;
  align-items: center;
}
.buttons-box__check-box-text-div h6, .buttons-box__check-box-text-div button {
  font-size: 0.85;
  margin: 0;
}
.buttons-box__check-box-text-div span.buttons-box__terms-button {
  color: #511989;
}
.buttons-box__check-box-text-div span.buttons-box__terms-button:hover {
  text-decoration: underline;
}
.buttons-box__switch-to-login-button {
  color: #511989;
}
.buttons-box__switch-to-login-button:hover {
  text-decoration: underline;
}
.buttons-box__terms-button {
  border: none;
  background-color: transparent;
}
.buttons-box button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 100%;
  flex: 1;
  background-color: #511989;
  margin-right: 12px;
  transition: background-color 0.3s;
}
.buttons-box button:hover {
  background-color: #6b21b4;
}
.buttons-box button:last-child {
  margin-right: 0;
}
.buttons-box button h6, .buttons-box button i {
  margin: 0;
  font-size: 0.95em;
  color: #ffffff;
}
.buttons-box button i {
  margin-right: 10px;
}
.buttons-box__form {
  padding: 15px 20px;
  border: none !important;
  outline: none !important;
}
.buttons-box__form textarea {
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  padding: 10px;
}
.buttons-box__send-report-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgb(53, 188, 53);
  color: white;
  border: none;
  font-weight: 600;
}
.buttons-box__close-report-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: lightgrey;
  border: none;
  color: rgb(63, 63, 63);
  font-weight: 600;
}
.buttons-box__new-arrivals {
  width: 100%;
  height: 3em;
  border: none;
  outline: none;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #511989;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.buttons-box__new-arrivals h1 {
  font-size: 1.2em;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}
.buttons-box__new-arrivals i {
  font-size: 1.2em;
  color: #ffffff;
}
.buttons-box__modal-header {
  margin: 0;
}
.buttons-box__star-rating-inner-div {
  display: flex;
}
.buttons-box__star-rating-icon-div {
  width: 3.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons-box__star-rating-icon-div i {
  font-size: 2.3em;
  color: lightgrey;
  transition: color 0.3s;
}
.buttons-box__star-rating-icon-div i.active {
  color: #511989;
}
.buttons-box__review-text-area {
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  padding: 10px;
}
.buttons-box__modal-logo {
  height: 60px;
}
.buttons-box__modal-title-div {
  padding: 40px 0 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 15px;
}
.buttons-box__modal-title-div.sign-in {
  padding-top: 10px;
}
.buttons-box__modal-title-div-alt {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.buttons-box__modal-logo-div {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 3;
  top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 430px) {
  .buttons-box__country-code-div {
    width: 38%;
  }
  .buttons-box__country-code-text {
    display: none;
  }
  .buttons-box__country-code-text--mobile {
    display: block;
  }
  .buttons-box__phone-input {
    width: 50%;
  }
  .buttons-box__verify-phone-number-button {
    display: none;
  }
  .buttons-box__verify-phone-number-button--mobile {
    display: block;
    border: none;
    outline: none;
    padding: 0 10px;
    border-radius: 4px;
    background-color: #511989;
    color: #ffffff;
    margin-top: 8px;
    height: 2em;
  }
}
.carousel__custom {
  background-color: rgb(244, 244, 244);
  position: relative;
  height: 100%;
  border-radius: 5px;
}
.carousel__custom .carousel-item__custom {
  border-radius: 5px;
  width: 100%;
  height: 472px;
  overflow: hidden;
}
.carousel__custom .carousel-item__custom img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.carousel__custom__dummy-image {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
.carousel__custom__button-prev {
  height: 40px;
  width: 40px;
  border: 4px solid white;
  border-radius: 50%;
  padding: 0.5em;
  background-color: #511989;
  position: absolute;
  opacity: 1;
  left: -20px;
  top: calc(50% - 20px);
}
.carousel__custom__button-next {
  height: 40px;
  width: 40px;
  border: 4px solid white;
  border-radius: 50%;
  padding: 0.5em;
  background-color: #511989;
  position: absolute;
  opacity: 1;
  right: -20px;
  top: calc(50% - 20px);
}
.carousel__custom__add-to-wishlist-button {
  position: absolute;
  z-index: 4;
  top: 25px;
  right: 50px;
  height: 2.8em;
  width: 2.8em;
  border-radius: 50%;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fac007;
}
.carousel__custom__add-to-wishlist-button i {
  font-size: 1.2em;
  color: #ffffff;
}

@media (max-width: 430px) {
  .carousel__custom {
    height: 305px;
  }
  .carousel__custom .carousel-item__custom {
    border-radius: 5px;
    width: 100%;
    height: 305px;
    overflow: hidden;
  }
  .carousel__custom .carousel-item__custom img {
    height: 100%;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .carousel__custom__dummy-image {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .carousel__custom__button-prev {
    height: 40px;
    width: 40px;
    border: 4px solid white;
    border-radius: 50%;
    padding: 0.5em;
    background-color: #511989;
    position: absolute;
    opacity: 1;
    left: -20px;
    top: calc(50% - 20px);
  }
  .carousel__custom__button-next {
    height: 40px;
    width: 40px;
    border: 4px solid white;
    border-radius: 50%;
    padding: 0.5em;
    background-color: #511989;
    position: absolute;
    opacity: 1;
    right: -20px;
    top: calc(50% - 20px);
  }
  .carousel__custom__add-to-wishlist-button {
    top: 15px;
    right: 15px;
    height: 2.4em;
    width: 2.4em;
  }
  .carousel__custom__add-to-wishlist-button i {
    font-size: 1em;
  }
}
.notifications__list-heading {
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
  background-color: rgb(132, 132, 132);
  color: #fff;
}
.notifications__empty-div {
  width: 50%;
  min-height: 250px;
  border-radius: 5px;
  margin: 50px auto 30px;
  background-color: rgb(232, 232, 232);
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notifications__empty-div * {
  color: rgb(158, 158, 158);
  text-align: center;
}
.notifications__empty-div i {
  font-size: 5em;
}
.notifications__empty-div h6 {
  margin: 0;
}
.notifications__empty-div a {
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: rgb(219, 219, 219);
  transition: background-color 0.3s;
}
.notifications__empty-div a:hover {
  background-color: rgb(203, 203, 203);
}
.notifications__list {
  padding: 0;
}
.notifications__list-div {
  background-color: #fff;
  padding: 15px 0;
  margin: 0 auto;
}
.notifications__card {
  width: 100%;
  height: auto;
  padding: 15px;
  border: 1px solid lightgrey;
  margin-right: 20px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.notifications__card__message-text {
  font-weight: 400;
}
.notifications__card:last-child {
  margin-bottom: 0;
}
.notifications__card__heading-div {
  display: flex;
  gap: 15px;
}
.notifications__card__time {
  text-align: right;
  color: rgb(169, 169, 169);
}
.notifications__card__heading-icon-div {
  background-color: lightgrey;
  height: 1.7em;
  width: 1.7em;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notifications__card__heading-icon-div i {
  color: rgb(243, 243, 243);
}

@media (max-width: 430px) {
  .notifications__empty-div {
    width: 90%;
    min-height: 250px;
    margin: 30px auto;
    padding: 1.5em;
  }
  .notifications__empty-div i {
    font-size: 5em;
    margin-bottom: 10px;
  }
  .notifications__list-div {
    position: initial;
    top: unset;
    padding: 15px 0;
    margin: 0 auto;
  }
}
.premium-account-box {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: rgb(243, 243, 243);
  margin-bottom: 20px;
}
.premium-account-box p {
  line-height: 1.3;
  color: #484848;
}
.premium-account-box__button {
  width: 100%;
  height: 2.5em;
  border-radius: 5px;
  background-color: #fac007;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.premium-account-box__button h5 {
  color: #484848;
  margin: 0;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.8px;
}

.tabs-custom {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
}
.tabs-custom__tab-item {
  background-color: transparent;
}
.tabs-custom__tab-item button {
  font-weight: 600;
  color: #484848;
}
.tabs-custom__tab-item button:hover {
  color: #484848 !important;
  border-bottom: 1px solid lightgrey !important;
}
.tabs-custom__tab-item button.active {
  color: #484848 !important;
  border: 1px solid lightgrey !important;
  border-bottom: 1px solid #fff !important;
  background-color: transparent !important;
}
.tabs-custom__empty-div {
  width: 50%;
  min-height: 250px;
  border-radius: 5px;
  margin: 80px auto 30px;
  background-color: rgb(232, 232, 232);
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5em;
}
.tabs-custom__empty-div * {
  color: rgb(158, 158, 158);
  text-align: center;
}
.tabs-custom__empty-div i {
  font-size: 5em;
}
.tabs-custom__empty-div__button {
  padding: 8px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  background-color: #511989;
  font-weight: 600;
  color: #ffffff;
}
.tabs-custom__reload {
  background-color: #511989;
  margin-top: 30px;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 8px 15px;
  font-weight: 600;
}
.tabs-custom__button {
  height: 2.5em;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #511989;
  margin-right: 12px;
  padding: 0.5em 1em;
  transition: background-color 0.3s;
  color: #ffffff;
  font-size: 0.95em;
}
.tabs-custom__button:hover {
  background-color: #6b21b4;
}

@media (max-width: 430px) {
  .tabs-custom__empty-div {
    width: 90%;
    min-height: 250px;
    margin: 30px auto;
    padding: 2.5em;
  }
  .tabs-custom__empty-div i {
    font-size: 5em;
  }
  .hide-tab-on-mobile {
    display: none;
  }
}
.loader__container {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader__div {
  height: 120px;
  width: 120px;
  border-radius: 4px;
}
.loader__div span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #511989;
  margin: 35px 5px;
}
.loader__div span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}
.loader__div span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}
.loader__div span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}
@keyframes bounce {
  0%, 75%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
}

.search__categories-box ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}
.search__categories-box button {
  border: none;
  background-color: rgb(243, 243, 243);
  border: 1px solid lightgrey;
  padding: 0.5em 1em;
  border-radius: 50px;
  color: #511989;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0.3em;
}
.search__categories-box button * {
  font-weight: 700;
  margin: 0;
}

@media (max-width: 430px) {
  .search__categories-box ul {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: center;
  }
  .search__categories-box h6 {
    text-wrap: nowrap;
    font-size: 0.9em;
    margin-bottom: 0;
    font-weight: 700;
  }
  .search__categories-box button {
    max-width: 400px;
    display: inline-block;
  }
}
.find-closer-items__button {
  width: 100%;
  height: auto;
  border: 1px solid lightgrey;
  padding: 0.5em 1em;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: transparent;
  transition: background-color 0.4s;
}
.find-closer-items__button:hover {
  background-color: rgb(243, 243, 243);
}
.find-closer-items__button h6 {
  margin: 0;
}
.find-closer-items__icon-div {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  display: flex;
  justify-content: center;
  align-items: center;
}
.find-closer-items__icon-div i {
  font-size: 1.3em;
  color: #511989;
}

.mile-range__div {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 1em;
}
.mile-range__div input {
  width: 100%;
}
.mile-range__div input {
  width: 100%;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.mile-range__div input::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 50%;
  background: #511989;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.mile-range__div input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #511989;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.mile-range__info span {
  font-weight: 700;
  color: #511989;
}
.mile-range__input-div {
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
}
.mile-range__input-range-bar {
  width: 100%;
  height: 5px;
  display: flex;
  position: relative;
  align-items: center;
  background: #ddd;
  border-radius: 5px;
}
.mile-range__thumb {
  z-index: 2;
}
.mile-range__trail-line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #511989;
  border-radius: 5px;
  z-index: 1;
}

.verification-box {
  border: 1px solid #ea8282;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9d9d9;
  margin-bottom: 100px;
}
.verification-box--success {
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 100px;
  border: 1px solid #91e091;
  background-color: #e0f6e0;
}
.verification-box__title {
  text-align: center;
}
.verification-box__text {
  text-align: center;
}
.verification-box__buttons-div {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.verification-box__send-verification-button {
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: #e56363;
  color: white;
  border: none;
  font-weight: 600;
}
.verification-box__send-verification-button--success {
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: 600;
  background-color: #5dd15d;
}
.verification-box__countdown {
  color: #e56363;
  text-align: center;
}
.verification-box__approve-verification-button {
  background-color: transparent;
  color: #e56363;
  font-weight: 600;
  border: none;
}
.verification-box__approve-verification-button:hover {
  text-decoration: underline;
}

.verification-card {
  border: 1px solid #ea8282;
  border-radius: 5px;
  padding: 15px;
  background-color: #f9d9d9;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verification-card__title {
  margin: 0;
}
.verification-card__button {
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: #e56363;
  color: white;
  border: none;
  font-weight: 600;
}

.job-postings__div {
  padding: 20px 0;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.job-postings__tab-button-div {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.job-postings__tab-button {
  outline: none;
  border: none;
  font-weight: 700;
  padding: 5px 20px;
  width: 100px;
  border-radius: 50px;
  background-color: transparent;
  color: #511989;
}
.job-postings__tab-button:hover {
  background-color: rgb(243, 243, 243);
}
.job-postings__tab-button.active {
  background-color: #511989;
  color: #ffffff;
}
.job-postings__post-button {
  outline: none;
  border: none;
  font-weight: 700;
  padding: 5px 20px;
  min-width: 180px;
  border-radius: 50px;
  background-color: rgb(243, 243, 243);
  color: dimgrey;
}
.job-postings__post-button:hover {
  background-color: #dadada;
  color: dimgrey;
}
.job-postings__card-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  -moz-column-gap: 15px;
       column-gap: 15px;
  height: 100%;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid lightgrey;
  outline: none;
  padding: 15px;
  transition: background-color 0.3s;
  text-align: start;
}
.job-postings__card-div:hover {
  background-color: rgb(243, 243, 243);
}
.job-postings__card-div p {
  margin-bottom: 5px;
}
.job-postings__info-div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.job-postings__image-div {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-color: rgb(243, 243, 243);
  border: 1px solid lightgrey;
  overflow: hidden;
}
.job-postings__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50px;
  opacity: 0.3;
}
.job-postings__single-post-title-div {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
}
.job-postings__single-post-title {
  margin-bottom: 15px;
}
.job-postings__contact-detail-title-div {
  margin-bottom: 15px;
}
.job-postings__container-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
}
.job-postings__text-div {
  margin-bottom: 20px;
}
.job-postings__delete-button {
  border: none;
  outline: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  padding: 5px 20px;
  background-color: rgb(223, 64, 64);
  margin-top: 70px;
}
.job-postings__tab-button-title {
  margin: 0;
}
.job-postings__web-link-button {
  border: none;
  outline: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  padding: 5px 20px;
  background-color: #511989;
}
/* .job-postings__job-type-div {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
} */

.job-postings__job-type-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  gap: 5px; /* Space between items */
  margin-top: 10px;
}

.job-postings__job-type {
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(243, 243, 243);
  font-weight: 600;
  padding: 2px 8px;
}
.job-postings__contact-detail-div {
  display: flex;
  -moz-column-gap: 200px;
       column-gap: 200px;
}
.job-postings__card-inner-div {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.job-postings__job-type {
  padding: 2px 5px;
  background-color: grey;
  color: white;
  border-radius: 3px;
  font-size: 14px;
}


.job-postings__checkbox-outer-div {
  display: flex;
  -moz-column-gap: 70px;
       column-gap: 70px;
  height: 50px;
  align-items: center;
}
.job-postings__checkbox-div {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.job-postings__checkbox-div h6 {
  margin: 0;
}
.job-postings__submit-button {
  height: 2.3em;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  width: 120px;
  font-weight: 600;
  outline: none;
  background-color: #511989;
  padding: 5px 10px;
}
.job-postings__dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}
.job-postings__nested-dropdown {
  margin-top: 10px;
}
.job-postings__nested-dropdown select {
  padding: 5px;
  font-size: 16px;
}

@media (max-width: 430px) {
  .job-postings__div--addPost {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 5px 0;
  }
  .job-postings__tab-button-title {
    text-align: center;
    flex: 1;
    margin: 0;
  }
  .job-postings__post-button {
    margin-bottom: 25px;
    width: 70%;
  }
  .job-postings__container-div {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
}
.contain-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #3a7aa2, #511989);
  background-image: linear-gradient(to bottom right, #511989, #3a7aa2);
}
.contain-div h1 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 1.2em;
  color: #511989;
  margin-bottom: 30px;
}
.contain-div__home-button {
  border: none;
  border-radius: 4px;
  background-color: #511989;
  margin-top: 12px;
  padding: 0.4em 0.7em;
}
.contain-div__home-button h6 {
  font-size: 0.6em;
  color: white;
  margin-bottom: 0 !important;
}

.done {
  width: 100px;
  height: 100px;
  position: relative;
  left: 0;
  right: 0;
  top: -20px;
  margin: auto;
}

.congrats {
  position: relative;
  left: 50%;
  top: 50%;
  max-width: 800px;
  transform: translate(-50%, -50%);
  width: 80%;
  min-height: 300px;
  max-height: 900px;
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 12px 15px 5px rgba(0, 0, 0, 0.2078431373);
  background-image: linear-gradient(to bottom right, #02b3e4, #02ccba);
  background: #fff;
  text-align: center;
  font-size: 2em;
  color: #511989;
  padding: 0.65em;
}

.text {
  position: relative;
  font-weight: normal;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  max-width: 800px;
  font-family: "Lato", sans-serif;
  font-size: 0.6em;
}

.circ {
  opacity: 0;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  transition: all 1s;
}

.tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  transition: stroke-dashoffset 1s 0.5s ease-out;
}

.drawn svg .path {
  opacity: 1;
  stroke-dashoffset: 0;
}

.regards {
  font-size: 0.7em;
}

@media (max-width: 600px) {
  .congrats h1 {
    font-size: 1.2em;
  }
  .done {
    top: -10px;
    width: 80px;
    height: 80px;
  }
  .text {
    font-size: 0.5em;
  }
  .regards {
    font-size: 0.6em;
  }
}
@media (max-width: 500px) {
  .congrats h1 {
    font-size: 1em;
  }
  .done {
    top: -10px;
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 410px) {
  .congrats h1 {
    font-size: 1em;
  }
  .congrats .hide {
    display: none;
  }
  .congrats {
    width: 100%;
  }
  .done {
    top: -10px;
    width: 50px;
    height: 50px;
  }
  .regards {
    font-size: 0.55em;
  }
}
@media (max-width: 430px) {
  .contain-div {
    padding: 0 15px;
  }
}
.terms-and-conditions__line {
  width: 100%;
  height: 1px;
  background-color: rgb(230, 230, 230);
  margin: 20px 0;
}

.pulse__container {
  padding: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse {
  height: 100px;
  width: 100px;
  background-color: #511989;
  z-index: 2;
  position: relative;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 50px;
  color: #ffffff;
}
.pulse i {
  color: white;
  font-size: 0.8em;
}

.pulse:before,
.pulse:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
  background-color: #511989;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
}

.pulse:before {
  animation: pulse 2s ease-out infinite;
}

.pulse:after {
  animation: pulse 2s 1s ease-out infinite;
}

@keyframes pulse {
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.settings {
  padding: 15px;
}
.settings__title-header {
  margin: 0;
  font-weight: 700;
  color: #511989;
}
.settings__account-info {
  background-color: rgb(225, 225, 225);
  border: 1px solid dimgrey;
  border-radius: 4px;
  padding: 0 8px;
}
.settings__account-info-div {
  border-bottom: 2px solid rgb(177, 177, 177);
  height: 2.4em;
  line-height: 2;
}
.settings__account-info-div:last-child {
  border-bottom: none;
}
.settings__account-info-div * {
  margin: 0;
}
.settings__account-info-title {
  font-weight: 700;
}

.request__hero-section-outer-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.request__hero-section-div {
  border: 2px solid lightgrey;
  background-color: rgb(243, 243, 243);
  border-radius: 4px;
  width: 70%;
  padding: 15px;
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
  justify-content: center;
  align-items: center;
}
.request__item-card {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  background-color: #fff;
  width: 100%;
  height: auto;
  transition: background-color 0.2s;
}
.request__item-card:hover {
  background-color: rgb(243, 243, 243);
}
.request__item-card__info-div h5 {
  height: 2.5em;
  overflow: hidden;
  margin-bottom: 2px;
  text-align: left;
}
.request__item-card__info-div h6 {
  margin-bottom: 0;
  color: darkgrey;
  text-align: left;
}
.request__item-card__icon-div {
  border-radius: 4px;
  height: 35px;
  width: 35px;
  margin-bottom: 5px;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.request__search-bar-div {
  height: 2.5em;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.request__search-bar {
  height: 100%;
  width: 45%;
  border: 1px solid rgb(126, 126, 126);
  border-radius: 4px;
  padding: 0 15px;
}
.request__search-bar-button {
  height: 2.3em;
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #511989;
  border: none;
}
.request__single-item-item-type {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  background-color: rgb(243, 243, 243);
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.request__single-item-item-type i {
  font-size: 4em;
}
.request__single-item-info-div {
  display: flex;
  height: 200px;
  gap: 20px;
}
.request__single-item-inner-info-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.request__single-item__vendor-info-div {
  display: flex;
  gap: 10px;
}
.request__single-item__vendor-info-div button {
  border: none;
  background-color: transparent;
  outline: none;
  font-weight: 700;
  line-height: 1;
}
.request__single-item__vendor-info-div button:hover {
  text-decoration: underline;
}
.request__single-item__vendor-image-div {
  background-color: lightgrey;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
.request__single-item__vendor-image-div img {
  width: 25px;
  height: 25px;
  -o-object-fit: cover;
     object-fit: cover;
}
.request__single-item__inner-info-div-top {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.request__single-item__inner-info-div-top h3, .request__single-item__inner-info-div-top h5, .request__single-item__inner-info-div-top p, .request__single-item__inner-info-div-top h6 {
  margin: 0;
}
.request__single-item__inner-info-div-bottom {
  height: 2.2em;
}
.request__single-item__inner-info-div-bottom__button {
  border-radius: 4px;
  color: #ffffff;
  background-color: #511989;
  border: none;
  outline: none;
  height: 100%;
  padding: 0 35px;
  font-weight: 700;
  letter-spacing: 1px;
}
.request__single-item__inner-info-div-bottom__button.delete-button {
  background-color: rgb(223, 64, 64);
}
.request__section {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: rgb(243, 243, 243);
  border: 1px solid lightgrey;
  margin-bottom: 20px;
  padding: 15px;
}
.request__section__text {
  flex: 3.5;
  text-align: center;
  font-weight: 700;
  width: 60%;
  text-align: left;
}
.request__section__button {
  flex: 1;
  color: #ffffff;
  border-radius: 4px;
  background-color: #511989;
  padding: 5px 20px;
  width: 100%;
  border: none;
  outline: none;
}
.request__section__button.home {
  width: 180px;
  width: 100%;
  padding: 5px 10px;
}

.request-box__outer-div {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  height: auto;
  width: 100%;
  padding: 0 150px;
  margin-bottom: 20px;
}
.request-box__item-div {
  height: auto;
  padding: 15px;
  flex: 1;
  border-radius: 4px;
  border: 2px solid lightgrey;
}
.request-box__title {
  font-weight: 700;
  color: #511989;
}
.request-box__text {
  color: darkgray;
  width: 90%;
}
.request-box__button {
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 20px;
  background-color: #511989;
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@media (max-width: 430px) {
  .request__item-card__info-div h5 {
    height: 1.3em;
    margin-bottom: 2px;
  }
  .request__item-card__icon-div {
    height: 50px;
    width: 50px;
  }
  .request__item-card__icon-div i {
    font-size: 1.2em;
  }
  .request__search-bar {
    width: 80%;
  }
  .request__single-item-info-div {
    flex-direction: column;
    height: auto;
    gap: 20px;
    margin-top: 30px;
  }
  .request__single-item-item-type {
    width: 80px;
    height: 80px;
    gap: 2px;
    align-self: center;
  }
  .request__single-item-item-type i {
    font-size: 2em;
  }
  .request__single-item-item-type h5 {
    font-size: 0.8em;
    margin: 0;
  }
  .request__single-item-inner-info-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .request__single-item__vendor-info-div {
    justify-content: center;
    margin-bottom: 10px;
  }
  .request__single-item__vendor-info-div button {
    border: none;
    background-color: transparent;
    outline: none;
    font-weight: 700;
    line-height: 1;
  }
  .request__single-item__vendor-info-div button:hover {
    text-decoration: underline;
  }
  .request__single-item__vendor-image-div {
    background-color: lightgrey;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .request__single-item__inner-info-div-top {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .request__single-item__inner-info-div-top h3, .request__single-item__inner-info-div-top h5, .request__single-item__inner-info-div-top p, .request__single-item__inner-info-div-top h6 {
    text-align: center;
  }
  .request__single-item__inner-info-div-bottom {
    height: 2.2em;
    margin-bottom: 50px;
  }
  .request__single-item__inner-info-div-bottom__button {
    border-radius: 4px;
    width: 100%;
  }
  .request__single-item__inner-info-div-bottom__button.delete-button {
    background-color: rgb(223, 64, 64);
  }
  .request-box__outer-div {
    gap: 15px;
    row-gap: 15px;
    height: auto;
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap; /* Prevents wrapping of the flex items */
    width: -moz-max-content;
    width: max-content; /* Ensures the inner container width exceeds the outer container */
  }
  .request-box__outermost-div {
    display: flex;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents line breaks */
    width: 100%; /* Adjust the width as needed */
  }
  .request-box__item-div {
    height: auto;
    padding: 15px;
    flex: 0 0 auto; /* Prevents cards from shrinking */
    width: 350px;
    border-radius: 4px;
    border: 2px solid lightgrey;
    text-wrap: wrap;
  }
  .request-box__item-div p {
    line-height: 1.3;
  }
  .request-box__title {
    font-weight: 700;
    color: #511989;
  }
  .request-box__text {
    color: darkgray;
    width: 90%;
  }
  .request-box__button {
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 5px 20px;
    background-color: #511989;
    color: #ffffff;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
.new-arrivals__no-arrivals-outer-div {
  display: flex;
  justify-content: center;
}
.new-arrivals__no-arrivals-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  height: 150px;
  width: 60%;
  margin-bottom: 20px;
}
.new-arrivals__view-all-button {
  height: 2em;
  outline: none;
  border: 1px solid lightgrey;
  background-color: lighygrey;
  border-radius: 4px;
  width: 45%;
}
.new-arrivals__view-all-button-div {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footer {
  height: auto;
  background-color: #511989;
  padding-top: 30px;
}
.footer__top-div {
  width: 1400px;
  margin: 0 auto 30px;
}
.footer__heading {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 6px !important;
}
.footer__sub-heading {
  text-decoration: underline;
  color: #ffffff;
  font-weight: 700;
}
.footer__brand-info {
  line-height: 1.3;
  font-size: 0.9em;
  color: #ffffff;
  width: 300px;
  margin-bottom: 0;
}
.footer__email-div {
  display: flex;
  justify-content: flex-end;
}
.footer__email-inner-div h5, .footer__email-inner-div p {
  margin-bottom: 2px;
  color: #ffffff;
}
.footer__category-list {
  margin: 0;
  padding: 0;
}
.footer__brand {
  display: flex;
  align-self: center;
  gap: 8px;
  margin-bottom: 1px;
}
.footer__app-logo {
  height: 1.1em;
}
.footer__category-list-item {
  margin-bottom: 3px;
}
.footer__category-list-item a {
  color: white;
  font-weight: 500;
}
.footer__category-list-item a:hover {
  text-decoration: underline;
}
.footer__contact-div {
  margin-bottom: 15px;
  cursor: pointer;
}
.footer__contact-div p:hover {
  text-decoration: underline;
}
.footer__contact-icon {
  height: 16px;
  width: 16px;
  color: white;
  border-radius: 50%;
  margin-right: 10px;
}
.footer__contact-info {
  font-size: 0.9em;
  margin: 0;
  line-height: 1;
  font-weight: 500;
  color: #ffffff;
}
.footer__socials-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5019607843);
  margin-right: 15px;
  cursor: pointer;
}
.footer__social-icon {
  font-size: 1.4em;
  color: #511989;
}
.footer__bottom-div {
  height: 60px;
  background-color: rgba(255, 255, 255, 0.1254901961);
  align-items: center;
  justify-content: center;
}
.footer__bottom-div h6 {
  color: rgba(255, 255, 255, 0.236);
  margin: 0;
}
.footer__info-div {
  margin-top: 20px;
}
.footer__info--get-the-app {
  padding-top: 20px;
}
.footer__info--get-the-app__title {
  color: #ffffff;
}
.footer__info--get-the-app__button {
  background-color: black;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 8px 18px;
}
.footer__info--get-the-app__button-image-div {
  width: 30px;
}
.footer__info--get-the-app__button-image {
  width: 100%;
}
.footer__info--get-the-app__button-info-div {
  display: flex;
  flex-direction: column;
}
.footer__info--get-the-app__button-info-div * {
  margin: 0;
  color: #ffffff;
  line-height: 1;
}
.footer__info--get-the-app__button-info-div p {
  font-size: 0.9em;
}
.footer__info--get-the-app__button-info-div h3 {
  font-size: 1.5em;
}
.footer__info--get-the-app__button-div {
  display: flex;
  gap: 12px;
}
.footer__info a {
  color: #ffffff;
}

@media (max-width: 1000px) {
  .footer {
    padding-top: 0;
  }
  .footer__top-div {
    width: 100%;
    margin: 0 auto 20px;
    padding: 0 1em;
  }
  .footer__heading {
    font-size: 1.4em;
    margin: 20px 0 10px !important;
  }
  .footer__categories {
    display: none;
  }
  .footer__brand {
    display: flex;
    align-self: center;
    gap: 8px;
    margin: 20px 0 5px;
  }
  .footer__brand .footer__heading {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
  .footer__brand-info {
    line-height: 1.1;
    font-size: 1em;
    width: 75%;
    margin-bottom: 20px;
  }
  .footer__category-list-item {
    margin-bottom: 6px;
  }
  .footer__category-list-item a {
    font-size: 1.1em;
  }
  .footer__contact-div {
    margin-bottom: 15px;
  }
  .footer__contact-icon {
    height: 22px;
    width: 22px;
  }
  .footer__contact-info {
    font-size: 1.1em;
    margin: 0;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
  }
  .footer__socials-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5019607843);
    margin-right: 15px;
  }
  .footer__social-icon {
    font-size: 1.4em;
    color: #511989;
  }
  .footer__bottom-div {
    height: 60px;
    background-color: rgba(255, 255, 255, 0.1254901961);
    align-items: center;
    justify-content: center;
  }
  .footer__bottom-div h6 {
    color: #ffffff;
    margin: 0;
  }
}
@media (max-width: 430px) {
  .footer {
    padding-top: 0;
  }
  .footer__top-div {
    width: 100%;
    margin: 0 auto 20px;
    padding: 0 1em;
  }
  .footer__heading {
    font-size: 1.4em;
    margin: 20px 0 10px !important;
  }
  .footer__brand {
    display: flex;
    align-self: center;
    gap: 8px;
    margin: 20px 0 5px;
  }
  .footer__brand .footer__heading {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
  .footer__brand-info {
    line-height: 1.1;
    font-size: 1em;
    width: 75%;
    margin-bottom: 20px;
  }
  .footer__email-div {
    justify-content: flex-start;
    margin-top: 50px;
  }
  .footer__category-list-item {
    margin-bottom: 6px;
  }
  .footer__category-list-item a {
    font-size: 1.1em;
  }
  .footer__contact-div {
    margin-bottom: 15px;
  }
  .footer__contact-icon {
    height: 22px;
    width: 22px;
  }
  .footer__contact-info {
    font-size: 1.1em;
    margin: 0;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
  }
  .footer__socials-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5019607843);
    margin-right: 15px;
  }
  .footer__social-icon {
    font-size: 1.4em;
    color: #511989;
  }
  .footer__bottom-div {
    height: 60px;
    background-color: rgba(255, 255, 255, 0.1254901961);
    align-items: center;
    justify-content: center;
  }
  .footer__bottom-div h6 {
    color: #ffffff;
    margin: 0;
  }
}
.main-section-div {
  background-color: #fff;
  height: auto;
  min-height: 406px;
  padding: 30px 0;
}

.main-section {
  margin: 0 auto;
  width: 1400px;
  height: 100%;
}
.main-section__left-div {
  width: 300px;
  margin-right: 10px;
}
.main-section__right-div {
  flex: 1;
  margin-left: 10px;
}
.main-section__right-div__category-alt {
  display: flex;
  flex-direction: column;
}
.main-section__right-div__category-alt .hero {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.main-section__mobile-div {
  display: none;
}

@media (max-width: 1000px) {
  .main-section-div {
    background-color: #fff;
    height: auto;
    padding: 20px 0;
  }
  .main-section {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .main-section__left-div {
    display: none;
    flex: 1;
    margin-right: 10px;
  }
  .main-section__right-div {
    flex: unset;
    width: 100%;
    margin: 0;
    padding: 0 0.5em;
  }
  .main-section__mobile-div {
    display: block;
    margin-bottom: 30px;
  }
}
@media (max-width: 430px) {
  .main-section-div {
    background-color: #fff;
    height: auto;
    padding: 15px 0;
  }
  .main-section {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .main-section__left-div {
    display: none;
    flex: 1;
    margin-right: 10px;
  }
  .main-section__right-div {
    flex: unset;
    width: 100vw;
    margin: 0;
    padding: 0 0.5em;
  }
  .main-section__mobile-div {
    display: block;
    margin-bottom: 30px;
  }
}
.checkout__success-panel {
  padding: 15px;
  border-radius: 8px;
  background-color: lightgrey;
  border: 1px solid rgb(243, 243, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.checkout__success-message {
  width: 80%;
  height: auto;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 20px;
}
.checkout__success-message h3 {
  text-align: center;
  margin-bottom: 20px;
}
.checkout__success-button {
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  background-color: #511989;
  color: white;
  font-weight: 700;
}
.checkout__layout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}

form.checkout__form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

button.checkout__buttom {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff !important;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button.checkout__buttom:hover {
  filter: contrast(115%);
}

button.checkout__buttom:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.checkout__spinner,
.checkout__spinner:before,
.checkout__spinner:after {
  border-radius: 50%;
}

.checkout__spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.checkout__spinner:before,
.checkout__spinner:after {
  position: absolute;
  content: "";
}

.checkout__spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.checkout__spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loadSpinner 2s infinite ease;
}

@keyframes loadSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
.about__image-div {
  width: 50%;
  height: 300px;
  float: left;
}
.about__image-div img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.about__heading {
  color: #511989;
}
.about__button {
  margin: 2.5em 0;
  border: none;
  padding: 0.5em 2em;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  background-color: #511989;
  text-transform: capitalize;
}

@media (max-width: 430px) {
  .about__image-div {
    width: 100%;
    height: 250px;
    float: unset;
  }
}
.item-type-button {
  width: 250px;
  height: 40px;
  border: 1px solid #d3d3d3;
  border-radius: 50px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.item-type-button__outer-div {
  display: flex;
  justify-content: center;
}
.item-type-button__top-button {
  position: absolute;
  border: 2px solid #f3f3f3;
  height: 100%;
  width: 55%;
  border-radius: 50px;
  color: #ffffff;
  font-weight: 700;
  background-color: #511989;
  right: 45%;
  transition: right 0.9s;
}
.item-type-button__top-button.cars {
  right: 0;
}
.item-type-button__bottom-button-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
}
.item-type-button__bottom-button-div button {
  flex: 1;
  outline: none;
  border: none;
  background-color: #f3f3f3;
  font-weight: 700;
  color: rgb(128, 128, 128);
}

@media (max-width: 430px) {
  .item-type-button {
    width: 200px;
    height: 40px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .item-type-button__top-button {
    font-weight: 600;
  }
  .item-type-button__top-button.cars {
    right: 0;
  }
  .item-type-button__bottom-button-div button {
    font-weight: 600;
  }
}
.top-brands {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  overflow-x: scroll;
  gap: 15px;
}
.top-brands::-webkit-scrollbar {
  display: none;
}
.top-brands__brand-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: auto;
}
.top-brands__image-div {
  border-radius: 50%;
  border: 1px solid lightgrey;
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
  padding: 5px;
}
.top-brands__image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.top-brands__text {
  text-align: center;
  font-size: 0.9em;
}

@media (max-width: 430px) {
  .top-brands {
    margin-bottom: 20px;
    gap: 15px;
  }
  .top-brands__image-div {
    width: 55px;
    height: 55px;
    margin-bottom: 5px;
    padding: 5px;
  }
  .top-brands__text {
    font-size: 0.8em;
  }
}
.welcome-page__right-div {
  display: flex;
  flex-direction: column;
}

.welcome-page__hero {
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
}
.welcome-page__hero__new-arrivals-button {
  border: none;
  outline: none;
  border-radius: 5px;
  display: flex;
  gap: 15px;
  background-color: #511989;
  padding: 0 20px;
  height: 2.8em;
  align-items: center;
}
.welcome-page__hero__new-arrivals-button * {
  color: white;
  font-size: 1.3em;
  margin: 0;
}
.welcome-page__hero__new-arrivals-button.shiny {
  background: #511989;
  background: linear-gradient(-45deg, #511989 50%, #b2b3e5 60%, #511989 70%);
  background-size: 600% 100%;
  animation: shine 20s infinite;
  animation-delay: 0s;
  animation-timing-function: linear;
}
@keyframes shine {
  0% {
    background-position-x: 400%;
  }
  50% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: -400%;
  }
}
.welcome-page__hero__image-div {
  height: 100%;
  width: 200px;
  position: absolute;
  right: 200px;
  bottom: 0;
}
.welcome-page__hero__image {
  height: 90%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.welcome-page__hero__image-BG {
  height: 95%;
  position: absolute;
  right: -20%;
  bottom: 0;
  z-index: 2;
  opacity: 0.7;
}
.welcome-page__hero__text-large {
  display: block;
  text-align: left !important;
  width: 40%;
}
.welcome-page__hero__text-small {
  display: none;
}
.welcome-page__hero__form-element {
  width: 100%;
  height: 2.5em;
  position: relative;
}
.welcome-page__hero__explore-shops-div {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: #511989;
  padding: 5px 15px;
  border-bottom-right-radius: 5px;
}
.welcome-page__hero__main-div {
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: rgb(243, 243, 243);
  height: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 2em;
  position: relative;
}
.welcome-page__hero__main-div h1 {
  text-align: center;
  font-size: 1.8em;
  font-weight: 700;
  width: 50%;
  color: #511989;
  margin-bottom: 5px;
  text-align: left;
}
.welcome-page__hero__main-div p {
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
}
.welcome-page__hero__search-div {
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  height: 100px;
  border: 1px solid lightgrey;
  justify-content: center;
  align-items: center;
  position: relative;
}
.welcome-page__hero__search-div__image {
  position: absolute;
  z-index: 1;
  bottom: 0;
  height: 170px;
  left: 230px;
}
.welcome-page__hero__search-div__input {
  border: none;
  height: 2.5em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #511989;
  padding: 0 8em 0 1em;
}
.welcome-page__hero__search-div__input:focus {
  outline: 2px solid #842fd9;
}
.welcome-page__hero__search-div__button {
  border: none;
  outline: none;
  height: 2.5em;
  width: 20%;
  right: 0;
  z-index: 1;
  position: absolute;
  border-radius: 5px;
  color: #ffffff;
  background-color: #511989;
  font-weight: 700;
  letter-spacing: 1px;
}
.welcome-page__hero__form {
  width: 600px;
  background-color: white;
  height: 2.5em;
  z-index: 2;
  border-radius: 5px;
}
.welcome-page__hero__search-box {
  border: none;
  width: 100%;
  height: 2.5em;
  padding-left: 1em;
  padding-right: 1em;
  border-start-start-radius: 5px;
}

@media (max-width: 1000px) {
  .welcome-page__hero {
    width: 100%;
    height: auto;
    display: none;
  }
  .welcome-page__hero__text-large {
    display: none;
  }
  .welcome-page__hero__text-small {
    display: block;
  }
  .welcome-page__hero__main-div {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
  }
  .welcome-page__hero__main-div h1 {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .welcome-page__hero__main-div p {
    width: 100%;
    font-size: 0.9em;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .welcome-page__hero__search-div {
    height: 90px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .welcome-page__hero__search-div__image {
    display: none;
  }
  .welcome-page__hero__search-div__input {
    border: none;
    height: 2.5em;
    width: 80%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #511989;
    padding-left: 1em;
    padding-right: 1em;
  }
  .welcome-page__hero__search-div__input:focus {
    outline: 2px solid #842fd9;
  }
  .welcome-page__hero__search-div__button {
    border: none;
    outline: none;
    height: 2.5em;
    width: 20%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #ffffff;
    background-color: #511989;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .welcome-page__hero__form {
    width: 600px;
    background-color: white;
    height: 2.5em;
    z-index: 2;
    border-radius: 5px;
  }
  .welcome-page__hero__search-box {
    border: none;
    width: 100%;
    height: 2.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-start-start-radius: 5px;
  }
}
@media (max-width: 430px) {
  .welcome-page__hero {
    width: 100%;
    height: auto;
    display: none;
  }
  .welcome-page__hero__text-large {
    display: none;
  }
  .welcome-page__hero__text-small {
    display: block;
  }
  .welcome-page__hero__main-div {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
  }
  .welcome-page__hero__main-div h1 {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .welcome-page__hero__main-div p {
    width: 100%;
    font-size: 0.9em;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .welcome-page__hero__search-div {
    height: 90px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .welcome-page__hero__search-div__image {
    display: none;
  }
  .welcome-page__hero__search-div__input {
    border: none;
    height: 2.5em;
    width: 80%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #511989;
    padding-left: 1em;
    padding-right: 1em;
  }
  .welcome-page__hero__search-div__input:focus {
    outline: 2px solid #842fd9;
  }
  .welcome-page__hero__search-div__button {
    border: none;
    outline: none;
    height: 2.5em;
    width: 20%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #ffffff;
    background-color: #511989;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .welcome-page__hero__form {
    width: 600px;
    background-color: white;
    height: 2.5em;
    z-index: 2;
    border-radius: 5px;
  }
  .welcome-page__hero__search-box {
    border: none;
    width: 100%;
    height: 2.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-start-start-radius: 5px;
  }
}
.welcome-page__explore-shops {
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
}
.welcome-page__explore-shops__shop-outer-div {
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 8px;
  margin-bottom: 15px;
}
.welcome-page__explore-shops__main-div {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background-color: #f8f8f8;
  height: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 2.8em 10px 15px;
  position: relative;
}
.welcome-page__explore-shops__shop-div {
  height: 100%;
  width: 69vw;
  display: flex;
  gap: 10px;
  overflow-x: scroll;
}
.welcome-page__explore-shops__shop-div::-webkit-scrollbar {
  display: none;
}
.welcome-page__explore-shops-div {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: #511989;
  padding: 5px 15px;
  border-bottom-right-radius: 5px;
}
.welcome-page__explore-shops-div * {
  margin-bottom: 0;
}
.welcome-page__shop-card {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px;
  width: 180px;
  flex-shrink: 0;
  height: 200px;
  overflow: hidden;
  background-color: white;
  transition: background-color 0.4s;
}
.welcome-page__shop-card:hover {
  background-color: rgb(243, 243, 243);
}
.welcome-page__shop-card__image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.welcome-page__shop-card:hover .welcome-page__shop-card__button {
  bottom: 15px;
}
.welcome-page__shop-card__image-div {
  width: 100%;
  height: 120px;
  border: 1px solid rgb(243, 243, 243);
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 5px;
}
.welcome-page__shop-card__shop-name {
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #511989;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.welcome-page__shop-card__shop-description {
  font-size: 0.95em;
  line-height: 1.2;
  font-weight: 500;
  color: rgb(93, 93, 93);
  margin-bottom: 0;
}

@media (max-width: 430px) {
  .welcome-page__explore-shops {
    margin-bottom: 0;
    height: auto;
  }
  .welcome-page__explore-shops__shop-outer-div {
    margin-bottom: 0;
    height: unset;
  }
  .welcome-page__explore-shops__main-div {
    margin-bottom: 0;
    height: auto;
    padding: 2.4em 10px 10px;
  }
  .welcome-page__explore-shops__shop-div {
    height: 120px;
    width: 100%;
    gap: 5px;
  }
  .welcome-page__explore-shops-div {
    height: 1.8em;
    padding: 0 15px;
  }
  .welcome-page__explore-shops-div * {
    margin-bottom: 0;
    font-size: 0.9em;
  }
  .welcome-page__shop-card {
    padding: 5px;
    width: 155px;
    height: 100%;
  }
  .welcome-page__shop-card__shop-bio {
    display: none;
  }
  .welcome-page__shop-card:hover .welcome-page__shop-card__button {
    bottom: 5px;
  }
  .welcome-page__shop-card__button {
    padding: 1px 5px;
    font-size: 0.85em;
    font-weight: 500;
    bottom: 5px;
    transition: none;
    box-shadow: unset;
  }
  .welcome-page__shop-card__image-div {
    height: 80px;
    margin-bottom: 2px;
  }
  .welcome-page__shop-card__shop-name {
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 0.9em;
  }
  .welcome-page__shop-card__shop-description {
    font-size: 0.8em;
    line-height: 1;
    font-weight: 400;
    color: rgb(93, 93, 93);
    margin-bottom: 0;
    height: 4.8em;
    overflow: hidden;
  }
}
.welcome-page__form-div {
  margin-bottom: 30px;
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
}
.welcome-page__form {
  width: 100%;
  height: 100%;
  border: 1px solid #511989;
  border-radius: 8px;
  background-color: #d1b0f1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.welcome-page__input {
  width: 50%;
  border-radius: 5px;
  background-color: white;
  padding: 5px 10px;
  outline: none;
  height: 2.3em;
  border: 1px solid #511989;
}
.welcome-page__search-button {
  width: 120px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #511989;
  border: none;
  height: 2.3em;
}

@media (max-width: 1000px) {
  .welcome-page__form-div {
    padding: 0 10px;
  }
  .welcome-page__form {
    width: 100%;
    padding: 10px;
    gap: 5px;
  }
  .welcome-page__input {
    width: unset;
    flex: 1;
  }
  .welcome-page__search-button {
    width: 70px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #511989;
    border: none;
    height: 2.3em;
  }
}
@media (max-width: 430px) {
  .welcome-page__form-div {
    padding: 0 10px;
  }
  .welcome-page__form {
    width: 100%;
    padding: 10px;
    gap: 5px;
  }
  .welcome-page__input {
    width: unset;
    flex: 1;
  }
  .welcome-page__search-button {
    width: 70px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #511989;
    border: none;
    height: 2.3em;
  }
}
.welcome-page__filter-by-distance__caption {
  height: 100%;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid lightgrey;
}
.welcome-page__filter-by-distance__caption * {
  color: #511989;
  color: rgb(83, 83, 83);
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 700;
}
.welcome-page__filter-by-distance__div {
  border: 1px solid lightgrey;
  margin: 35px 130px;
  height: 3.2em;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
}
.welcome-page__filter-by-distance__div.alt {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
  padding-right: 30px;
  height: 50px;
  border-radius: 50px;
  background-color: #f7f1fc;
}
.welcome-page__filter-by-distance__div.alt .caption {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  min-width: 200px;
  background-color: #511989;
  border-radius: 50px;
}
.welcome-page__filter-by-distance__div.alt h5 {
  color: #ffffff;
  font-size: 1.1em;
  margin: 0;
}
.welcome-page__filter-by-distance__div input {
  width: 100%;
}
.welcome-page__filter-by-distance__div input {
  width: 100%;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.welcome-page__filter-by-distance__div input::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 50%;
  background: #511989;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.welcome-page__filter-by-distance__div input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #511989;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.welcome-page__filter-by-distance__info span {
  font-weight: 700;
  color: #511989;
}
.welcome-page__filter-by-distance__input-div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 20px;
}
.welcome-page__filter-by-distance__input-range-bar {
  width: 100%;
  height: 5px;
  display: flex;
  position: relative;
  align-items: center;
  background: #ddd;
  border-radius: 5px;
}
.welcome-page__filter-by-distance__input-range-bar.alt {
  background-color: white;
}
.welcome-page__filter-by-distance__thumb {
  z-index: 2;
}
.welcome-page__filter-by-distance__trail-line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #511989;
  border-radius: 5px;
  z-index: 1;
}
@media (max-width: 1000px) {
  .welcome-page__filter-by-distance__div {
    margin: 20px 0;
    padding: 1em;
  }
  .welcome-page__filter-by-distance__div input {
    width: 100%;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .welcome-page__filter-by-distance__div input::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border: none;
    border-radius: 50%;
    background: #511989;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
  .welcome-page__filter-by-distance__div input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #511989;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
  .welcome-page__filter-by-distance__info span {
    font-weight: 700;
    color: #511989;
  }
  .welcome-page__filter-by-distance__input-div {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;
  }
  .welcome-page__filter-by-distance__input-range-bar {
    width: 100%;
    height: 5px;
    display: flex;
    position: relative;
    align-items: center;
    background: #ddd;
    border-radius: 5px;
  }
  .welcome-page__filter-by-distance__thumb {
    z-index: 2;
  }
  .welcome-page__filter-by-distance__trail-line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #511989;
    border-radius: 5px;
    z-index: 1;
  }
}
@media (max-width: 430px) {
  .welcome-page__filter-by-distance__caption {
    height: 100%;
    width: auto;
    justify-content: flex-start;
    border-right: none;
  }
  .welcome-page__filter-by-distance__caption * {
    font-size: 0.95em;
    font-weight: 600;
  }
  .welcome-page__filter-by-distance__input-div {
    padding: 0;
  }
  .welcome-page__filter-by-distance__div {
    margin: 35px 10px;
    height: auto;
    border-radius: 8px;
    flex-direction: column;
    padding: 15px 10px;
    gap: 15px;
  }
  .welcome-page__filter-by-distance__div.alt {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0;
    padding-right: 30px;
    height: 50px;
    border-radius: 50px;
    background-color: #f7f1fc;
  }
  .welcome-page__filter-by-distance__div.alt .caption {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    min-width: 200px;
    background-color: #511989;
    border-radius: 50px;
  }
  .welcome-page__filter-by-distance__div.alt h5 {
    color: #ffffff;
    font-size: 1.1em;
    margin: 0;
  }
  .welcome-page__filter-by-distance__div input {
    width: 100%;
  }
  .welcome-page__filter-by-distance__div input {
    width: 100%;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .welcome-page__filter-by-distance__div input::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border: none;
    border-radius: 50%;
    background: #511989;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
  .welcome-page__filter-by-distance__div input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #511989;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
  .button-container {
    display: flex;
   
    justify-content: flex-start; /* Aligns the button to the left */
  }
  
  .button-rounded {
    padding: 10px 20px;
    background-color: #511989; /* Change to your preferred color */
    color: black;
    border: none;
    border-radius: 20px; /* Rounded edges */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-rounded:hover {
    background-color: #0056b3; /* Change to your preferred hover color */
  }
}/*# sourceMappingURL=main.css.map */