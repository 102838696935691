.product-detail {
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    padding: 15px;
    background-color: $primaryMidGreyColour;

    &__title {
        color: $secondaryColour;
        margin-bottom: 2px;
        font-weight: 700;
    }

    &__name {
        font-size: 1.4em;
        font-weight: 700;
        color:rgb(107, 107, 107);
    }

    &__price, &__condition {
        font-size: 1.3em;
        font-weight: 700;
        color:rgb(107, 107, 107);
    }

    &__detail {
        font-weight: 500;
        line-height: 1.3;
        color:rgb(107, 107, 107);
        margin: 0;
    }

    & [class$='div'] {
        border-bottom: 1px solid $primaryGreyColour;
        margin-top: 8px;

        &:last-child {
            border-bottom: none;
        }

        &:first-child {
            margin-top: 0;
        }
    } 
}

.product-location {
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    padding: 15px;
    background-color: $primaryMidGreyColour;
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
        color: $secondaryColour;
        margin-bottom: 2px;
        font-weight: 700;
    }

    &__name, &__distance {
        font-size: 1.2em;
        font-weight: 700;
        color:rgb(107, 107, 107);
    }

    &__name {
        text-transform: capitalize;
    }

    &__miles-away {
        margin-bottom: 0;
    }

    &__distance {
        margin: 0;
    }

    &__distance span {
        font-size: 1.3em;
        font-weight: 700;
        color:rgb(107, 107, 107);
    }

    &__distance p {
        color:rgb(107, 107, 107);
    }

    & [class$='div'] {
        border-bottom: 1px solid $primaryGreyColour;
        margin-top: 8px;

        &:last-child {
            border-bottom: none;
        }

        &:first-child {
            margin-top: 0;
        }
    } 
}

.vendor-details {
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    padding: 15px;
    background-color: $primaryMidGreyColour;
    margin-top: 20px;
    margin-bottom: 20px;

    &__info-div {
      align-content: center;
      height: 70px;
    }

    &__profile-image-div {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 15px;
        position: relative;
        border: 1px solid $primaryGreyColour;

        & img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__profile-title {
        color: $secondaryColour;
        margin-bottom: 4px;
        font-weight: 700;
    }

    &__profile-name {
        font-size: 1.2em;
        font-weight: 700;
        color:rgb(107, 107, 107);
        margin: 0;
    }

    &__online-indicator {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        position: absolute;
        background-color: $onlineColour;
        border: 1px solid $secondaryColour;
        left: 3px;
        top: 6px;
    }

    & a:hover {
        text-decoration: underline;
    }

    &__button-div {
        display: block;
        border-radius: 5px;
        background-color: $secondaryColour;
        @include paddingVertical(8px);
        margin-top: 8px;
        cursor: pointer;

        & h5 {
           color: $lightFont;
           margin: 0;
           font-weight: 700;
           text-align: center;
           letter-spacing: 0.8px;
        }
    }
}

.product-detail-loading {
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    padding: 15px;
    background-color: $primaryMidGreyColour;
    margin-bottom: 20px;

    &__vendor-image {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: $primaryGreyColour;
        margin-right: 15px;
    }

    &__vendor-info-div {
        flex: 1;
    }

    &__inner-div {
        margin-bottom: 20px;

        &--alt {
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__Loader-top {
        border-radius: 2.5px;
        height: 18px;
        width: 40%;
        background-color: $primaryGreyColour;
        margin-bottom: 8px;
    }

    &__Loader-bottom {
        border-radius: 2.5px;
        height: 18px;
        width: 100%;
        background-color: $primaryGreyColour;
        margin-bottom: 8px;

        &--alt {
            width: 60%;
        }
    }

    & .loading {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background-image: linear-gradient(to left , transparent , rgba(255,255,255,0.5), transparent);
            animation: loading 1.5s infinite;
        }
    }
}

.views-box {
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    padding: 15px;
    background-color: $primaryMidGreyColour;
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
    align-items: center;

    &__icon-div {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 2px solid $primaryGreyColour;
        display: flex;
        justify-content: center;
        align-items: center;

        & i {
            font-size: 1.1em;
        }
    }

    & h5 {
        margin: 0;
        font-weight: 700;
    }
}

.buttons-box-loading {
    width: 100%;
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0;


    &__button {
        height: 100%;
        flex: 1;
        border-radius: 5px;
        background-color: $primaryGreyColour;
        margin-right: 12px;

        &:last-child {
        margin-right: 0;
        }

        &.loading {
            position: relative;
            overflow: hidden;
    
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateX(-100%);
                background-image: linear-gradient(to left , transparent , rgba(255,255,255,0.5), transparent);
                animation: loading 1.5s infinite;
            }
        }
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

@include mobileScreen {
    
}