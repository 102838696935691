.item-image-box {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    background-color: $primaryMidGreyColour;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    gap: 15px;

    &__top-div {
        border-radius: 5px;
        background-color: #FFFFFF;
        width: 100%;
        height: 520px;
        margin-bottom: 0;
        position: relative;
        padding: 1.5em 2em;
    }

    &__bottom-div {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        gap: 5px;
        flex: 1;

        &__image-div {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            background-color: #FFFFFF;
            cursor: pointer;
            border: 1px solid #FFFFFF;
            transition: border 0.3s;
            overflow: hidden;

            & img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &:hover {
                border: 1px solid $secondaryColour;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}

.item-image-box-loading {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    background-color: $primaryMidGreyColour;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    gap: 15px;

    &__top-div {
        border-radius: 5px;
        background-color: #FFFFFF;
        width: 100%;
        height: 520px;
        margin-bottom: 0px;
        padding: 1.5em 10em;

        & div {
            height: 100%;
            border-radius: 5px;
            background-color: $primaryGreyColour;
        }
    }

    &__bottom-div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
        gap: 5px;

        & div {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            background-color: $primaryGreyColour;
            gap: 15px;
        }
       } 
       
       & .loading {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background-image: linear-gradient(to left , transparent , rgba(255,255,255,0.5), transparent);
            animation: loading 1.5s infinite;
        }
    }
}

@include mobileScreen {
    .item-image-box {
        display: block;

        &__top-div {
            width: 100%;
            height: 320px;
            padding: 0.5em;
            margin-bottom: 15px;
        }
    
        &__bottom-div {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex: unset;
            height: 60px;
            gap: 8px;
    
            &__image-div {
                width: 60px;
                height: 60px;
                margin-right: 0;
            }
        }
    }

    .item-image-box-loading {
        width: 100%;
        min-height: 100px;
        border-radius: 5px;
        background-color: $primaryMidGreyColour;
        padding: 15px;
        margin-bottom: 20px;
        display: block;
        gap: unset;
    
        &__top-div {
            border-radius: 5px;
            background-color: #FFFFFF;
            width: 100%;
            height: 320px;
            margin-bottom: 15px;
            padding: 10px;
    
            & div {
                height: 300px;
                border-radius: 5px;
                background-color: $primaryGreyColour;
            }
        }
    
        &__bottom-div {
            height: 60px;
            flex-direction: row;
            flex: unset;
            gap: unset;
    
            & div {
                width: 160px;
                height: 60px;
            }
           } 
           
           & .loading {
            position: relative;
            overflow: hidden;
    
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateX(-100%);
                background-image: linear-gradient(to left , transparent , rgba(255,255,255,0.5), transparent);
                animation: loading 1.5s infinite;
            }
        }
    }
}