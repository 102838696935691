.welcome-page {

    &__explore-shops {
        width: 100%;
        flex: 1;
        margin-bottom: 20px;

        &__shop-outer-div {
            width: 100%;
            // height: 2ppx;
            border-radius: 4px;
            border: 1px solid $primaryGreyColour;
            padding: 8px;
            margin-bottom: 15px;
        }
    
        &__main-div {
            display: flex;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid $primaryGreyColour;
            background-color: lighten($primaryMidGreyColour, 2%);
            height: 100%;
            margin-bottom: 15px;
            overflow: hidden;
            padding: 2.8em 10px 15px;
            position: relative; 
        }
    
        &__shop-div {
            height: 100%;
            width: 69vw;
            display: flex;
            gap: 10px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    
        &-div {
            display: inline-flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            color: $lightFont;
            background-color: $secondaryColour;
            padding: 5px 15px;
            border-bottom-right-radius: 5px;
    
            & * {
                margin-bottom: 0;
            }
        }
    }

    &__shop-card {
        display: flex;
        flex-direction: column;
        border: 1px solid $primaryGreyColour;
        border-radius: 5px;
        padding: 5px;
        width: 180px;
        flex-shrink: 0;
        height: 200px;
        overflow: hidden;
        background-color: white;
        transition: background-color 0.4s;

        &:hover {
            background-color: $primaryMidGreyColour;
        }


        &__image {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:hover .welcome-page__shop-card__button{
            bottom: 15px;
        }

        // &__button {
        //     background-color: $secondaryColour;
        //     border: none;
        //     outline: none;
        //     border-radius: 50px;
        //     color: $lightFont;
        //     padding: 1px 5px;
        //     font-size: 0.95em;
        //     font-weight: 500;
        //     width: 100px;
        //     align-self: center;
        //     position: absolute;
        //     bottom: -30px;
        //     transition: bottom 0.3s;
        //     box-shadow: 5px 5px 10px white,-5px -5px 10px white;
        // }

        &__image-div {
            width: 100%;
            height: 120px;
            border: 1px solid $primaryMidGreyColour;
            overflow: hidden;
            border-radius: 4px;
            margin-bottom: 5px;
        }

        &__shop-name {
            margin-bottom: 5px;
            text-transform: capitalize;
            color: $secondaryColour;
            font-weight: 700;
            text-align: center;
            width: 100%;
        }

        &__shop-description {
            font-size: 0.95em;
            line-height: 1.2;
            font-weight: 500;
            color:rgb(93, 93, 93);
            margin-bottom: 0;
        }
    }

}

@include mobileScreen {
    .welcome-page {

        &__explore-shops {
            // width: 100%;
            // flex: 1;
            // margin-bottom: 20px;
            margin-bottom: 0;
            height: auto;

            &__shop-outer-div {
                margin-bottom: 0;
                height: unset;
            }
        
            &__main-div {
                // display: flex;
                // justify-content: center;
                // border-radius: 5px;
                // border: 1px solid $primaryGreyColour;
                // background-color: lighten($primaryMidGreyColour, 2%);
                // height: 200px;
                // margin-bottom: 15px;
                // overflow: hidden;
                margin-bottom: 0;
                height: auto;
                padding: 2.4em 10px 10px;
                // position: relative; 
            }
        
            &__shop-div {
                height: 120px;
                width: 100%;
                gap: 5px;
            }
        
            &-div {
                height: 1.8em;
                padding: 0 15px;
        
                & * {
                    margin-bottom: 0;
                    font-size: 0.9em;
                }
            }
        }
    
        &__shop-card {
            padding: 5px;
            width: 155px;
            height: 100%;

            &__shop-bio{
                display: none;
            }

            &:hover .welcome-page__shop-card__button{
                bottom: 5px;
            }    
    
            &__button {
                padding: 1px 5px;
                font-size: 0.85em;
                font-weight: 500;
                bottom: 5px;
                transition: none;
                box-shadow: unset;
            }
    
            &__image-div {
                height: 80px;
                margin-bottom: 2px;
            }
    
            &__shop-name {
                margin-bottom: 2px;
                font-weight: 700;
                font-size: 0.9em;
            }
    
            &__shop-description {
                font-size: 0.8em;
                line-height: 1;
                font-weight: 400;
                color:rgb(93, 93, 93);
                margin-bottom: 0;
                // background-color: aqua;
                height: 4.8em;
                overflow: hidden;
            }
        }
    
    }
}
