* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $darkFont;
}

ul li {
    list-style: none;
}

a {
    color: $darkFont;
    text-decoration: none;
}
