.wish-list {
    &__item {
        width: 100%;
        height: 150px;
        position: relative;
        overflow: hidden;
        margin-bottom: 8px;
        
        &:last-child {
            margin-bottom: 0;
        }

        & a{
            display: flex;
            width: 100%;
            height: 100%;
            border: 1px solid $primaryMidGreyColour;
            border-radius: 5px;
            padding: 10px;
            padding-right: 40px;
            transition: background-color 0.3s;

            &:hover {
                background-color: $primaryMidGreyColour;
            }
        }

        &:hover {
            background-color: $primaryMidGreyColour;
        }
    }

    &__image-div {
        width: 220px;
        height: 100%;
        border-radius: 5px;
        border: 1px solid $primaryMidGreyColour;
        background-color: $primaryGreyColour;
        margin-right: 10px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        & img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-fit: cover;
        }
    }

    &__info-div {
        margin-right: 10px;
        flex: 1;
    }

    &__button-div {
        height: 25px;
        width: 25px;
        position: absolute;
        z-index: 3;
        top: 10px;
        right: 10px;

        & button {
            height: 100%;
            width: 100%;
            margin-bottom: 8px;
            outline: none;
            border: none;
            border-radius: 5px;
            color: #ffffff;
            background-color: rgb(195, 80, 80);
        }
    }

    &__empty-div {
        width: 50%;
        min-height: 250px;
        border-radius: 5px;
        margin: 50px auto 30px;
        background-color: rgb(232, 232, 232);
        border: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3em;

        & * {
            color: rgb(158, 158, 158);
            text-align: center;
        }

        & i {
            font-size: 5em;
        }

        & h6 {
            margin: 0;
        }

        & a {
            padding: 0.5em 1em;
            border-radius: 5px;
            background-color: rgb(219, 219, 219);
            transition: background-color 0.3s;

            &:hover {
                background-color: rgb(203, 203, 203);
            }
        }
    }

    &__remove-all-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__remove-all-button {
        padding: 0.25em 1em;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: $secondaryGreyColour;
        color: #fff;
        margin-top: 15px;
        font-weight: 600;
        letter-spacing: 0.8px;
    }
}

@include mobileScreen {
    .wish-list {
        &__empty-div {
            width: 90%;
            min-height: 250px;
            margin: 30px auto;
            padding: 1.5em;

            & i {
                font-size: 5em;
                margin-bottom: 10px;
            }
        }
    

    &__item {
        width: 100%;
        height: 150px;
        position: relative;
        overflow: hidden;
        margin-bottom: 8px;
        
        &:last-child {
            margin-bottom: 0;
        }

        & a{
            display: flex;
            width: 100%;
            height: 100%;
            border: 1px solid $primaryMidGreyColour;
            border-radius: 5px;
            padding: 5px;
            padding-right: 20px;
            transition: background-color 0.3s;

            &:hover {
                background-color: $primaryMidGreyColour;
            }
        }

        &:hover {
            background-color: $primaryMidGreyColour;
        }
    }

    &__image-div {
        width: 120px;
        height: 100%;
        border-radius: 5px;
        border: 1px solid $primaryMidGreyColour;
        background-color: $primaryGreyColour;
        margin-right: 10px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        & img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-fit: cover;
        }
    }

    &__info-div {
        margin-right: 10px;
        flex: 1;
    }

    &__button-div {
        height: 20px;
        width: 20px;
        position: absolute;
        z-index: 3;
        top: 5px;
        right: 5px;

        & button {
            height: 100%;
            width: 100%;
            margin-bottom: 8px;
            outline: none;
            border: none;
            border-radius: 5px;
            color: #ffffff;
            background-color: rgb(195, 80, 80);
            font-size: 0.8em;
        }
    }

    &__empty-div {
        width: 90%;
        min-height: 250px;
        border-radius: 5px;
        margin: 30px auto;
        background-color: rgb(232, 232, 232);
        border: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5em;

        & * {
            color: rgb(158, 158, 158);
            text-align: center;
        }

        & i {
            font-size: 5em;
            margin-bottom: 10px;
        }

        & h6 {
            margin: 0;
        }

        & a {
            padding: 0.5em 1em;
            border-radius: 5px;
            background-color: rgb(219, 219, 219);
            transition: background-color 0.3s;

            &:hover {
                background-color: rgb(203, 203, 203);
            }
        }
    }
  }
}
