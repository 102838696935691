.request {
    &__hero-section-outer-div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
        
    }

    &__hero-section-div {
        border: 2px solid $primaryGreyColour;
        background-color: $primaryMidGreyColour;
        border-radius: 4px;
        width: 70%;
        padding: 15px;
        display: flex;
        column-gap: 15px;
        justify-content: center;
        align-items: center;
        // flex-direction: column;
    }
    
    &__item-card {
        border: 1px solid $primaryGreyColour;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        background-color: #fff;
        width: 100%;
        height: auto;
        transition: background-color 0.2s;

        &:hover {
            background-color: $primaryMidGreyColour;
        }

        &__info-div {
            & h5 {
                height: 2.5em;
                overflow: hidden;
                margin-bottom: 2px;
                text-align: left;
            }
            & h6 {
                margin-bottom: 0;
                color: darkgrey;
                text-align: left;
            }
        }
    }

    &__item-card__icon-div {
        border-radius: 4px;
        height: 35px;
        width: 35px;
        margin-bottom: 5px;
        background-color: lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__search-bar-div {
        height: 2.5em;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 15px;
    }

    &__search-bar {
        height: 100%;
        width: 45%;
        border: 1px solid rgb(126, 126, 126);
        border-radius: 4px;
        padding: 0 15px;

        &-button {
            height: 2.3em;
            padding: 5px 20px; 
            color: $lightFont;
            border-radius: 5px;
            background-color: $secondaryColour;
            border: none;
        }
    }

    &__single-item-item-type {
        width: 200px;
        height: 200px;
        border-radius: 4px;
        background-color: $primaryMidGreyColour;
        border: 1px solid $primaryGreyColour;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        & i {
            font-size: 4em;
        }
    }

    &__single-item-info-div {
        display: flex;
        height: 200px;
        gap: 20px;
    }

    &__single-item {
        &-inner-info-div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__vendor-info-div {
            display: flex;
            gap: 10px;

            & button {
                border: none;
                background-color: transparent;
                outline: none;
                font-weight: 700;
                line-height: 1;

                &:hover {
                    text-decoration: underline;
                }

            }
        }

        &__vendor-image-div {
            background-color: lightgrey;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            overflow: hidden;

            & img {
                width: 25px;
                height: 25px;
                object-fit: cover;
            }
        }

        &__inner-info-div-top {
            display: flex;
            flex-direction: column;
            gap: 5px;

            & h3, & h5, & p, & h6 {
                margin: 0;
            }
        }

        &__inner-info-div-bottom {
            height: 2.2em;

            &__button {
                border-radius: 4px;
                color: $lightFont;
                background-color: $secondaryColour;
                border: none;
                outline: none;
                height: 100%;
                padding: 0 35px;
                font-weight: 700;
                letter-spacing: 1px;

                &.delete-button {
                    background-color: $dangerRed;
                }
            }
        }
    }

    &__section {
        width: 100%;
        height: auto;
        border-radius: 8px;
        background-color: $primaryMidGreyColour;
        border: 1px solid $primaryGreyColour;
        margin-bottom: 20px;
        padding: 15px;

        &__text {
            // color: $secondaryColour;
            flex: 3.5;
            text-align: center;
            font-weight: 700;
            width: 60%;
            text-align: left;
        }

        &__button {
            flex: 1;
            color: $lightFont;
            border-radius: 4px;
            background-color: $secondaryColour;
            padding: 5px 20px;
            width: 100%;
            border: none;
            outline: none;

            &.home {
                width: 180px;
                width: 100%;
                padding: 5px 10px;
            }
        }
    }
}

.request-box {
    &__outer-div {
        display: flex;
        column-gap: 20px;
        height: auto;
        width: 100%;
        padding: 0 150px;
        margin-bottom: 20px;
    }

    &__item-div {
        height: auto;
        padding: 15px;
        flex: 1;
        border-radius: 4px;
        border: 2px solid $primaryGreyColour;
    }

    &__title {
        font-weight: 700;
        color: $secondaryColour;
    }

    &__text {
        color: darkgray;
        width: 90%;
    }

    &__button {
        border: none;
        outline: none;
        border-radius: 4px;
        padding: 5px 20px;
        background-color: $secondaryColour;
        color: $lightFont;
        font-size: 0.9em;
        font-weight: 700;
        letter-spacing: 0.5px;
    }
}

@include mobileScreen {
    .request {
        &__item-card {
            &__info-div {
                & h5 {
                    height: 1.3em;
                    margin-bottom: 2px;
                }
            }

            &__icon-div {
                height: 50px;
                width: 50px;

                & i {
                    font-size: 1.2em;
                }
            }
        }

        &__search-bar {
            width: 80%;
        }

        &__single-item-info-div {
            flex-direction: column;
            height: auto;
            gap: 20px;
            margin-top: 30px;
        }

        &__single-item-item-type {
            width: 80px;
            height: 80px;
            gap: 2px;
            align-self: center;
    
            & i {
                font-size: 2em;
            }

            & h5 {
                font-size: 0.8em;
                margin: 0;
            }
        }

        &__single-item {
            &-inner-info-div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
    
            &__vendor-info-div {
                justify-content: center;
                margin-bottom: 10px;
    
                & button {
                    border: none;
                    background-color: transparent;
                    outline: none;
                    font-weight: 700;
                    line-height: 1;
    
                    &:hover {
                        text-decoration: underline;
                    }
    
                }
            }
    
            &__vendor-image-div {
                background-color: lightgrey;
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
    
            &__inner-info-div-top {
                display: flex;
                flex-direction: column;
                gap: 5px;
    
                & h3, & h5, & p, & h6 {
                    text-align: center;
                }
            }
    
            &__inner-info-div-bottom {
                height: 2.2em;
                margin-bottom: 50px;
    
                &__button {
                    border-radius: 4px;
                      width: 100%;
    
                    &.delete-button {
                        background-color: $dangerRed;
                    }
                }
            }
        }
    }

    .request-box {
        &__outer-div {
            gap: 15px;
            row-gap: 15px;
            height: auto;
            padding: 0 15px;
            margin-bottom: 20px;

            display: flex;
            flex-wrap: nowrap; /* Prevents wrapping of the flex items */
            width: max-content; /* Ensures the inner container width exceeds the outer container */
        }

        &__outermost-div {
            display: flex;
            overflow-x: auto; /* Enables horizontal scrolling */
            white-space: nowrap; /* Prevents line breaks */
            width: 100%; /* Adjust the width as needed */
        }
    
        &__item-div {
            height: auto;
            padding: 15px;
            flex: 0 0 auto; /* Prevents cards from shrinking */
            width: 350px;
            border-radius: 4px;
            border: 2px solid $primaryGreyColour;
            text-wrap: wrap;

            & p {
                line-height: 1.3;
            }
        }
    
        &__title {
            font-weight: 700;
            color: $secondaryColour;
        }
    
        &__text {
            color: darkgray;
            width: 90%;
        }
    
        &__button {
            border: none;
            outline: none;
            border-radius: 4px;
            padding: 5px 20px;
            background-color: $secondaryColour;
            color: $lightFont;
            font-size: 0.9em;
            font-weight: 700;
            letter-spacing: 0.5px;
        }
    }



}