.find-closer-items {
    &__button {
        width: 100%;
        height: auto;
        border: 1px solid $primaryGreyColour;
        padding: 0.5em 1em;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        background-color: transparent;
        transition: background-color 0.4s;

        &:hover {
            background-color: $primaryMidGreyColour;
        }

        & h6 {
            margin: 0;
        }
    }

    &__icon-div{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid $primaryGreyColour;
        background-color: $primaryMidGreyColour;
        display: flex;
        justify-content: center;
        align-items: center;
        
        & i {
            font-size: 1.3em;
            color: $secondaryColour;
        }
    }
}

.mile-range {
    &__div {
        border: 1px solid $primaryGreyColour;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 1em;

        & input {
            width: 100%;
        }

        & input{
            width: 100%;
            background: none;
            pointer-events: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        & input::-moz-range-thumb{
            height: 20px;
            width: 20px;
            border: none;
            border-radius: 50%;
            background: $secondaryColour;
            pointer-events: auto;
            -moz-appearance: none;
            box-shadow: 0 0 6px rgba(0,0,0,0.05);
            z-index: 2;
        }

        & input::-webkit-slider-thumb{
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: $secondaryColour;
            pointer-events: auto;
            -webkit-appearance: none;
            box-shadow: 0 0 6px rgba(0,0,0,0.05);
            z-index: 2;
        }

    }
    &__info span{
        font-weight: 700;
        color: $secondaryColour;
    }

    &__input-div{
        width: 100%;
        height: 18px;
        // background-color: lightblue;
        display: flex;
        align-items: center;
    }

    &__input-range-bar {
        width: 100%;
        height: 5px;
        display: flex;
        position: relative;
        align-items: center;
        background: #ddd;
        border-radius: 5px;
    }

    &__thumb {
        z-index: 2;
    }

    &__trail-line {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $secondaryColour;
        border-radius: 5px;
        z-index: 1;
    }
}