.user-detail-box {
    border-radius: 5px;
    border: 1px solid $primaryGreyColour;
    padding: 15px;
    background-color: $primaryMidGreyColour;
    margin-bottom: 20px;

    &__image-div {
        border: 1px solid $primaryGreyColour;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        background-color: #fff;
        position: relative;
        margin: 0 auto 8px;
        justify-content: center;

        & img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }
    }

    &__online-indicator {
        position: absolute;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: $onlineColour;
        left: 8px;
        top: 8px;
        border: 1px solid $primaryGreyColour;
    }

    &__user-name {
        text-align: center;
        font-weight: 700;
    }

    &__user-info-div {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $primaryGreyColour;
        @include paddingVertical(0.5em);

        &--bio {
            display: block;
            justify-content: unset;

            & h6 {
                margin-bottom: 5px;
            }
        }
    }

    &__user-info-title, &__user-info-value {
        font-weight: 700;
        color: $darkFont;
        margin: 0;
    }

    &__user-rating-div {
        display: flex;
        gap: 3px;

        & i {
            color: rgba(0, 0, 0, 0.163);
            position: relative;
            overflow: hidden;
            font-size: 0.9em;

            &.active {
              color: $secondaryColour;
            }
        }
    }

    &__user-info-value--bio {
        font-weight: 400;
        line-height: 1.2;
        font-size: 0.9em; 
    }

    &__start-chat-div {
        border-radius: 5px;
        background-color: $secondaryColour;
        border: none;
        outline: none;
        width: 100%;
        @include paddingVertical(8px);
        margin-top: 8px;
        margin-bottom: 10px;
        cursor: pointer;

        & h5 {
           color: $lightFont;
           margin: 0;
           font-weight: 700;
           text-align: center;
           letter-spacing: 0.8px;
        }
    }

    &__edit-user-button {
        width: 100%;
        padding: 5px 10px;
        color: #fff;
        border-radius: 5px;
        background-color: $secondaryColour;
        @include paddingVertical(8px);
        margin-top: 8px;
        margin-bottom: 10px;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        & i {
           color: $lightFont;
           margin-right: 10px;
           font-weight: 700;
           text-align: center;
           letter-spacing: 0.8px;
        }
    }

    &__delete-user-button {
        width: 100%;
        padding: 5px 10px;
        color: #fff;
        border-radius: 5px;
        background-color: $dangerRed;
        @include paddingVertical(8px);
        margin-top: 8px;
        margin-bottom: 10px;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        & i {
           color: $lightFont;
           margin-right: 10px;
           font-weight: 700;
           text-align: center;
           letter-spacing: 0.8px;
        }
    }



    &__report-user-button {
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 4px;
        padding: 5px 10px;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(116, 116, 116, 0.167); 
        }
    }

    &__report-user-icon {
        height: 1em;
        width: 1em;
        // background-color: grey;
        color: grey;
        // border-radius: 50%;
        margin-right: 10px;    
    }

    &__report-user {
        margin: 0;
        line-height: 1;
        font-size: 0.9em;
    }

    &__buttons-box__discard-button {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: $dangerRed;
        border: none;
        color: white;
        font-weight: 600;
        cursor: pointer;

        &.button-disabled {
            cursor: not-allowed;
            background-color: lighten($dangerRed, 30%);
        }

    }

    &__delete-account-modal__input {
        height: 2.3em;
        width: 80%;
        border-radius: 4px;
        padding: 0 10px;
        margin-top: 15px;

        &-or {
            margin: 0;
        }
    }

    &__delete-account-modal__google-delete {
        height: 2.3em;
        width: 80%;
        border-radius: 4px;
        padding: 0 10px;
        border: none;
        outline: none;
        background-color: orangered;
        color: $lightFont;
    }

    &__rate-user-button {
        display: flex;
        width: 100%;
        height: auto;
        border: 1px solid rgba(116, 116, 116, 0.167);
        border-radius: 4px;
        align-items: center;
        padding: 5px 8px;
        gap: 10px;
        margin-bottom: 20px;

        & * {
            margin: 0;
        }

        &:hover {
            background-color: rgba(67, 67, 67, 0.1);
        }
    }
}

.edit-profile {
  &__form-image-div {
    height: 100%;
    width: 150px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $primaryGreyColour;

    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
  }

  &__edit-image-buttons-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
  }

  &__edit-image-div {
    height: 150px;
    display: flex;
    gap: 15px;
  }

  &__input {
    display: none;
  }

  &__update-image-button, &__remove-image-button {
    height: 2.2em;
    width: 170px;
    border-radius: 4px;
    background-color: $primaryGreyColour;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    // color: #fff;

    &:hover {
        background-color: darken($primaryGreyColour, 5%);
    }

    & * {
        margin: 0;
    }
  }

  &__remove-image-button {
    background-color: $dangerRed;
    color: #fff;

    &:hover {
        background-color: darken($dangerRed, 5%);
    }
    
    & i {
        color: #fff;
    }
  }
}

@include mobileScreen{
    .user-detail-box {
        background-color: lighten($primaryColour, 20%);
        padding: 0.5em;
    
        &__user-name {
            font-size: 1.6em;
        }

        &__user-rating-div {
        display: flex;
        gap: 1px;
        margin-bottom: 10px;

        & i {
            color: #ffffff5c;
            font-size: 12px;

            &.active {
              color: yellow;
            }
        }
    }
    
       &__user-info-outer-div {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            margin-top: 10px;

            & .user-detail-box__user-info-div{
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                flex: 1;  
                height: 80px;
                border-radius: 5px;
                padding: 0.5em 0.3em;
                background-color: $secondaryColour;

                & .user-detail-box__user-info-title {
                    color: $lightFont;
                }

                & .user-detail-box__user-info-value {
                    color: $lightFont;
                    font-size: 1.8em;
                }
            }
       }
    
        &__user-info-title, &__user-info-value {
            font-weight: 700;
            color: $secondaryColour;
            margin: 0;
        }

        &__user-info-div--bio {
            border: 1px solid $secondaryColour;
            border-radius: 5px;
            background-color: lighten($primaryColour, 20%);
            padding: 0.5em;
            margin: 15px 0;
        }
    
        &__user-info-value--bio {
            font-weight: 400;
            line-height: 1.4;
            font-size: 0.9em; 
        }
    
        &__start-chat-div {
            display: block;
            border-radius: 5px;
            background-color: $secondaryColour;
            @include paddingVertical(8px);
            margin-top: 8px;
            margin-bottom: 10px;
            cursor: pointer;
    
            & h5 {
               color: $lightFont;
               margin: 0;
               font-weight: 700;
               text-align: center;
               letter-spacing: 0.8px;
            }
        }

        &__rate-user-button {
            padding: 10px 8px;
            gap: 10px;
            margin-bottom: 20px;
    
            & * {
                margin: 0;
            }
        }
    
        &__delete-account-modal__google-delete {
            height: 2.3em;
            width: 100%;
            font-size: 0.9em;
            padding: 0 2px;
            background-color: orangered;
            color: $lightFont;
        }

        &__delete-account-modal__heading-text {
            text-align: center;
            width: 100%;
        }

        &__delete-account-modal__input {
            width: 100%;
        }

        &__report-user-icon {
            height: 1em;
            width: 1em;

            border-radius: 50%;
            margin-right: 10px;    
        }
    
        &__report-user {
            margin: 0;
            line-height: 1;
            font-size: 0.9em;
        }
    }

    .edit-profile {
        &__form {
            width: 100%;
        }
      }
      
}