.categories-box {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    // border: 1px solid $primaryMidGreyColour; 
    overflow: hidden;
    margin-bottom: 30px;

    &__title {
        color: $darkFont;
        margin: 0;
        font-weight: 700;
    }

    &__title-div {
        border: 1px solid $primaryYellowColour;
        background-color: $primaryYellowColour;
        @include paddingHorizontal(1em);
        height: 50px;
    }

    &__lines-div {
        width: 1em;
        height: 0.8em;
        margin-right: 1em;
    }

    &__lines {
        width: 100%;
        height: 2px;
        background-color: $darkFont;
    }

    &__list {
        padding: 0;
        margin: 0;
        height: 350px;
        border: 1px solid $primaryGreyColour; 


        &-item {
            flex: 1;
            width: 100%;
            border-bottom: 1px solid #00000010;

            &-icon {
                font-size: 0.8em;
            }

            &:last-child {
                  border-bottom: none;
                }

            & a {
                height: 100%;
                justify-content: space-between;
                align-items: center;
                display: flex;
                width: 100%;
                height: 100%;
                font-weight: 600;
                transition: all 0.3s;
                @include paddingHorizontal(1em);

                &:hover {
                    background-color: $primaryMidGreyColour;
                    padding-right: 1.3em;
                }
            }
        }

        &-item p {
            margin: 0;
        }
    }
}

@include mobileScreen{
    .categories-box{
        display: none;
    } 
}