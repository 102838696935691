  .checkout {
    &__success-panel {
      padding: 15px;
      border-radius: 8px;
      background-color: $primaryGreyColour;
      border: 1px solid $primaryMidGreyColour;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }

    &__success-message {
      width: 80%;
      height: auto;
      margin: 0 auto;
      border-radius: 5px;
      border: 1px solid $primaryGreyColour;
      padding: 20px;

      & h3 {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    &__success-button {
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      display: block;
      margin: 0 auto;
      background-color: $secondaryColour;
      color: white;
      font-weight: 700;
    }

    &__layout {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      padding: 50px; 
    }
  }
  
  form.checkout__form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  button.checkout__buttom {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff !important;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  button.checkout__buttom:hover {
    filter: contrast(115%);
  }
  
  button.checkout__buttom:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .checkout__spinner,
  .checkout__spinner:before,
  .checkout__spinner:after {
    border-radius: 50%;
  }
  
  .checkout__spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .checkout__spinner:before,
  .checkout__spinner:after {
    position: absolute;
    content: '';
  }
  
  .checkout__spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .checkout__spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loadSpinner 2s infinite ease;
  }
  
  @keyframes loadSpinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }