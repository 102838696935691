.chat-wall {
    height: auto;
    border: 1px solid $primaryGreyColour;
    border-radius: 5px;
    overflow: hidden;

    &__header {
        border-bottom: 1px solid $primaryGreyColour;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 15px;
        background-color: $primaryMidGreyColour;
        display: flex;
        align-items: center;

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        &-image-div {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-color: $secondaryGreyColour;
            margin-right: 15px;
        }

        &-user-info {
            & h4, & p {
                margin: 0;
            }
            & a:hover {
                text-decoration: underline;
            }
        }

        & h3 {
            font-weight: 700;
            color: $secondaryColour;
        }
    }

    &__main {
        height: 60vh;
        padding: 15px;
        overflow-y: scroll;
    }

    &__footer {
        border-top: 1px solid $primaryGreyColour;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $primaryMidGreyColour;
        padding: 15px;
        justify-content: center;
    }

    &__input-div {
        width: 80%;
        height: 2.5em; 
        background-color: #FFFFFF;
        border-radius: 5px;
        justify-content: space-between;
    }

    &__input {
        border: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid $secondaryColour;
        @include paddingHorizontal(1em);
        width: 80%;
        height: 100%;

        &.disabled {
          border: 1px solid rgb(179, 180, 181);
        }

        &:focus {
            outline: none;
        }
    }

    &__button {
        flex: 1;
        height: 100%;
        outline: none;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: $lightFont;
        background-color: $secondaryColour;
        font-weight: 700;
        letter-spacing: 1px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          background-color: rgb(179, 180, 181);
        }
    }
}

.chat-card {
    min-height: 3em;
    align-items: flex-start;
    margin-bottom: 15px;
    
    &__image-div {
        height: 3.5em;
        width: 3.5em;
        border-radius: 50%;
        border: 1px solid $primaryGreyColour;
        background-color: $primaryMidGreyColour;
        margin-right: 15px;
    }

    &__image {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    &__message-div {
        min-height: 3em;
        max-width: 380px;
        // display: inline-block;
        display: inline-flex;
        flex-direction: column;
        padding: 1em;
        border: 1px solid $secondaryColour;
        background-color: $secondaryColour;
        border-radius: 30px;
        border-top-left-radius: 10px;
        box-sizing: border-box;
        
    }

    &__message {
        margin: 0;
        line-height: 1.3;
        color: $lightFont;
    }

    &__product-link-div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    &__product-link {
        font-size: 0.8em;
        padding: 3px 18px;
        color: $lightFont;
        margin-top: 8px;
        border-radius: 4px;
        border-bottom-left-radius: 10px;
        background-color: lighten($secondaryColour, 20%);
        text-align: left;
        display: inline-block;
        // align-self: flex-start;
        cursor: pointer;
    }

    &--alt {
        flex-direction: row-reverse;
        align-items: flex-start;
        margin-bottom: 15px;

        & .chat-card__image-div {
            margin-left: 15px;
            margin-right: 0;
            align-items: flex-end;
        }

        & .chat-card__message-div {
            border: 1px solid darkgrey;
            background-color: #E9E9E9;
            border-radius: 30px;
            border-top-right-radius: 10px;
            box-sizing: border-box;
        }

        & .chat-card__message {
            margin: 0;
            line-height: 1.3;
            color: $darkFont;
        }

        & .chat-card__product-link-div {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        & .chat-card__product-link {
            text-align: right;
            // align-self: flex-end !important;
            padding: 3px 18px;
            color: $lightFont;
            border-radius: 4px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 4px;
        }
    }
}

@include mobileScreen{
    .chat-wall { 
        &__main {
            height: 60vh;
            padding: 0.8em 10px;
        }
    
        &__input-div {
            width: 100%;
        }
    
        &__input {
            width: 80%;
        }

        &-user-info {
            & a:hover {
                text-decoration: none;
            }
        }
    }
    
    .chat-card {
        margin-bottom: 0.8em;
        
        &__image-div {
            height: 40px;
            width: 40px;
            margin-right:8px;
        }
    
        &__message-div {
            min-height: 40px;
            max-width: 70vw;
            display: inline-block;
            padding: 10px;
            border: 1px solid $secondaryColour;
            background-color: lighten($primaryColour, 20%);
            border: 1px solid $secondaryColour;
            background-color: $secondaryColour;
            border-radius: 18px;
            border-top-left-radius: 6px;
            box-sizing: border-box;
        }
    
        &__message {
            margin: 0;
            line-height: 1.3;
        }
    
        &--alt {
            flex-direction: row-reverse;
            align-items: flex-start;
            margin-bottom: 15px;
    
            & .chat-card__image-div {
                margin-left: 15px;
                margin-right: 0;
            }
    
            & .chat-card__message-div {
                border: 1px solid darkgrey;
                background-color: #E9E9E9;
                border-radius: 18px;
                border-top-right-radius: 6px;
                box-sizing: border-box;
            }
        }
    }
}