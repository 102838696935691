.hero {
    width: 100%;
    height: auto;
    margin-bottom: 30px;

    &__text-large {
        display: block;
    }

    &__text-small {
        display: none;
    }

    &__form-element {
        width: 100%;
        height: 2.5em;
        position: relative;
    }

    &__main-div {
        border-radius: 5px;
        border: 1px solid $primaryGreyColour;
        background-color: $primaryMidGreyColour;
        height: 165px;
        margin-bottom: 15px;
        overflow: hidden;
        padding: 10px 20px;
        position: relative;

        &__image {
            position: absolute;
            bottom: 0;
            right: 100px;
            height: 150px;
        }

        & h1 {
            width: 80%;
            font-size: 1.8em;
            font-weight: 700;
            color: $secondaryColour;
            margin-bottom: 5px;
        }

        & p {
            width: 80%;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    &__search-div {
        border-radius: 5px;
        background-color: $primaryMidGreyColour;
        height: 130px;
        border: 1px solid $primaryGreyColour;
        justify-content: center;
        align-items: center;
        position: relative;

        &__image {
            position: absolute;
            z-index: 1;
            bottom: 0;
            height: 220px;
            left: 230px;
        }

        &__input {
            border: none;
            height: 2.5em;
            width: 100%;
            border-radius: 5px;
            border: 1px solid $secondaryColour;
            padding: 0 8em 0 1em;

            &:focus {
                outline: 2px solid lighten($secondaryColour, 20%);
            }
        }

        &__button {
            border: none;
            outline: none;
            height: 2.5em;
            width: 20%;
            right: 0;
            z-index: 1;
            position: absolute;
            border-radius: 5px;  
            color: $lightFont;
            background-color: $secondaryColour;
            font-weight: 700;
            letter-spacing: 1px;
        }
    }

    &__form {
        width: 600px;
        background-color: white;
        height: 2.5em;
        z-index: 2;
        border-radius: 5px;
    }

    &__search-box {
        border: none;
        width: 100%;
        height: 2.5em;
        @include paddingHorizontal(1em);
        border-start-start-radius: 5px;
    }
}

.hero-section-brands {

    &__div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
    }

    max-width: 1000px;
    overflow: scroll;
    display: flex;
    gap: 15px;

    &::-webkit-scrollbar {
        display: none;
    }
}

@include mobileScreen {
    .hero {
        width: 100%;
        height: auto;

        &__text-large {
            display: none;
        }

        &__text-small {
            display: block;
        }
    

        &__main-div {
            width: 100%;
            height: 180px;
            margin-bottom: 15px;
            padding: 15px;
            position: relative;
    
            &__image {
                position: absolute;
                bottom: 0;
                right: 10px;
                height: 140px;
            }
    
            & h1 {
                width: 80%;
                font-weight: 700;
                font-size: 1.2em;
                color: $secondaryColour;
                margin-bottom: 5px;
            }
    
            & p {
                width: 60%;
                font-size: 0.9em;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 10px;
            }
        }
    
        &__search-div {
            height: 90px;
            @include paddingHorizontal(1em);
    
            &__image {
                display: none;
            }
    
            &__input {
                border: none;
                height: 2.5em;
                width: 80%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 1px solid $secondaryColour;
                @include paddingHorizontal(1em);
    
                &:focus {
                    outline: 2px solid lighten($secondaryColour, 20%);
                }
            }
    
            &__button {
                border: none;
                outline: none;
                height: 2.5em;
                width: 20%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;    
                color: $lightFont;
                background-color: $secondaryColour;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    
        &__form {
            width: 600px;
            background-color: white;
            height: 2.5em;
            z-index: 2;
            border-radius: 5px;
        }
    
        &__search-box {
            border: none;
            width: 100%;
            height: 2.5em;
            @include paddingHorizontal(1em);
            border-start-start-radius: 5px;
        }
    }
}