.welcome-page__right-div {
    display: flex;
    flex-direction: column;
}

.welcome-page__hero {
    width: 100%;
    flex: 1;
    margin-bottom: 20px;

    &__new-arrivals-button {
        border: none;
        outline: none;
        border-radius: 5px;
        display: flex;
        gap: 15px;
        background-color: $secondaryColour;
        padding: 0 20px;
        height: 2.8em;
        align-items: center;

        & * {
            color: white;
            font-size: 1.3em;
            margin: 0;
        } 

        &.shiny {
            background: $secondaryColour;
            background: linear-gradient(-45deg, $secondaryColour 50%, #b2b3e5 60%, $secondaryColour 70%);
            background-size: 600% 100%;
            -webkit-animation: shine 20s infinite;
                    animation: shine 20s infinite;
            -webkit-animation-delay: 0s;
                    animation-delay: 0s;
            -webkit-animation-timing-function: linear;
                    animation-timing-function: linear;
          }
          
          @-webkit-keyframes shine {
            0% {
              background-position-x: 400%;
            }
            50% {
              background-position-x: 0%;
            }
            100% {
              background-position-x: -400%;
            }
          }
          
          @keyframes shine {
            0% {
              background-position-x: 400%;
            }
            50% {
              background-position-x: 0%;
            }
            100% {
              background-position-x: -400%;
            }
          }
    }

    &__image-div {
        height: 100%;
        width: 200px;
        position: absolute;
        right: 200px;
        bottom: 0;
    }

    &__image {
        height: 90%;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;
    }

    &__image-BG {
        height: 95%;
        position: absolute;
        right: -20%;
        bottom: 0;
        z-index: 2;
        opacity: 0.7;
    }

    &__text-large {
        display: block;
        text-align: left !important;
        width: 40%;
    }

    &__text-small {
        display: none;
    }

    &__form-element {
        width: 100%;
        height: 2.5em;
        position: relative;
    }

    &__explore-shops-div {
        display: inline-flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        color: $lightFont;
        background-color: $secondaryColour;
        padding: 5px 15px;
        border-bottom-right-radius: 5px;
    }

    &__main-div {
        border-radius: 5px;
        border: 1px solid $primaryGreyColour;
        background-color: $primaryMidGreyColour;
        height: 100%;
        margin-bottom: 15px;
        overflow: hidden;
        padding: 2em;
        position: relative;

        & h1 {
            text-align: center;
            font-size: 1.8em;
            font-weight: 700;
            width: 50%;
            color: $secondaryColour;
            margin-bottom: 5px;
            text-align: left;
        }

        & p {
            text-align: center;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
    &__search-div {
        border-radius: 5px;
        background-color: $primaryMidGreyColour;
        height: 100px;
        border: 1px solid $primaryGreyColour;
        justify-content: center;
        align-items: center;
        position: relative;

        &__image {
            position: absolute;
            z-index: 1;
            bottom: 0;
            height: 170px;
            left: 230px;
        }

        &__input {
            border: none;
            height: 2.5em;
            width: 100%;
            border-radius: 5px;
            border: 1px solid $secondaryColour;
            padding: 0 8em 0 1em;

            &:focus {
                outline: 2px solid lighten($secondaryColour, 20%);
            }
        }

        &__button {
            border: none;
            outline: none;
            height: 2.5em;
            width: 20%;
            right: 0;
            z-index: 1;
            position: absolute;
            border-radius: 5px;  
            color: $lightFont;
            background-color: $secondaryColour;
            font-weight: 700;
            letter-spacing: 1px;
        }
    }

    &__form {
        width: 600px;
        background-color: white;
        height: 2.5em;
        z-index: 2;
        border-radius: 5px;
    }

    &__search-box {
        border: none;
        width: 100%;
        height: 2.5em;
        @include paddingHorizontal(1em);
        border-start-start-radius: 5px;
    }
}

@include largemobileScreen {
    .welcome-page__hero {
        width: 100%;
        height: auto;
        display: none;

        &__text-large {
            display: none;
        }

        &__text-small {
            display: block;
        }
    

        &__main-div {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
            padding: 15px;
            position: relative;
    
            & h1 {
                width: 100%;
                text-align: center;
                font-weight: 700;
                font-size: 1.2em;
                margin-bottom: 10px;
            }
    
            & p {
                width: 100%;
                font-size: 0.9em;
                line-height: 1.2;
                margin-bottom: 0;
            }
        }
    
        &__search-div {
            height: 90px;
            @include paddingHorizontal(1em);
    
            &__image {
                display: none;
            }
    
            &__input {
                border: none;
                height: 2.5em;
                width: 80%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 1px solid $secondaryColour;
                @include paddingHorizontal(1em);
    
                &:focus {
                    outline: 2px solid lighten($secondaryColour, 20%);
                }
            }
    
            &__button {
                border: none;
                outline: none;
                height: 2.5em;
                width: 20%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;    
                color: $lightFont;
                background-color: $secondaryColour;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    
        &__form {
            width: 600px;
            background-color: white;
            height: 2.5em;
            z-index: 2;
            border-radius: 5px;
        }
    
        &__search-box {
            border: none;
            width: 100%;
            height: 2.5em;
            @include paddingHorizontal(1em);
            border-start-start-radius: 5px;
        }
    }
}

@include mobileScreen {
    .welcome-page__hero {
        width: 100%;
        height: auto;
        display: none;

        &__text-large {
            display: none;
        }

        &__text-small {
            display: block;
        }
    

        &__main-div {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
            padding: 15px;
            position: relative;
    
            & h1 {
                width: 100%;
                text-align: center;
                font-weight: 700;
                font-size: 1.2em;
                margin-bottom: 10px;
            }
    
            & p {
                width: 100%;
                font-size: 0.9em;
                line-height: 1.2;
                margin-bottom: 0;
            }
        }
    
        &__search-div {
            height: 90px;
            @include paddingHorizontal(1em);
    
            &__image {
                display: none;
            }
    
            &__input {
                border: none;
                height: 2.5em;
                width: 80%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 1px solid $secondaryColour;
                @include paddingHorizontal(1em);
    
                &:focus {
                    outline: 2px solid lighten($secondaryColour, 20%);
                }
            }
    
            &__button {
                border: none;
                outline: none;
                height: 2.5em;
                width: 20%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;    
                color: $lightFont;
                background-color: $secondaryColour;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    
        &__form {
            width: 600px;
            background-color: white;
            height: 2.5em;
            z-index: 2;
            border-radius: 5px;
        }
    
        &__search-box {
            border: none;
            width: 100%;
            height: 2.5em;
            @include paddingHorizontal(1em);
            border-start-start-radius: 5px;
        }
    }
}
