.tabs-custom {
    margin-bottom: 20px;
    border-bottom: 1px solid $primaryGreyColour;

    &__tab-item {
        background-color: transparent;
    }

    &__tab-item button{

        font-weight: 600;
        color: $darkFont;

        &:hover {
            color: $darkFont !important;
            border-bottom: 1px solid $primaryGreyColour !important;
        }

        &.active {
            color: $darkFont !important;
            border: 1px solid $primaryGreyColour !important;
            border-bottom: 1px solid $backgroundColour !important;
            background-color:  transparent !important;

        }
    }

    &__empty-div {
        width: 50%;
        min-height: 250px;
        border-radius: 5px;
        margin: 80px auto 30px;
        background-color: rgb(232, 232, 232);
        border: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2.5em;

        & * {
            color: rgb(158, 158, 158);
            text-align: center;
        }

        & i {
            font-size: 5em;
        }
    }

    &__empty-div__button {
        padding: 8px 20px;
        margin-top: 20px;
        border: none;
        border-radius: 4px;
        background-color: $secondaryColour;
        font-weight: 600;
        color: $lightFont;
    }

    &__reload {
        background-color: $secondaryColour;
        margin-top: 30px;
        border: none;
        border-radius: 5px;
        color: white;
        padding: 8px 15px;
        font-weight: 600;
    }

    &__button {
        height: 2.5em;
        outline: none;
        border: none;
        border-radius: 5px;
        background-color: $secondaryColour;
        margin-right: 12px;
        padding: 0.5em 1em;
        transition: background-color 0.3s;
        color: $lightFont;
        font-size: 0.95em;

        &:hover {
            background-color: lighten($secondaryColour, 10%);
        }
    }
}

@include mobileScreen {
    .tabs-custom {
        &__empty-div {
            width: 90%;
            min-height: 250px;
            margin: 30px auto;
            padding: 2.5em;
    
            & i {
                font-size: 5em;
            }
        }
    }
    .hide-tab-on-mobile {
        display: none;
    }
}