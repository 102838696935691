.footer {
    height: auto;
    background-color: $secondaryColour;
    padding-top: 30px;

    &__top-div {
        width: $largeDeviceWidth;
        margin: 0 auto 30px;
    }

    &__heading {
        color: $lightFont;
        font-weight: 700;
        margin-bottom: 6px !important;
    }

    &__sub-heading {
        text-decoration: underline;
        color: $lightFont;
        font-weight: 700;
    }

    &__brand-info {
        line-height: 1.3;
        font-size: 0.9em;
        color: $lightFont;
        width: 300px;
        margin-bottom: 0;
    }

    &__email-div {
        display: flex;
        justify-content: flex-end;
    }

    &__email-inner-div {
        & h5, & p {
            margin-bottom: 2px;
            color: $lightFont;
        }
    }

    &__category-list {
        margin: 0;
        padding: 0;
    }

    &__brand {
        display: flex;
        align-self: center;
        gap: 8px;
        margin-bottom: 1px;
        
    }

    &__app-logo {
        height: 1.1em;
    }

    &__category-list-item {
        margin-bottom: 3px;
        
        & a {
            color: white;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__contact-div {
        margin-bottom: 15px;
        cursor: pointer; 
        
        & p:hover {
            text-decoration: underline;
        }
    }

    &__contact-icon {
        height: 16px;
        width: 16px;
        // background-color: rgba(231, 231, 231, 0.293);
        color: white;
        border-radius: 50%;
        margin-right: 10px;
    }
    
    &__contact-info {
        font-size: 0.9em; 
        margin: 0;
        line-height: 1;
        font-weight: 500;
        color: $lightFont;
    }

    &__socials-inner-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #FFFFFF80;
        margin-right: 15px;
        cursor: pointer;
    }

    &__social-icon {
        font-size: 1.4em;
        color: $secondaryColour;
    }

    &__bottom-div {
        height: 60px;
        background-color: #FFFFFF20;
        align-items: center;
        justify-content: center;

        & h6 {
            color: rgba(255, 255, 255, 0.236);
            margin: 0;
        }
    }

    &__info-div {
        margin-top: 20px;
    }

    &__info {
        &--get-the-app {
            padding-top: 20px; 

            &__title {
                color: $lightFont;
            }

            &__button {
                background-color: black;
                border: none; 
                outline: none;
                border-radius: 8px;
                padding: 8px 18px;

                &-image-div {
                    width: 30px;
                }

                &-image {
                    width: 100%;
                }

                &-info-div {
                    display: flex;
                    flex-direction: column;
                     & * {
                        margin: 0;
                        color: $lightFont;
                        line-height: 1;
                     }

                     & p {
                        font-size: 0.9em;
                     }

                     & h3 {
                        font-size: 1.5em;
                     }

                }

                &-div {
                    display: flex;
                    gap: 12px;
                }
            }
        }

        & a {
          color: $lightFont;
        }
    }
}

@include largemobileScreen {
    .footer {
        padding-top: 0;

        &__top-div {
            width: 100%;
            margin: 0 auto 20px;
            padding: 0 1em;
        }

        &__heading {
            font-size: 1.4em;
            margin: 20px 0 10px !important;
        }

        &__categories {
            display: none;
        }

        &__brand {
            display: flex;
            align-self: center;
            gap: 8px;
            margin: 20px 0 5px;

            & .footer__heading {
                margin-top: unset !important;
                margin-bottom: unset !important;
            }
            
        }
    
    
        &__brand-info {
            line-height: 1.1;
            font-size: 1em;
            width: 75%;
            margin-bottom: 20px;
        }
    
        &__category-list-item {
            margin-bottom: 6px;
            
            & a {
                font-size: 1.1em;
            }
        }

        &__contact-div {
            margin-bottom: 15px;
        }

        &__contact-icon {
            height: 22px;
            width: 22px;
        }
        
        &__contact-info {
            font-size: 1.1em; 
            margin: 0;
            line-height: 1;
            font-weight: 500;
            color: $lightFont;
        }
    
        &__socials-inner-div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #FFFFFF80;
            margin-right: 15px;
        }
    
        &__social-icon {
            font-size: 1.4em;
            color: $secondaryColour;
        }
    
        &__bottom-div {
            height: 60px;
            background-color: #FFFFFF20;
            align-items: center;
            justify-content: center;
    
            & h6 {
                color: $lightFont;
                margin: 0;
            }
        }
    }
}
@include mobileScreen {
    .footer {
        padding-top: 0;

        &__top-div {
            width: 100%;
            margin: 0 auto 20px;
            padding: 0 1em;
        }

        &__heading {
            font-size: 1.4em;
            margin: 20px 0 10px !important;
        }

        &__brand {
            display: flex;
            align-self: center;
            gap: 8px;
            margin: 20px 0 5px;

            & .footer__heading {
                margin-top: unset !important;
                margin-bottom: unset !important;
            }
            
        }
    
    
        &__brand-info {
            line-height: 1.1;
            font-size: 1em;
            width: 75%;
            margin-bottom: 20px;
        }

        &__email-div {
            justify-content: flex-start;
            margin-top: 50px;
        }
    
        &__category-list-item {
            margin-bottom: 6px;
            
            & a {
                font-size: 1.1em;
            }
        }

        &__contact-div {
            margin-bottom: 15px;
        }

        &__contact-icon {
            height: 22px;
            width: 22px;
        }
        
        &__contact-info {
            font-size: 1.1em; 
            margin: 0;
            line-height: 1;
            font-weight: 500;
            color: $lightFont;
        }
    
        &__socials-inner-div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #FFFFFF80;
            margin-right: 15px;
        }
    
        &__social-icon {
            font-size: 1.4em;
            color: $secondaryColour;
        }
    
        &__bottom-div {
            height: 60px;
            background-color: #FFFFFF20;
            align-items: center;
            justify-content: center;
    
            & h6 {
                color: $lightFont;
                margin: 0;
            }
        }
    }
}